import axios from "axios";
import { authHeader } from "@/services/base.service";
import { BASE_API_URL } from "@/helpers/base.api.url";

class MiceService {
  async getData() {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/mices",
    });
  }
  async getDataByVoyage() {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/mices",
    });
  }

  async getDataById(mice_id) {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/mices/" + mice_id,
    });
  }

  async storeData(mice) {
    return await axios.post(BASE_API_URL + "/mices", mice, {
      headers: authHeader(),
    });
  }

  async updateData(mice_id, data) {
    return await axios({
      method: "put",
      url: BASE_API_URL + "/mices/" + mice_id,
      data: data,
      headers: authHeader(),
    });
  }

  async deleteData(mice_id) {
    return await axios({
      method: "delete",
      url: BASE_API_URL + "/mices/" + mice_id,
      headers: authHeader(),
    });
  }
}

export default new MiceService();
