import axios from "axios";
import { authHeader } from "@/services/base.service";
import { BASE_API_URL } from "@/helpers/base.api.url";

class ReviewService {
  async getData() {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/review",
    });
  }

  async storeData(data) {
    return await axios({
      method: "post",
      url: BASE_API_URL + "/review",
      headers: authHeader(),
      data: data,
    });
  }

  async updateData(review_id, data) {
    return await axios({
      method: "put",
      url: BASE_API_URL + "/review/" + review_id,
      headers: authHeader(),
      data: data,
    });
  }

  async deleteData(review_id) {
    return await axios({
      method: "delete",
      url: BASE_API_URL + "/review/" + review_id,
      headers: authHeader(),
    });
  }
}

export default new ReviewService();
