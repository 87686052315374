<template>
  <div class="custom-select">
    <div
      class="selected-option flex-r gap-4 uppercase whitespace-nowrap"
      @click="toggleDropdown"
    >
      <span>{{ selectedLabel }}</span>
      <font-awesome-icon
        :icon="['fas', 'chevron-down']"
        style="transform: rotate(180deg)"
      />
    </div>
    <ul class="options-list">
      <li
        v-for="vessel in options"
        :key="vessel.id"
        @click="selectOption(vessel)"
        :class="{ selected: vessel.id == selectedVesselId }"
      >
        <span>{{ vessel.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "CruiseSelect",
  data() {
    return {
      vessels: [
        { id: 1, name: "Cruise 1" },
        { id: 2, name: "Cruise 2" },
      ],
      selectedVesselId: null,
      isOpen: false,
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(["vessel"]),
    selected: {
      get() {
        return this.vessel;
      },
      set(value) {
        this.updateVessel(value);
      },
    },
    selectedLabel() {
      const selectedOption = this.vessels.find((v) => v.id == this.selected);
      return selectedOption ? selectedOption.name : "Select Cruise";
    },
  },
  methods: {
    ...mapMutations(["setVessel"]),
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(vessel) {
      this.selected = vessel.id; // Truyền id của vessel vào state
      this.isOpen = false; // Đóng dropdown sau khi chọn
    },
    updateVessel(value) {
      this.setVessel(value);
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
.custom-select {
  position: relative;
  border: none;
  /* Adjust width as needed */
}

.custom-select:hover .options-list {
  display: flex;
  flex-direction: column;
}

.selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
  cursor: pointer;
  padding-bottom: 2px;
  letter-spacing: 0.2vw;
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;
}

.options-list li {
  padding: 8px;
  cursor: pointer;
  color: white;
}

.options-list li:hover {
  background-color: rgba(160, 160, 160, 0.8);
}

.selected {
  font-weight: bold;
  background-color: rgba(80, 80, 80, 0.5);
}

@media only screen and (min-width: 780px) {
  .options-list {
    top: -340%;
  }
}
</style>
