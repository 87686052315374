<template>
  <div class="">
    <div
      class="banner mb-8 w-full"
      :style="{ backgroundImage: `url(${imgUrl})` }"
    >
      <PageLogo :logoType="3" />
    </div>
  </div>
</template>
<script>
import PageLogo from "@/components/PageLogo.vue";
const Banner = {
  name: "Banner",
  components: {
    PageLogo,
  },
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
  },
};
export default Banner;
</script>
<style>
.banner {
  /* background-image: url(https://res.cloudinary.com/dfgr5besj/image/upload/v1720375390/r0grdd68idgnn2i8xlwd.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 50vh;
}
</style>
