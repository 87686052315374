<template>
  <div class="items-center justify-center w-full flex mb-8">
    <div
      class="text-nowrap justify-center items-center rounded-lg p-2 flex w-full"
      style="background-color: #15395a"
    >
      <div class="grid gird-cols-1 lg:flex gap-2 w-full">
        <!-- <ComboboxLocation
          v-model="selectedLocation"
          :options="locations.data"
        /> -->

        <DatePicker.RangePicker
          class="rounded-none w-full"
          v-model:value="dates"
          :placeholder="[$t('startDate'), $t('endDate')]"
          :format="'DD MMM YYYY'"
        />
        <!-- <ComboboxLocation v-model="vessel" /> -->
        <ComboboxDatepicker v-model="voyage" />
        <ComboboxOcupanacy v-model="occupancy" />
        <div
          class="rounded-tr-lg rounded-br-lg flex flex-row justify-center items-center cursor-pointer p-2 md:p-0"
          @click="search"
        >
          <div
            style="color: #e4b587"
            class="mr-3"
          >
            {{ $t("search") }}
          </div>
          <font-awesome-icon
            :icon="['fas', 'search']"
            style="color: #e4b587"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// import ComboboxLocation from "@/components/ComboboxLocation.vue";
import ComboboxOcupanacy from "@/components/ComboboxOcupanacy.vue";
import { DatePicker } from "ant-design-vue";
// import { formatDate } from "@/helpers/format.date";
import { ref, defineEmits } from "vue";
import vesselService from "@/services/vessel.service";
import router from "@/router";
import ComboboxDatepicker from "@/components/ComboboxDatepicker.vue";

const emits = defineEmits(["search"]);

// const dateFormat = ref("YYYY-MM-DD");

let items = [];
try {
  const data = await vesselService.getData();
  console.log(data.data.data.vessels);
  items = data.data.data.vessels.filter((e) => {
    return e.id == localStorage.vessel;
  });
} catch (err) {
  console.log(err);
}
const dates = ref([]);

const occupancy = ref({
  adult: 0,
  children: 0,
  infant: 0,
});

// const vessel = ref({
//   name: "",
//   order_number: 1,
// });

const voyage = ref(null);

const search = async () => {
  console.log(voyage.value);
  localStorage.adult = occupancy.value.adult;
  localStorage.children = occupancy.value.children;
  localStorage.infant = occupancy.value.infant;
  emits("search");
  if (voyage.value != null && voyage.value.name != undefined && voyage.value.name.includes("3")) {
    router.push({
      path: `/vessels/2/booking`,
      query: {
        check_in: formatDate(dates.value[0]),
        check_out: formatDate(dates.value[1]),
      },
    });
  } else if (
    voyage.value != null &&
    voyage.value.name != undefined &&
    voyage.value.name.includes("2")
  ) {
    router.push({
      path: `/vessels/1/booking`,
      query: {
        check_in: formatDate(dates.value[0]),
        check_out: formatDate(dates.value[1]),
      },
    });
  } else {
    router.push({
      path: `/vessels/${items[0].order_number}/booking`,
      query: {
        check_in: formatDate(dates.value[0]),
        check_out: formatDate(dates.value[1]),
      },
    });
  }
};

const formatDate = (date) => {
  return date
    ? new Date(date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    : "";
};
</script>
<style>
.mx-input-wrapper {
  height: 100%;
}
.mx-input-wrapper input {
  border-radius: 0;
  height: 100% !important;
}
</style>
