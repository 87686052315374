<template>
  <div class="fixed right-5 bottom-5 z-50">
    <div
      class="cursor-pointer hover:scale-125 ease-in duration-150"
      v-if="isSupportOpen"
    >
      <img
        src="../assets/support_icon_chat.png"
        @click="isSupportOpen = !isSupportOpen"
      />
    </div>
    <div
      class="flex flex-col gap-3 relative"
      v-if="!isSupportOpen"
    >
      <font-awesome-icon
        :icon="['fas', 'chevron-down']"
        class="cursor-pointer"
        @click="isSupportOpen = !isSupportOpen"
      />

      <a
        href="viber://chat?number=84376589368"
        target="_blank"
        class="cursor-pointer relative online-support hover:scale-110 ease-in duration-150"
      >
        <img src="../assets/support_icon_viber.png" />
        <div
          class="absolute right-full mr-4 top-1/3 bg-slate-500 text-white p-1 rounded-lg description-support"
        >
          Viber
        </div>
      </a>

      <a
        href="https://zalo.me/+84376589368"
        target="_blank"
        class="cursor-pointer relative online-support hover:scale-110 ease-in duration-150"
      >
        <img src="../assets/support_icon_zalo.png" />
        <div
          class="absolute right-full mr-4 top-1/3 bg-slate-500 text-white p-1 rounded-lg description-support"
        >
          Zalo
        </div>
      </a>

      <a
        href="https://api.whatsapp.com/send?phone=84376589368"
        target="_blank"
        class="cursor-pointer relative online-support hover:scale-110 ease-in duration-150"
      >
        <img src="../assets/support_icon_whatsapp.png" />
        <div
          class="absolute right-full mr-4 top-1/3 bg-slate-500 text-white p-1 rounded-lg description-support"
        >
          Whatsapp
        </div>
      </a>

      <a
        href="https://m.me/267482149792760"
        class="cursor-pointer relative online-support hover:scale-110 ease-in duration-150"
        target="_blank"
      >
        <img src="../assets/support_icon_messenger.png" />
        <div
          class="absolute right-full mr-4 top-1/3 bg-slate-500 text-white p-1 rounded-lg description-support"
        >
          Messenger
        </div>
      </a>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";

const isSupportOpen = ref(true);
</script>
<style>
.description-support {
  display: none;
}

.online-support:hover .description-support {
  display: block;
}
</style>
