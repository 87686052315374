<script setup>
import VoyageService from "@/services/voyage.service";
import CardVoyage from "@/components/CardVoyage.vue";
// import Button from "@/components/Button.vue";
import { ref, reactive } from "vue";
// import PageLogo from "@/components/PageLogo.vue";
// import Combobox from "@/components/Combobox.vue";
import VoyageSearchHeading from "@/components/heading/VoyageSearchHeading.vue";
// import CardList from "@/components/CardList.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
// import { formatDate } from "@/helpers/format.date";
import SearchBox from "@/components/SearchBox.vue";
// import { diffDate } from "@/helpers/diff.date.function";

const language = ref(localStorage.language);

let itemsPaginated = reactive({ data: [] });

try {
  const voyage = await VoyageService.getData();

  itemsPaginated.data = voyage.data.data.voyages.sort((a, b) => {
    return a.stt - b.stt;
  });
} catch (err) {
  console.log(err);
}

const mySwiper = ref(null);

const modules = ref([Navigation]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const perPage = ref(3);

if (itemsPaginated.data.length == 1) {
  perPage.value = 1;
} else if (itemsPaginated.data.length == 2) {
  if (window.innerWidth >= 768) {
    perPage.value = 2;
  } else {
    perPage.value = 1;
  }
} else {
  if (window.innerWidth >= 768 && window.innerWidth <= 1280) {
    perPage.value = 2;
  } else if (window.innerWidth >= 1280) {
    perPage.value = 3;
  } else {
    perPage.value = 1;
  }
}
</script>

<template>
  <VoyageSearchHeading />

  <div
    class="search-contain body-custom px-5 p-0 mb-10"
    style="max-width: 1440px"
  >
    <SearchBox @search="handleSearch" />
    <!-- <div class="flex-c md:flex-r pb-8 gap-5">
      <Combobox isV3="true" :white="false" fetchValue="DESTINATION"></Combobox>
      <Combobox isV3="true" :white="false" fetchValue="CHECK IN - CHECK OUT"></Combobox>
      <Combobox isV3="true" :white="false" fetchValue="MONTH & YEAR"></Combobox>
    </div> -->
    <!-- <div
      class="flex flex-row items-center justify-center text-center gap-5 mb-5 md:mb-8"
      v-if="numPages.data > 1"
    >
      <button
        class="prevBtn h-8 w-8 md:h-10 md:w-10"
        @click="prevPage"
      ></button>
      <div
        class="slide-counter blue"
        id="slideCounter"
      >
        {{ currentPageHuman }} of {{ numPages.data }}
      </div>
      <button
        class="nextBtn h-8 w-8 md:h-10 md:w-10"
        @click="nextPage"
      ></button>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 md:gap-6">
      <CardVoyage
        v-for="(item, key) in arrayData.listOfVoyage"
        :key="key"
        :card_title="item.summary_location"
        day="3 DAYS / 2 NIGHTS"
        :fare="item.fares"
        :time="item.voyage_date_available.length + ' DATE AVAILABLE'"
        :imgUrl="item.voyage_metadata.length > 0 ? item.voyage_metadata[0].image_link : ''"
        :voyage_id="item.id"
      >
      </CardVoyage>
    </div> -->
    <swiper
      :slides-per-view="perPage"
      :space-between="10"
      class="w-full mb-8 h-full md:grid md:items-stretch mySwiper"
      @swiper="onSwiper"
      :loop="true"
      :navigation="true"
      :modules="modules"
    >
      <swiper-slide
        v-for="(item, index) in itemsPaginated.data"
        :key="index"
        class="flex justify-center items-center"
      >
        <CardVoyage
          :voyage_id="item.id"
          :card_title="item[`summary_location_${language}`]"
          :day="item[`content_${language}`]"
          :fare="item[`fares_${language}`]"
          :time="item.voyage_date_available.length + $t('numberOfDateAvailable')"
          :imgUrl="item.voyage_metadata.length > 0 ? item.voyage_metadata[0].image_link : ''"
          class="text-start w-full h-full"
        />
      </swiper-slide>
      <div
        class="swiper-pagination"
        v-bind="pagination"
      ></div>
    </swiper>
  </div>

  <!-- <div class="flex-r border-bottom-blue mb-8 border-b justify-between">
    <div class="flex-r gap-3">
      <div
        :class="[
          'grid-otp',
          'flex-r',
          'items-center',
          'blue',
          'gap-2',
          { selected: selectedOption == 'grid' },
        ]"
        @click="selectOption('grid')"
      >
        <font-awesome-icon :icon="['fas', 'grip-vertical']" />
        {{ $t("voyageSearch.gridOtp") }}
      </div>
      <div
        :class="[
          'list-otp hidden md:block',
          'flex-r',
          'items-center',
          'blue',
          'gap-2',
          { selected: selectedOption == 'list' },
        ]"
        @click="selectOption('list')"
      >
        <font-awesome-icon :icon="['fas', 'bars']" />
        {{ $t("voyageSearch.listOtp") }}
      </div>
    </div>
    <div class="border-bottom-blue border-b blue text-xs">PHOTO VIEW</div>
  </div> -->
  <!-- <div
    class="mx-auto hidden md:block"
    v-if="selectedOption == 'list'"
  >
    <div class="flex-c gap-8 mb-8">
      <CardList
        v-for="(item, key) in arrayData.listOfVoyage"
        :key="key"
        :imgUrl="item.voyage_metadata.length > 0 ? item.voyage_metadata[0].image_link : ''"
        :card_title="item.summary_location"
        :card_date_availeble="item.voyage_date_available.length + 'DATE AVAILABLE'"
        :card_fare="item.fares"
      ></CardList>
    </div>
  </div> -->
</template>

<style></style>
