<template>
  <swiper
    :slides-per-view="1"
    :space-between="10"
    class="w-full mb-8 h-full md:grid md:items-stretch"
    @swiper="onSwiper"
    :loop="true"
    :navigation="true"
    :modules="modules"
  >
    <swiper-slide
      v-for="(item, index) in itemsPaginated"
      :key="index"
      class="grid grid-cols-1 xl:grid-cols-2"
    >
      <div class="service-slide-content md:pl-16 md:pr-16">
        <div class="flex flex-col justify-center h-full">
          <div class="flex flex-row overflow-hidden">
            <div class="flex flex-col justify-around h-full overflow-hidden">
              <div class="blue uppercase text-start text-lg md:text-xl overflow-hidden">
                <p>{{ $t("service.titleNor") + $t("service.titleItalic") }}</p>
              </div>
            </div>
          </div>
          <div
            class="blue overflow-hidden text-3xl py-5 md:text-5xl text-start home-page-title"
            v-html="item[`title_${language}`]"
          ></div>
          <div class="flex flex-row items-center justify-start blue overflow-hidden">
            <div class="flex flex-col text-start text-xs h-full md:text-base font-normal my-4 pb-5">
              <p
                class="pb-3 xl:pb-5"
                v-html="item[`location_${language}`]"
              ></p>
              <p
                class="pb-3 xl:pb-5"
                v-html="item[`opening_hour_${language}`]"
              ></p>

              <div
                class="line-clime-2"
                v-html="item[`content_${language}`]"
              ></div>
            </div>
          </div>

          <!-- Image Mobile -->
          <img
            :src="item.service_metadata.length > 0 ? item.service_metadata[0].image_link : ''"
            class="w-full block xl:hidden"
            height="1848"
          />
          <div class="flex flex-row py-5 overflow-hidden justify-start">
            <Button
              button_v="1"
              :text="$t('exploreButton')"
              @click="redirectTo(item.id)"
            ></Button>
          </div>
        </div>
      </div>

      <!-- Image PC -->
      <div class="flex items-center w-full justify-center pr-16">
        <img
          :src="item.service_metadata.length > 0 ? item.service_metadata[0].image_link : ''"
          class="w-full hidden xl:block img-home"
          height="1848"
        /></div
    ></swiper-slide>
  </swiper>
</template>

<script setup>
import Button from "@/components/Button.vue";
import { ref } from "vue";
import router from "@/router";
import VesselServiceClass from "@/services/vessel_service.service";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

let itemsPaginated = [];

try {
  const service = await VesselServiceClass.getData(localStorage.vessel);

  itemsPaginated = service.data.data.services.sort((a, b) => {
    return a.stt - b.stt;
  });
} catch (err) {
  console.log(err);
}

const language = ref(localStorage.language);

const mySwiper = ref(null);

const modules = ref([Navigation]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const redirectTo = (id) => {
  router.push("/service-detail/" + id).then(() => {
    window.location.reload();
  });
};
</script>
<style>
.img-home {
  width: auto;
  aspect-ratio: auto 765 / 1024;
  height: 100vh;
  object-fit: cover;
}

@media (min-height: 900px) {
  .img-home {
    width: auto;
    aspect-ratio: auto 765 / 1024;
    height: 923px;
    object-fit: cover;
  }
}

.line-clime-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
