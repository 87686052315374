<template>
  <div class="container mx-auto px-4 py-8 text-xs">
    <div class="flex-r items-center mb-4 justify-between">
      <h1 class="text-2xl font-bold">Menu List</h1>
      <div
        class="flex-r font-semibold gap-2 items-center cursor-pointer"
        @click="openAdd"
      >
        <div class="addBtn bg-contain"></div>
        Add new
      </div>
    </div>

    <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
      <table
        class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
      >
        <thead>
          <tr class="text-left">
            <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Name</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Description Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Description Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Thumbnail</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in menu"
            :key="data.id"
          >
            <td class="border px-4 py-2">{{ data.id }}</td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.name"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.title_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.title_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.description_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.description_vn"
            ></td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              <img :src="data.thumbnail" />
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
              <div class="w-full h-full grid grid-cols-2 gap-4">
                <button
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                  @click="
                    openUpdate(
                      data.id,
                      data.name,
                      data.thumbnail,
                      data.title_en,
                      data.title_vn,
                      data.description_en,
                      data.description_vn
                    )
                  "
                >
                  Edit Menu
                </button>
                <button
                  class="bg-red-500 text-white px-4 py-2 rounded"
                  @click="deleteMenu(data.id)"
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Add Menu -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isAddOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <div class="text-xl font-bold pb-2">Add Menu</div>
      <div class="grid grid-cols-2 gap-8">
        <div class="mb-2 w-full">
          <div class="font-semibold">Name</div>
          <input
            type="text"
            v-model="form.name"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold">Thumnail</div>
          <input
            type="text"
            v-model="form.thumbnail"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
          <input
            type="file"
            @change="onFileChange"
          />
          <div class="flex flex-row mt-4">
            <button
              type="submit"
              class="bg-blue-500 text-white px-4 py-2 rounded"
              @click="uploadImage"
            >
              Upload
            </button>
          </div>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold">Title Eng</div>
          <ckeditor
            :editor="editor"
            v-model="form.title_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold">Title Vie</div>
          <ckeditor
            :editor="editor"
            v-model="form.title_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold">Description Eng</div>
          <ckeditor
            :editor="editor"
            v-model="form.description_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold">Description Vie</div>
          <ckeditor
            :editor="editor"
            v-model="form.description_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>
      </div>

      <div class="flex-r gap-3">
        <button
          @click="addMenu()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Menu
        </button>
        <button
          @click="isAddOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Update Menu -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isUpdateOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <div class="text-xl font-bold pb-2">Update Menu</div>
      <div class="grid grid-cols-2 gap-8">
        <div class="mb-2 w-full">
          <div class="font-semibold">Name</div>
          <input
            type="text"
            v-model="formUpdate.name"
            class="form-input h-10 p-3"
            style="width: 100%"
            readonly
          />
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold">Thumnail</div>
          <input
            type="text"
            v-model="formUpdate.thumbnail"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
          <input
            type="file"
            @change="onFileChange"
          />
          <div class="flex flex-row mt-4">
            <button
              type="submit"
              class="bg-blue-500 text-white px-4 py-2 rounded"
              @click="uploadImage"
            >
              Upload
            </button>
          </div>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold">Title Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdate.title_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold">Title Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdate.title_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold">Description Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdate.description_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold">Description Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdate.description_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>
      </div>

      <div class="flex-r gap-3">
        <button
          @click="updateMenu()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Update Menu
        </button>
        <button
          @click="isUpdateOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <LoadingPage :isLoading="isLoading" />
</template>

<script>
import MenuService from "@/services/menu.service";
import axios from "axios";
import LoadingPage from "@/components/LoadingPage.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
} from "ckeditor5";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "MenuPortal",
  components: {
    LoadingPage,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertTable",
          "mediaEmbed",
          "|",
          "bulletedList",
          "numberedList",
          "indent",
          "outdent",
        ],
        plugins: [
          Bold,
          Essentials,
          Heading,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          Undo,
        ],
      },
      menu: [],
      activity_metadata: [],
      menu_id: "",
      isOpen: false,
      isAddOpen: false,
      form: {
        name: "",
        title_en: "",
        title_vn: "",
        description_en: "",
        description_vn: "",
        thumbnail: "",
      },
      isUpdateOpen: false,
      formUpdate: {
        name: "",
        title_en: "",
        title_vn: "",
        description_en: "",
        description_vn: "",
        thumbnail: "",
      },
      isFormuploadImageOpen: false,
      isLoading: false,
      languages: [{ lang: "en" }, { lang: "vn" }],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatDateTime(datetime) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(datetime).toLocaleDateString("en-US", options);
    },

    handleSelectLanguage(event) {
      const language = event.target.value;
      this.form.language = language;
      this.formUpdate.language = language;
    },

    getData() {
      this.isLoading = true;
      MenuService.getData()
        .then((response) => {
          this.menu = response.data.data.menu;
          this.isLoading = false;
        })
        .catch((error) => {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        });
    },

    closeForm() {
      this.isAddOpen = false;
      this.isOpen = false;
    },

    openAdd() {
      this.isAddOpen = true;
      this.form.name = "";
      this.form.title_en = "";
      this.form.title_vn = "";
      this.form.description_en = "";
      this.form.description_vn = "";
      this.form.thumbnail = "";
    },

    addMenu() {
      this.isLoading = true;
      if (
        this.form.name == "" ||
        this.form.title_en == "" ||
        this.form.title_vn == "" ||
        this.form.description_en == "" ||
        this.form.description_vn == "" ||
        this.form.thumbnail == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        MenuService.storeData(this.form)
          .then(() => {
            MenuService.getData()
              .then((res) => {
                this.menu = res.data.data.menu;
                this.isAddOpen = false;
                notify({ type: "success", title: "Success", text: "Add Successfully!" });
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    async deleteMenu(id) {
      this.isLoading = true;
      await MenuService.deleteData(id)
        .then(async () => {
          await MenuService.getData()
            .then((res) => {
              this.menu = res.data.data.menu;
              notify({ type: "success", title: "Success", text: "Delete Successfully!" });
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    openUpdate(id, name, thumbnail, title_en, title_vn, description_en, description_vn) {
      this.menu_id = id;
      this.formUpdate.name = name;
      this.formUpdate.thumbnail = thumbnail;
      this.formUpdate.title_en = title_en;
      this.formUpdate.title_vn = title_vn;
      this.formUpdate.description_en = description_en;
      this.formUpdate.description_vn = description_vn;
      this.isUpdateOpen = true;
    },

    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    updateMenu() {
      this.isLoading = true;
      if (
        this.formUpdate.name == "" ||
        this.formUpdate.title_en == "" ||
        this.formUpdate.title_vn == "" ||
        this.formUpdate.description_en == "" ||
        this.formUpdate.description_vn == "" ||
        this.formUpdate.thumbnail == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        MenuService.updateData(this.menu_id, this.formUpdate)
          .then(() => {
            MenuService.getData()
              .then((res) => {
                this.menu = res.data.data.menu;
                notify({ type: "success", title: "Success", text: "Update Successfully!" });
                this.isUpdateOpen = false;
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    async uploadImage() {
      if (!this.selectedFile) {
        notify({ type: "error", title: "Error", text: "Please select a file first" });
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      const formData = new FormData();
      formData.append("image", this.selectedFile);

      try {
        const response = await axios.post("https://api.imgur.com/3/image", formData, {
          headers: {
            Authorization: "Client-ID 8bb5dd7211002ee",
            "Content-Type": "multipart/form-data",
          },
        });
        this.form.thumbnail = response.data.data.link;
        this.formUpdate.thumbnail = response.data.data.link;
        this.isLoading = false;
      } catch (error) {
        console.error("Error uploading image:", error);
        notify({ type: "error", title: "Error", text: "Upload failed. Please try again." });
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
table th,
td {
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 2;
  width: fit-content;
}

thead .sticky-col {
  z-index: 5;
}
</style>
