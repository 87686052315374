import axios from "axios";
import { authHeader } from "@/services/base.service";
import { BASE_API_URL } from "@/helpers/base.api.url";

class VoyageService {
  async getData() {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/voyages",
    });
  }

  async getDataById(voyage_id) {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/voyages/" + voyage_id,
    });
  }

  async storeData(voyage) {
    return await axios.post(BASE_API_URL + "/voyages", voyage, {
      headers: authHeader(),
    });
  }

  async updateData(voyage_id, voyage) {
    return await axios({
      method: "put",
      url: BASE_API_URL + "/voyages/" + voyage_id,
      voyage,
      headers: authHeader(),
    });
  }

  async deleteData(voyage_id) {
    return await axios({
      method: "delete",
      url: BASE_API_URL + "/voyages/" + voyage_id,
      headers: authHeader(),
    });
  }
}

export default new VoyageService();
