<script setup>
/* eslint-disable */
import VoyageService from "@/services/voyage.service";
import CardVoyage from "@/components/CardVoyage.vue";
// import Button from "@/components/Button.vue";
import { reactive, ref, defineProps } from "vue";
// import router from "@/router";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
// import { diffDate } from "@/helpers/diff.date.function";

const language = ref(localStorage.language);

let itemsPaginated = reactive({ data: [] });

try {
  const voyage = await VoyageService.getData();

  itemsPaginated.data = voyage.data.data.voyages.sort((a, b) => {
    return a.stt - b.stt;
  });
} catch (err) {
  console.log(err);
}

const mySwiper = ref(null);

const modules = ref([Navigation]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const perPage = ref(3);

if (itemsPaginated.data.length == 1) {
  perPage.value = 1;
} else if (itemsPaginated.data.length == 2) {
  if (window.innerWidth >= 768) {
    perPage.value = 2;
  } else {
    perPage.value = 1;
  }
} else {
  if (window.innerWidth >= 768 && window.innerWidth <= 1280) {
    perPage.value = 2;
  } else if (window.innerWidth >= 1280) {
    perPage.value = 3;
  } else {
    perPage.value = 1;
  }
}
</script>

<template>
  <swiper
    :slides-per-view="perPage"
    :space-between="10"
    class="w-full mb-8 h-full md:grid md:items-stretch mySwiper"
    @swiper="onSwiper"
    :loop="true"
    :navigation="true"
    :modules="modules"
  >
    <swiper-slide
      v-for="(item, index) in itemsPaginated.data"
      :key="index"
      class="flex justify-center items-center"
    >
      <CardVoyage
        :voyage_id="item.id"
        :card_title="item[`summary_location_${language}`]"
        :day="item[`content_${language}`]"
        :fare="item[`fares_${language}`]"
        :time="item.voyage_date_available.length + $t('numberOfDateAvailable')"
        :imgUrl="item.voyage_metadata.length > 0 ? item.voyage_metadata[0].image_link : ''"
        class="text-start w-full h-full"
      />
    </swiper-slide>
    <div
      class="swiper-pagination"
      v-bind="pagination"
    ></div>
  </swiper>
</template>
