<template>
  <div class="service-view">
    <Suspense>
      <ActivityHeading />
    </Suspense>
    <div
      class="block p-0 body-custom w-full px-5"
      style="max-width: 1440px"
    >
      <div class="flex flex-col items-center gap-8"></div>
      <Suspense>
        <VenueAllComponent />
      </Suspense>
    </div>
  </div>
  <Menu></Menu>
  <Footer></Footer>
</template>
<script>
import Footer from "@/components/Footer.vue";
import Menu from "@/components/Menu.vue";
import VenueAllComponent from "@/components/venue/VenueAllComponent.vue";
import ActivityHeading from "@/components/heading/ActivityHeading.vue";

export default {
  name: "VenueView",
  components: {
    Footer,
    VenueAllComponent,
    Menu,
    ActivityHeading,
  },
  data() {
    return {
      selectedOption: "grid",
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
    },
  },
};
</script>
<style scoped>
.selected {
  font-weight: bold;
  border-bottom: 2px solid var(--blue-color);
}
</style>
