<template>
  <div class="home">
    <!-- <Navbar></Navbar> -->
    <Suspense>
      <HomeHeading />
    </Suspense>
    <div
      class="block p-0 body-custom w-full px-5"
      style="max-width: 1440px"
    >
      <OnlineSupport />
      <div class="mt-20">
        <Suspense>
          <SearchBox
            :vessel_id="vessel_id"
            @search="search"
          />
        </Suspense>
      </div>

      <div class="flex flex-col mb-10">
        <div
          class="blue py-7 text-center text-lg md:text-xl md:text-start uppercase home-page-title"
        >
          <p>
            {{ $t("voyages.titleNor") }}
          </p>
        </div>
        <!-- Voyage Section -->
        <Suspense>
          <VoyageComponent :handleSearch="handleSearchCount" />
        </Suspense>
      </div>
      <!-- Suites Section -->
      <div class="flex flex-col mt-20 mb-10">
        <Suspense>
          <SuiteComponent :handleSearch="handleSearchCount" />
        </Suspense>
      </div>
      <!-- Service Section -->
      <div class="xl:h-full xl:w-auto mt-20 mb-10">
        <Suspense>
          <VesselServiceHomeComponent />
        </Suspense>
      </div>
      <!-- Activities Section -->
      <div class="xl:h-full xl:w-auto mt-20 mb-10">
        <Suspense>
          <ActivityHomeComponent />
        </Suspense>
      </div>

      <!-- Venue Section -->
      <Suspense>
        <VenueHomeComponent />
      </Suspense>

      <!-- Mice Section -->
      <Suspense>
        <MiceHomeComponent />
      </Suspense>

      <!-- Travel Section -->
      <div class="xl:h-fit xl:w-full mt-20 mb-10">
        <div class="flex flex-col">
          <div class="blue text-center text-lg md:text-xl md:text-start uppercase">
            <p>{{ $t("blog.title") }}</p>
          </div>
          <div class="flex flex-col md:flex-row items-center">
            <div class="flex flex-row flex-1">
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div
                    class="blue text-4xl mt-3 md:mt-0 md:text-5xl text-center md:text-start home-page-title"
                  >
                    <p class="overflow-hidden text-wrap py-5">
                      {{ $t("blog.titleNor") + $t("blog.titleItalic") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Suspense>
            <PostCardComponent />
          </Suspense>
        </div>
      </div>

      <!-- Guest's Review Section -->
      <Suspense>
        <ReviewHomeComponent />
      </Suspense>

      <Suspense>
        <FormInformationComponent />
      </Suspense>
    </div>

    <Footer></Footer>
    <Menu class="top-0"></Menu>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Menu from "@/components/Menu.vue";
import VoyageComponent from "@/components/voyage/VoyageComponent.vue";
import SuiteComponent from "@/components/suite/SuiteComponent.vue";
import VesselServiceHomeComponent from "@/components/vessel_service/VesselServiceHomeComponent.vue";
import ActivityHomeComponent from "@/components/activity/ActivityHomeComponent.vue";
import PostCardComponent from "@/components/post/PostCardComponent.vue";
import HomeHeading from "@/components/heading/HomeHeading.vue";
import ReviewHomeComponent from "@/components/review/ReviewHomeComponent.vue";
import SearchBox from "@/components/SearchBox.vue";
import VenueHomeComponent from "@/components/venue/VenueHomeComponent.vue";
import MiceHomeComponent from "@/components/mice/MiceHomeComponent.vue";
import OnlineSupport from "@/components/OnlineSupport.vue";
import FormInformationComponent from "@/components/FormInformationComponent.vue";

export default {
  name: "HomeView",
  data() {
    return {
      options: ["Hạ Long", "Sung Sot Cave", "Hà Nội"],
      vessel_id: localStorage.vessel,
      handleSearchCount: 0,
    };
  },
  components: {
    // Blur,
    // PageLogo,
    // Combobox,
    // Button,
    Footer,
    // Navbar,
    Menu,
    VoyageComponent,
    // CardNews,
    // ComboboxV2,
    SuiteComponent,
    VenueHomeComponent,
    VesselServiceHomeComponent,
    ActivityHomeComponent,
    PostCardComponent,
    HomeHeading,
    ReviewHomeComponent,
    SearchBox,
    MiceHomeComponent,
    OnlineSupport,
    FormInformationComponent,
  },
  methods: {
    search() {
      this.handleSearchCount++;
    },
  },
};
</script>
