<template>
  <div class="card flex flex-col">
    <div class="flex flex-col">
      <div
        class="card-img bg-cover"
        :style="{ 'background-image': 'url(' + imgUrl + ')' }"
      ></div>
    </div>
    <div class="p-9 flex flex-col justify-between h-full">
      <div
        class="card-title blue"
        style="font-size: 20px"
        v-html="card_title"
      ></div>

      <div class="flex flex-col">
        <div
          class="card-heading__1 text-xs md:text-base blue pt-5 pb-3"
          style="font-weight: 400"
          v-html="bed_number"
        ></div>
        <div
          class="card-heading__1 text-xs md:text-base blue pb-3"
          style="font-weight: 400"
          v-html="suite_size"
        ></div>
        <div
          class="card-heading__1 text-xs md:text-base blue pb-3"
          style="font-weight: 400"
          v-html="occupancy"
        ></div>
        <!-- <div
          class="card-heading__1 blue pb-5 text-xs md:text-base"
          style="font-weight: 400"
        >
          {{ formatNumberWithCommas(price) }} VND
        </div> -->
      </div>
      <div class="flex flex-row gap-4 mt-10">
        <Button
          button_v="0"
          :text="$t('exploreButton')"
          @click="redirectTo(suite_id)"
        ></Button>
        <Button
          button_v="1"
          :text="$t('inquireNowButton')"
          @click="redirectToBooking"
        ></Button>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
import { formatNumberWithCommas } from "@/helpers/convert.number";
import VesselService from "@/services/vessel.service";

const SuiteCardComponent = {
  props: {
    card_title: {
      type: String,
    },
    bed_number: {
      type: Number,
    },
    suite_size: {
      type: Number,
    },
    sundeck_size: {
      type: Number,
    },
    imgUrl: {
      type: String,
    },
    occupancy: {
      type: String,
    },
    price: {
      type: Number,
    },
    suite_id: {
      type: Number,
    },
  },
  components: {
    Button,
  },
  data() {
    return {
      booking_number: 1,
    };
  },
  created() {
    this.getBookingNumber();
  },
  methods: {
    getBookingNumber() {
      VesselService.getData()
        .then((res) => {
          const data = res.data.data.vessels.filter((e) => {
            return e.id == localStorage.vessel;
          });
          this.booking_number = data[0].order_number;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    redirectTo(id) {
      this.$router.push("/suite-detail/" + id).then(() => {
        window.location.reload();
      });
    },
    formatNumberWithCommas,
    redirectToBooking() {
      this.$router.push("/vessels/" + this.booking_number + "/booking");
    },
  },
};
export default SuiteCardComponent;
</script>
<style></style>
