import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import VoyageSearch from "@/views/VoyageSearch.vue";
import VoyageView from "@/views/VoyageView.vue";
import SuiteDetail from "@/views/SuiteDetail.vue";
import SuiteView from "@/views/SuiteView.vue";
import ServiceView from "@/views/ServiceView.vue";
import ServiceDetail from "@/views/ServiceDetail.vue";
import BlogView from "@/views/BlogView.vue";
import AboutUs from "@/views/AboutUs.vue";
import AdminLogin from "@/views/Admin/AdminLogin.vue";
import AdminDashboard from "@/views/Admin/AdminDashboard.vue";
import BlogDetail from "@/views/BlogDetail.vue";
import ActivityView from "@/views/ActivityView.vue";
import ActivityDetail from "@/views/ActivityDetail.vue";
import BookingVessel1 from "@/views/BookingVessel1.vue";
import BookingVessel2 from "@/views/BookingVessel2.vue";
import { getI18n } from "@/main";
import CancelPolicy from "@/views/CancelPolicy.vue";
import VenueView from "@/views/VenueView.vue";
import VenueDetail from "@/views/VenueDetail.vue";
import MiceView from "@/views/MiceView.vue";
import MiceDetail from "@/views/MiceDetail.vue";

const routes = [
  {
    path: "/",
    redirect: { name: "home" },
    meta: {
      title: "tab.home",
      metaTags: [
        {
          name: "description",
          content:
            "Grand Pioneers Cruise (formerly Essence Grand Cruise) is the greenest, safest and the most modern cruise in Ha Long Bay, leading The Legacy Journey.",
        },
        {
          property: "og:title",
          content: "tab.home",
        },
      ],
      requiresAuth: false,
    },
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    meta: {
      title: "tab.home",
      metaTags: [
        {
          name: "description",
          content:
            "Grand Pioneers Cruise (formerly Essence Grand Cruise) is the greenest, safest and the most modern cruise in Ha Long Bay, leading The Legacy Journey.",
        },
        {
          property: "og:title",
          content: "tab.home",
        },
      ],
      requiresAuth: false,
    },
  },
  {
    path: "/suites/:id?",
    name: "suites",
    component: SuiteView,
    props: (route) => ({ id: route.params.id || "1" }),
    meta: {
      title: "tab.suiteview",
      metaTags: [
        {
          name: "description",
          content:
            "Surrounded by pristine natural wonders and the crystal blue waters of the bay, every suite on the Grand Pioneers cruise is a perfect place for healing.",
        },
        {
          property: "og:title",
          content: "tab.suiteview",
        },
      ],
      requiresAuth: false,
    },
  },
  {
    path: "/services/:id?",
    name: "services",
    component: ServiceView,
    props: (route) => ({ id: route.params.id || "1" }),
    meta: {
      title: "tab.serviceview",
      metaTags: [
        {
          name: "description",
          content:
            "Fully enjoy the beauty of the nature, locals, and culture of Halong Bay through engaging activities",
        },
        {
          property: "og:title",
          content: "tab.serviceview",
        },
      ],
      requiresAuth: false,
    },
  },
  {
    path: "/voyages",
    name: "voyages",
    component: VoyageSearch,
    meta: { title: "tab.voyagesearch", requiresAuth: false },
  },
  {
    path: "/voyage-view/:id",
    name: "voyage-view",
    component: VoyageView,
    props: true,
    meta: { title: "tab.voyageview", requiresAuth: false },
  },
  {
    path: "/suite-detail/:id",
    name: "suite-detail",
    component: SuiteDetail,
    props: true,
    meta: { title: "tab.suitedetail", requiresAuth: false },
  },

  {
    path: "/service-detail/:id",
    name: "service-detail",
    component: ServiceDetail,
    props: true,
    meta: { title: "tab.servicedetail", requiresAuth: false },
  },
  {
    path: "/blogs",
    name: "blogs",
    component: BlogView,
    meta: { title: "tab.blogview", requiresAuth: false },
  },
  {
    path: "/activities",
    name: "activities",
    component: ActivityView,
    meta: { title: "tab.activityView", requiresAuth: false },
  },
  {
    path: "/activity-detail/:id",
    name: "activity-detail",
    component: ActivityDetail,
    props: true,
    meta: { title: "tab.activityDetail", requiresAuth: false },
  },
  {
    path: "/venues",
    name: "venues",
    component: VenueView,
    meta: { title: "tab.venues", requiresAuth: false },
  },
  {
    path: "/venue-detail/:id",
    name: "venue-detail",
    component: VenueDetail,
    props: true,
    meta: { title: "tab.venueDetail", requiresAuth: false },
  },
  {
    path: "/mices",
    name: "mices",
    component: MiceView,
    meta: { title: "tab.mices", requiresAuth: false },
  },
  {
    path: "/mice-detail/:id",
    name: "mice-detail",
    component: MiceDetail,
    props: true,
    meta: { title: "tab.miceDetail", requiresAuth: false },
  },
  // {
  //   path: "/career-view",
  //   name: "career-view",
  //   component: CareerView,
  //   meta: { title: "tab.careerview", requiresAuth: false },
  // },
  {
    path: "/about-us/:id",
    name: "about-us",
    component: AboutUs,
    props: true,
    meta: { title: "tab.aboutus", requiresAuth: false },
  },
  {
    path: "/cancel-policy/:id",
    name: "cancel-policy",
    component: CancelPolicy,
    props: true,
    meta: { title: "tab.cancelPolicy", requiresAuth: false },
  },
  {
    path: "/vessels/1/booking",
    component: BookingVessel1,
    meta: { title: "tab.booking", requiresAuth: false },
  },
  {
    path: "/vessels/2/booking",
    component: BookingVessel2,
    meta: { title: "tab.booking", requiresAuth: false },
  },
  // {
  //   path: "/innovation-view",
  //   name: "innovation-view",
  //   component: InnovationView,
  //   meta: { title: "tab.aboutus", requiresAuth: false },
  // },
  // {
  //   path: "/login",
  //   name: "login-view",
  //   component: LoginView,
  //   meta: { title: "tab.login", requiresAuth: false },
  // },
  {
    path: "/blog-detail/:id",
    name: "blog-detail",
    component: BlogDetail,
    props: true,
    meta: { title: "tab.blogdetail", requiresAuth: false },
  },
  {
    path: "/admin1970",
    redirect: { name: "adminlogin" },
    meta: { title: "Admin | Login", requiresAuth: false },
  },
  {
    path: "/admin1970/login",
    name: "adminlogin",
    component: AdminLogin,
    meta: { title: "Admin | Login", requiresAuth: false },
  },
  {
    path: "/admin1970/dashboard",
    name: "admindashboard",
    component: AdminDashboard,
    meta: { title: "Admin | Dashboard", requiresAuth: true },
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "home" },
    meta: {
      title: "tab.home",
      metaTags: [
        {
          name: "description",
          content:
            "Grand Pioneers Cruise (formerly Essence Grand Cruise) is the greenest, safest and the most modern cruise in Ha Long Bay, leading The Legacy Journey.",
        },
        {
          property: "og:title",
          content: "tab.home",
        },
      ],
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.jwt_token;
  const role = localStorage.role;
  const i18n = getI18n(); // Lấy instance i18n từ hàm getI18n

  const title = to.meta.title;
  if (title) {
    // Use i18n to translate the title if it's present
    document.title = i18n.global.t(title);
  }

  // Remove any stale meta tags from the document using the key attribute we set below
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).forEach((el) =>
    el.parentNode.removeChild(el)
  );

  // Check if there are any meta tags to render
  const metaTags = to.meta.metaTags;
  if (metaTags && metaTags.length) {
    // Turn the meta tag definitions into actual elements in the head
    metaTags
      .map((tagDef) => {
        const tag = document.createElement("meta");

        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });

        // Use this to track which meta tags we create so we don't interfere with other ones
        tag.setAttribute("data-vue-router-controlled", "");

        return tag;
      })
      .forEach((tag) => document.head.appendChild(tag));
  }

  if (to.meta.requiresAuth) {
    if (isLoggedIn) {
      if (role == 1) {
        next();
      } else {
        next("/home");
      }
    } else {
      next("/admin1970/login");
    }
  } else {
    if (to.path.includes("admin")) {
      if (isLoggedIn) {
        if (role == 1) {
          next("/admin1970/dashboard");
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
