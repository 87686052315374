<template>
  <div class="container mx-auto px-4 py-8 text-xs">
    <div class="flex-r items-center mb-4 justify-between">
      <div class="flex flex-row gap-4">
        <h1 class="text-2xl font-bold">Email List</h1>
        <button
          class="text-black border border-black px-4 py-2 rounded text-sm hover:bg-blue-500 hover:text-white"
          @click="bulkSendEmail"
          v-if="this.selectedEmail.length > 0"
        >
          Send To Selected Email
        </button>
      </div>
      <div
        class="flex-r font-semibold gap-2 items-center cursor-pointer"
        @click="openFormAddActivity"
      >
        <div class="addBtn bg-contain"></div>
        Add new
      </div>
    </div>

    <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
      <table
        class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
      >
        <thead>
          <tr class="text-left">
            <th class="py-2 px-3 sticky top-0 bg-gray-200">
              <input
                type="checkbox"
                @click="selectAllEmail"
                :checked="isSelectAll"
              />
            </th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Fullname</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Email</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Phone</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Created At</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Updated At</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in activities"
            :key="data.id"
          >
            <td class="border px-4 py-2">
              <input
                type="checkbox"
                :value="data.email"
                :checked="selectedEmail.includes(data.email)"
                @click="selectEmail(data.email)"
              />
            </td>
            <td class="border px-4 py-2">{{ data.id }}</td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">{{ data.fullname }}</td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">{{ data.phone }}</td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">{{ data.email }}</td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ formatDateTime(data.created_at) }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ formatDateTime(data.updated_at) }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
              <div class="grid grid-cols-2 w-full h-full gap-4">
                <button
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                  @click="openUpdateActivities(data.id, data.email, data.fullname, data.phone)"
                >
                  Edit Activity
                </button>
                <button
                  class="bg-red-500 text-white px-4 py-2 rounded"
                  @click="deleteActivity(data.id)"
                >
                  Delete
                </button>

                <button
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                  @click="sendEmail(data.email)"
                >
                  Send Email
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Add Activity -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isAddActivityOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Add Email</h2>
      <div class="grid grid-cols-2 gap-8">
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Fullname</div>
          <input
            type="text"
            class="form-input h-10 p-3"
            v-model="formActivity.fullname"
          />
        </div>
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Email</div>
          <input
            type="email"
            class="form-input h-10 p-3"
            v-model="formActivity.email"
          />
        </div>
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Phone</div>
          <input
            type="phone"
            class="form-input h-10 p-3"
            v-model="formActivity.phone"
          />
        </div>
      </div>

      <div class="flex-r gap-3">
        <button
          @click="addActivities()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Email
        </button>
        <button
          @click="isAddActivityOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Update Activity -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isUpdateActivityOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Update Email</h2>

      <div class="grid grid-cols-2 gap-8">
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Fullname</div>
          <input
            type="email"
            class="form-input h-10 p-3"
            v-model="formUpdateActivity.fullname"
          />
        </div>
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Email</div>
          <input
            type="email"
            class="form-input h-10 p-3"
            v-model="formUpdateActivity.email"
          />
        </div>
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Phone</div>
          <input
            type="phone"
            class="form-input h-10 p-3"
            v-model="formUpdateActivity.phone"
          />
        </div>
      </div>

      <div class="flex-r gap-3">
        <button
          @click="UpdateActivities()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Update Email
        </button>
        <button
          @click="isUpdateActivityOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <LoadingPage :isLoading="isLoading" />
</template>

<script>
import EmailCustomerService from "@/services/email.service";
import LoadingPage from "@/components/LoadingPage.vue";

import { notify } from "@kyvg/vue3-notification";

export default {
  name: "ActivityPortal",
  components: {
    LoadingPage,
  },
  data() {
    return {
      activities: [],
      activity_metadata: [],
      activity_id: "",
      isAddActivityOpen: false,
      formActivity: {
        email: "",
        fullname: "",
        phone: "",
      },
      isUpdateActivityOpen: false,
      formUpdateActivity: {
        email: "",
        fullname: "",
        phone: "",
      },
      isFormUploadImageActivityOpen: false,
      isLoading: false,
      languages: [{ lang: "en" }, { lang: "vn" }],
      imageLink: "",
      selectedEmail: [],
      isSelectAll: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatDateTime(datetime) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(datetime).toLocaleDateString("en-US", options);
    },
    handleSelectLanguage(event) {
      const language = event.target.value;
      this.formActivity.language = language;
      this.formUpdateActivity.language = language;
    },
    getData() {
      this.isLoading = true;
      EmailCustomerService.getData()
        .then((response) => {
          this.activities = response.data.data.email_customers;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    closeForm() {
      this.isAddActivityOpen = false;
    },

    openFormAddActivity() {
      this.isAddActivityOpen = true;
      this.formActivity.email = "";
      this.formActivity.phone = "";
      this.formActivity.fullname = "";
    },

    addActivities() {
      this.isLoading = true;
      if (
        this.formActivity.email == "" ||
        this.formActivity.fullname == "" ||
        this.formActivity.phone == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        EmailCustomerService.storeData(this.formActivity)
          .then(() => {
            EmailCustomerService.getData()
              .then((res) => {
                this.activities = res.data.data.email_customers;
                this.isAddActivityOpen = false;
                notify({ type: "success", title: "Success", text: "Add Successfully!" });
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    deleteActivity(id) {
      this.isLoading = true;
      EmailCustomerService.deleteData(id)
        .then(() => {
          EmailCustomerService.getData()
            .then((res) => {
              this.activities = res.data.data.email_customers;
              notify({ type: "success", title: "Success", text: "Delete Successfully!" });
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    openUpdateActivities(id, email, fullname, phone) {
      this.activity_id = id;
      this.formUpdateActivity.email = email;
      this.formUpdateActivity.fullname = fullname;
      this.formUpdateActivity.phone = phone;
      this.isUpdateActivityOpen = true;
    },

    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    UpdateActivities() {
      this.isLoading = true;
      if (
        this.formUpdateActivity.email == "" ||
        this.formUpdateActivity.fullname == "" ||
        this.formUpdateActivity.phone == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        EmailCustomerService.updateData(this.activity_id, this.formUpdateActivity)
          .then(() => {
            EmailCustomerService.getData()
              .then((res) => {
                this.activities = res.data.data.email_customers;
                notify({ type: "success", title: "Success", text: "Update Successfully!" });
                this.isUpdateActivityOpen = false;
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    //Email

    selectEmail(email) {
      if (this.selectedEmail.includes(email)) {
        const index = this.selectedEmail.indexOf(email);
        if (index != -1) {
          this.selectedEmail.splice(index, 1);
        }
      } else {
        this.selectedEmail.push(email);
      }
    },

    isArrayIncludesAll(mainArray, subArray) {
      return subArray.every((item) => mainArray.includes(item));
    },

    selectAllEmail() {
      this.isSelectAll = !this.isSelectAll;
      if (this.isSelectAll) {
        this.activities.forEach((e) => {
          if (!this.selectedEmail.includes(e.email)) {
            this.selectedEmail.push(e.email);
          }
        });
      } else {
        this.selectedEmail = [];
      }
    },

    bulkSendEmail() {
      window.location.href = "mailto:" + encodeURIComponent(this.selectedEmail);
    },

    sendEmail(email) {
      window.location.href = "mailto:" + email;
    },
  },
};
</script>

<style scoped>
table th,
td {
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 2;
  width: fit-content;
}

thead .sticky-col {
  z-index: 5;
}
</style>
