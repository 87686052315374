<template>
  <div class="custom-select">
    <!-- <div
      class="selected-option"
      @click="toggleDropdown"
    >
      <span>{{ selectedLabel }}</span>
      <font-awesome-icon
        :icon="['fas', 'chevron-down']"
        style="transform: rotate(180deg)"
      />
    </div> -->
    <div class="options-list flex gap-4 items-center">
      <div
        v-for="option in options"
        :key="option.value"
        @click="selectOption(option.value)"
        :class="{ selected: option.value === selected }"
      >
        <img
          v-if="option.icon"
          :src="option.icon"
          alt=""
        />
        <!-- <span>{{ option.label }}</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

const NavbarPc = {
  name: "NavbarPc",
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(["language"]),
    selected: {
      get() {
        return this.language;
      },
      set(value) {
        this.updateLanguage(value);
      },
    },
    selectedLabel() {
      const selectedOption = this.options.find((option) => option.value === this.selected);
      return selectedOption ? selectedOption.label : "Select";
    },
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  methods: {
    ...mapMutations(["setLanguage"]),
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectOption(value) {
      this.setLanguage(value);
      this.dropdownOpen = false;
      this.$emit("input", value);
      window.location.reload();
    },
    updateLanguage(value) {
      this.setLanguage(value);
    },
  },
};
export default NavbarPc;
</script>

<style scoped>
.custom-select {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

span {
  color: white;
}

img {
  width: 20px;
  height: 19px;
  object-fit: contain;
  max-width: none;
}

.selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border-bottom: 1px solid white;
  width: fit-content;
  padding-bottom: 2px;

  text-transform: uppercase;
  letter-spacing: 0.2vw;
  transform: translateY(1px);
}

.options-list {
  display: flex;

  top: 100%;
  left: 0;
  width: 100%;

  max-height: 200px;

  border-radius: 4px;
  z-index: 1000;
  list-style: none;

  margin: 0;
  padding: 0;
}

.options-list li {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 4px;
}

.options-list li span {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  color: white;
}

.options-list li.selected {
  background-color: rgba(80, 80, 80, 0.5);
}

.options-list li:hover {
  background-color: rgba(160, 160, 160, 0.5);
}

.options-list img {
  margin-right: 0.5rem;
}

@media only screen and (min-width: 780px) {
  .options-list {
    top: -346%;
  }

  .selected-option {
    min-width: 112px;
  }
}
</style>
