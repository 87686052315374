import axios from "axios";
import { authHeader } from "@/services/base.service";
import { BASE_API_URL } from "@/helpers/base.api.url";

class VenueService {
  async getData() {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/venues",
    });
  }
  async getDataByVoyage() {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/venues",
    });
  }

  async getDataById(venue_id) {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/venues/" + venue_id,
    });
  }

  async storeData(venue) {
    return await axios.post(BASE_API_URL + "/venues", venue, {
      headers: authHeader(),
    });
  }

  async updateData(venue_id, data) {
    return await axios({
      method: "put",
      url: BASE_API_URL + "/venues/" + venue_id,
      data: data,
      headers: authHeader(),
    });
  }

  async deleteData(venue_id) {
    return await axios({
      method: "delete",
      url: BASE_API_URL + "/venues/" + venue_id,
      headers: authHeader(),
    });
  }
}

export default new VenueService();
