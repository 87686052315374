<template>
    <div class="blur bg-cover">
    </div>
</template>
<script>
    const Blur={
    }
    export default Blur;
</script>
<style>

</style>