<template>
  <div class="xl:h-fit xl:w-full mt-20 mb-10">
    <div class="blue text-center text-lg md:text-xl md:text-start xl:mb-10 uppercase">
      <p>{{ $t("review.title") }}</p>
    </div>
    <div
      class="flex"
      style="justify-content: center"
    >
      <div class="flex-c page-name">
        <div
          class="blue text-4xl mt-3 md:mt-0 md:text-5xl text-center overflow-hidden py-5 home-page-title"
        >
          <p>{{ $t("review.subTitle") }}</p>
        </div>
      </div>
    </div>
    <swiper
      :slides-per-view="perPage"
      :space-between="10"
      class="w-full mb-8 h-full md:grid md:items-stretch"
      @swiper="onSwiper"
      :loop="true"
      :navigation="true"
      :modules="modules"
    >
      <swiper-slide
        v-for="(item, index) in itemsPaginated"
        :key="index"
        class="flex justify-center items-center"
      >
        <div class="flex flex-row items-center my-6">
          <div class="flex flex-col items-center w-full">
            <div
              class="flex flex-row items-center justify-center blue text-center md:text-start text-xs md:text-base font-normal mb-4 w-full xl:w-1/2"
              v-html="item[`content_${language}`]"
            ></div>
            <div
              class="font-bold blue"
              v-html="item[`author_${language}`]"
            ></div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup>
import ReviewService from "@/services/review.service";
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const language = ref(localStorage.language);

let itemsPaginated = [];
try {
  const review = await ReviewService.getData();
  itemsPaginated = review.data.data.reviews;
} catch (err) {
  console.log(err);
}

const mySwiper = ref(null);

const modules = ref([Navigation]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};
</script>
<style></style>
