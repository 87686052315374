import axios from "axios";
import { authHeader } from "@/services/base.service";
import { BASE_API_URL } from "@/helpers/base.api.url";

class EmailCustomerService {
  async getData() {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/email-customer",
    });
  }
  async getDataByVoyage() {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/email-customer",
    });
  }

  async getDataById(email_id) {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/email-customer/" + email_id,
    });
  }

  async storeData(email) {
    return await axios({
      method: "post",
      url: BASE_API_URL + "/email-customer",
      data: email,
    });
  }

  async updateData(email_id, data) {
    return await axios({
      method: "put",
      url: BASE_API_URL + "/email-customer/" + email_id,
      data: data,
      headers: authHeader(),
    });
  }

  async deleteData(email_id) {
    return await axios({
      method: "delete",
      url: BASE_API_URL + "/email-customer/" + email_id,
      headers: authHeader(),
    });
  }
}

export default new EmailCustomerService();
