<template>
  <div class="container mx-auto px-4 py-8 text-xs">
    <div class="flex-r items-center mb-4 justify-between">
      <h1 class="text-2xl font-bold">Review List</h1>
      <div
        class="flex-r font-semibold gap-2 items-center cursor-pointer"
        @click="openAdd"
      >
        <div class="addBtn bg-contain"></div>
        Add new
      </div>
    </div>

    <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
      <table
        class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
      >
        <thead>
          <tr class="text-left">
            <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Author Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Author Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in review"
            :key="data.id"
          >
            <td class="border px-4 py-2">{{ data.id }}</td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.author_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.author_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.content_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.content_vn"
            ></td>
            <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
              <div class="w-full h-full grid grid-cols-2 gap-4">
                <button
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                  @click="
                    openUpdate(
                      data.id,
                      data.author_en,
                      data.author_vn,
                      data.content_en,
                      data.content_vn
                    )
                  "
                >
                  Edit Review
                </button>
                <button
                  class="bg-red-500 text-white px-4 py-2 rounded"
                  @click="deleteReview(data.id)"
                >
                  Delete
                </button>

                <button
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                  @click="
                    openDuplicate(data.author_en, data.author_vn, data.content_en, data.content_vn)
                  "
                >
                  Duplicate
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Add Review -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isAddOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <div class="text-xl font-bold pb-2">Add Review</div>
      <div class="grid grid-cols-2 gap-8">
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Author Eng</div>
          <input
            type="text"
            v-model="form.author_en"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
        </div>
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Author Vie</div>
          <input
            type="text"
            v-model="form.author_vn"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Content Eng</div>
          <ckeditor
            :editor="editor"
            v-model="form.content_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Content Vie</div>
          <ckeditor
            :editor="editor"
            v-model="form.content_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>
      </div>

      <div class="flex-r gap-3">
        <button
          @click="addReview()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Review
        </button>
        <button
          @click="isAddOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Update Review -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isUpdateOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <div class="text-xl font-bold pb-2">Update Review</div>
      <div class="grid grid-cols-2 gap-8">
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Author Eng</div>
          <input
            type="text"
            v-model="formUpdate.author_en"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Author Vie</div>
          <input
            type="text"
            v-model="formUpdate.author_vn"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Content Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdate.content_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Content Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdate.content_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>
      </div>

      <div class="flex-r gap-3">
        <button
          @click="updateReview()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Update Review
        </button>
        <button
          @click="isUpdateOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <LoadingPage :isLoading="isLoading" />
</template>

<script>
import ReviewService from "@/services/review.service";
import LoadingPage from "@/components/LoadingPage.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
} from "ckeditor5";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "ReviewPortal",
  components: {
    LoadingPage,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertTable",
          "mediaEmbed",
          "|",
          "bulletedList",
          "numberedList",
          "indent",
          "outdent",
        ],
        plugins: [
          Bold,
          Essentials,
          Heading,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          Undo,
        ],
      },
      menu: [],
      Review_metadata: [],
      menu_id: "",
      vessel_id: "",
      isOpen: false,
      isAddOpen: false,
      form: {
        content_en: "",
        content_vn: "",
        author_en: "",
        author_vn: "",
      },
      isUpdateOpen: false,
      formUpdate: {
        content_en: "",
        content_vn: "",
        author_en: "",
        author_vn: "",
      },
      isFormuploadImageOpen: false,
      isLoading: false,
      languages: [{ lang: "en" }, { lang: "vn" }],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatDateTime(datetime) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(datetime).toLocaleDateString("en-US", options);
    },

    handleSelectLanguage(event) {
      const language = event.target.value;
      this.form.language = language;
      this.formUpdate.language = language;
    },

    getData() {
      this.isLoading = true;
      ReviewService.getData()
        .then((response) => {
          this.review = response.data.data.reviews;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    closeForm() {
      this.isAddOpen = false;
      this.isOpen = false;
    },

    openAdd() {
      this.isAddOpen = true;
      this.form.content_en = "";
      this.form.content_vn = "";
      this.form.author_en = "";
      this.form.author_vn = "";
    },

    addReview() {
      this.isLoading = true;
      if (
        this.form.content_en == "" ||
        this.form.content_vn == "" ||
        this.form.author_en == "" ||
        this.form.author_vn == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        ReviewService.storeData(this.form)
          .then(() => {
            ReviewService.getData()
              .then((res) => {
                this.review = res.data.data.reviews;
                this.isAddOpen = false;
                notify({ type: "success", title: "Success", text: "Add Successfully!" });
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    deleteReview(id) {
      this.isLoading = true;
      ReviewService.deleteData(id)
        .then(() => {
          ReviewService.getData()
            .then((res) => {
              this.review = res.data.data.reviews;
              notify({ type: "success", title: "Success", text: "Delete Successfully!" });
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    openUpdate(id, author_en, author_vn, content_en, content_vn) {
      this.review_id = id;
      this.formUpdate.author_en = author_en;
      this.formUpdate.author_vn = author_vn;
      this.formUpdate.content_en = content_en;
      this.formUpdate.content_vn = content_vn;
      this.isUpdateOpen = true;
    },

    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    updateReview() {
      this.isLoading = true;
      if (
        this.formUpdate.author_en == "" ||
        this.formUpdate.author_vn == "" ||
        this.formUpdate.content_vn == "" ||
        this.formUpdate.content_en == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        ReviewService.updateData(this.review_id, this.formUpdate)
          .then(() => {
            ReviewService.getData()
              .then((res) => {
                this.review = res.data.data.reviews;
                notify({ type: "success", title: "Success", text: "Update Successfully!" });
                this.isUpdateOpen = false;
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    openDuplicate(author_en, author_vn, content_en, content_vn) {
      this.isAddOpen = true;
      this.form.content_en = content_en;
      this.form.content_vn = content_vn;
      this.form.author_en = author_en;
      this.form.author_vn = author_vn;
    },
  },
};
</script>

<style scoped>
table th,
td {
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 2;
  width: fit-content;
}

thead .sticky-col {
  z-index: 5;
}
</style>
