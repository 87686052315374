<template>
  <div class="service-view">
    <Suspense>
      <ActivityHeading />
    </Suspense>
    <div
      class="block p-0 body-custom w-full px-5"
      style="max-width: 1440px"
    >
      <div class="flex flex-col items-center gap-8">
        <!-- <div class="flex flex-row justify-center gap-8 w-full mb-8">
          <Button
            button_v="0"
            :text="$t('exploreButton')"
          ></Button>
          <Button
            button_v="1"
            :text="$t('inquireNowButton')"
          ></Button>
        </div> -->
      </div>
      <Suspense>
        <ActivityAllComponent />
      </Suspense>
    </div>
  </div>
  <Menu></Menu>
  <Footer></Footer>
</template>
<script>
import Footer from "@/components/Footer.vue";
// import PageLogo from "@/components/PageLogo.vue";
// import Button from "@/components/Button.vue";
import Menu from "@/components/Menu.vue";
import ActivityAllComponent from "@/components/activity/ActivityAllComponent.vue";
import ActivityHeading from "@/components/heading/ActivityHeading.vue";

export default {
  name: "ActivityView",
  components: {
    Footer,
    // PageLogo,
    // Button,
    ActivityAllComponent,
    Menu,
    ActivityHeading,
  },
  data() {
    return {
      selectedOption: "grid",
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
    },
  },
};
</script>
<style scoped>
.selected {
  font-weight: bold;
  border-bottom: 2px solid var(--blue-color);
}
</style>
