<template>
  <div class="container mx-auto px-4 py-8 text-xs">
    <div class="flex-r items-center mb-4 justify-between">
      <h1 class="text-2xl font-bold">Blogs List</h1>
      <div
        class="flex-r font-semibold gap-2 items-center cursor-pointer"
        @click="openBlogForm"
      >
        <div class="addBtn bg-contain"></div>
        Add new
      </div>
    </div>

    <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
      <table
        class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
      >
        <thead>
          <tr class="text-left">
            <th class="py-2 px-3 sticky top-0 bg-gray-200">No.</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Description Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Description Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Thumbnail</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Post Section</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Created At</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Updated At</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in datas"
            :key="data.id"
          >
            <td class="border px-4 py-2">{{ data.stt }}</td>
            <td class="border px-4 py-2">{{ data.id }}</td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.title_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.title_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.description_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.description_vn"
            ></td>
            <td class="border px-4 py-2 min-w-72 text-wrap">
              <img :src="data.thumbnail" />
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              <button
                @click="viewSection(data.id)"
                class="border p-4"
              >
                View Post section
              </button>
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ formatDateTime(data.created_at) }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ formatDateTime(data.updated_at) }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
              <div class="h-full w-full grid grid-cols-2 gap-4">
                <button
                  @click="
                    openUpdateBlogForm(
                      data.title_en,
                      data.title_vn,
                      data.description_en,
                      data.description_vn,
                      data.thumbnail,
                      data.id,
                      data.stt
                    )
                  "
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Edit
                </button>
                <button
                  @click="deleteBlog(data.id)"
                  class="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
                <button
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                  @click="
                    openDuplicate(
                      data.title_en,
                      data.title_vn,
                      data.description_en,
                      data.description_vn,
                      data.thumbnail,
                      data.stt
                    )
                  "
                >
                  Duplicate
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isSectionView"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <div class="flex-r gap-8">
        <h2 class="text-xl font-bold pb-2">Section View</h2>
        <div
          class="flex-r font-semibold gap-2 items-center cursor-pointer"
          @click="openSectionForm()"
          style="top: 0; left: 100%"
        >
          <div class="addBtn bg-contain"></div>
          Add new
        </div>
      </div>
      <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
        <table
          class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
        >
          <thead>
            <tr class="text-left">
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Id</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Post id</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Section name Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Section name Vie</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Vie</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Image link</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Image name Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Image name Vie</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Create At</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Update At</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="section in sections"
              :key="section.id"
            >
              <td class="border py-2 px-3 sticky top-0">{{ section.id }}</td>
              <td class="border py-2 px-3 sticky top-0">{{ section.post_id }}</td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="section.section_name_en"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="section.section_name_vn"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="section.content_en"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="section.content_vn"
              ></td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                <img :src="section.image_link" />
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                {{ section.image_name_en }}
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                {{ section.image_name_vn }}
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">{{ section.created_at }}</td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">{{ section.updated_at }}</td>
              <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
                <div class="w-full h-full grid grid-cols-2 gap-4">
                  <button
                    @click="
                      openEditSectionForm(
                        section.section_name_en,
                        section.section_name_vn,
                        section.content_en,
                        section.content_vn,
                        section.image_link,
                        section.image_name_en,
                        section.image_name_vn,
                        section.id
                      )
                    "
                    class="bg-blue-500 text-white px-2 py-1 rounded"
                  >
                    Edit
                  </button>
                  <button
                    @click="deletePostSection(section.id)"
                    class="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Delete
                  </button>
                  <button
                    @click="
                      openDuplicateSection(
                        section.section_name_en,
                        section.section_name_vn,
                        section.content_en,
                        section.content_vn,
                        section.image_link,
                        section.image_name_en,
                        section.image_name_vn
                      )
                    "
                    class="bg-blue-500 text-white px-2 py-1 rounded"
                  >
                    Duplicate
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex-r w-full justify-end">
        <button
          @click="closeSection"
          class="bg-gray-500 text-white px-4 py-2 rounded"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Blog Form -->
  <div
    v-if="isFormOpen"
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center overflow-auto"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Add New Blog</h2>
      <form
        @submit.prevent="addBlog"
        class="flex flex-col justify-between"
      >
        <div class="grid grid-cols-1 gap-8">
          <div class="grid grid-cols-2 gap-2">
            <div class="mb-2 w-full">
              <label class="block w-full">Title Eng</label>
              <ckeditor
                :editor="editor"
                v-model="blogForm.title_en"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="mb-2 w-full">
              <label class="block w-full">Title Vie</label>
              <ckeditor
                :editor="editor"
                v-model="blogForm.title_vn"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Description Eng</label>
              <ckeditor
                :editor="editor"
                v-model="blogForm.description_en"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Description Vie</label>
              <ckeditor
                :editor="editor"
                v-model="blogForm.description_vn"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Thumnail</label>
              <input
                type="text"
                v-model="blogForm.thumbnail"
                class="form-input h-10 p-3"
                style="width: 100%"
              />
              <div class="flex flex-row my-2 gap-2">
                <input
                  type="file"
                  @change="onFileChange"
                />
                <button
                  type="submit"
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                  @click="uploadImage"
                >
                  Upload
                </button>
              </div>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full"> Order </label>
              <vue-number-input
                v-model="blogForm.stt"
                :min="1"
                inline
                controls
              ></vue-number-input>
            </div>
          </div>
        </div>

        <div class="flex-r">
          <button
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Add Blog
          </button>
          <button
            type="button"
            @click="closeBlogForm"
            class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Update Blog Form -->
  <div
    v-if="isFormUpdateOpen"
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Update Blog</h2>
      <form
        @submit.prevent="updateBlog"
        class="flex flex-col justify-between"
      >
        <div class="grid grid-cols-1 gap-8">
          <div class="grid grid-cols-2 gap-8">
            <div class="mb-2 w-full">
              <label class="block w-full">Title Eng</label>
              <ckeditor
                :editor="editor"
                v-model="blogUpdateForm.title_en"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Title Vie</label>
              <ckeditor
                :editor="editor"
                v-model="blogUpdateForm.title_vn"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Description Eng</label>
              <ckeditor
                :editor="editor"
                v-model="blogUpdateForm.description_en"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Description Vie</label>
              <ckeditor
                :editor="editor"
                v-model="blogUpdateForm.description_vn"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Thumnail</label>
              <input
                type="text"
                v-model="blogUpdateForm.thumbnail"
                class="form-input h-10 p-3"
                style="width: 100%"
              />
              <div class="flex flex-row my-2 gap-2">
                <input
                  type="file"
                  @change="onFileChange"
                />
                <button
                  type="submit"
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                  @click="uploadImage"
                >
                  Upload
                </button>
              </div>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full"> Order </label>
              <vue-number-input
                v-model="blogUpdateForm.stt"
                :min="1"
                inline
                controls
              ></vue-number-input>
            </div>
          </div>
        </div>

        <div class="flex-r">
          <button
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Update Blog
          </button>
          <button
            type="button"
            @click="closeBlogForm"
            class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Add Section -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="addsectionForm"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Add Section</h2>
      <div class="grid grid-cols-2 gap-8">
        <div class="block w-full">
          <div class="font-semibold">Section name Eng</div>
          <ckeditor
            :editor="editor"
            v-model="sectionForm.section_name_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="block w-full">
          <div class="font-semibold">Section name Vie</div>
          <ckeditor
            :editor="editor"
            v-model="sectionForm.section_name_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="block w-full">
          <div class="font-semibold">Content Eng</div>
          <ckeditor
            :editor="editor"
            v-model="sectionForm.content_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="block w-full">
          <div class="font-semibold">Content Vie</div>
          <ckeditor
            :editor="editor"
            v-model="sectionForm.content_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="block w-full">
          <div class="font-semibold">Image Name Eng</div>
          <input
            type="text"
            v-model="sectionForm.image_name_en"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
        </div>

        <div class="block w-full">
          <div class="font-semibold">Image Name Vie</div>
          <input
            type="text"
            v-model="sectionForm.image_name_vn"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
        </div>

        <div class="block w-full">
          <div class="font-semibold">Image Link</div>
          <input
            type="text"
            v-model="sectionForm.image_link"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
          <div class="flex flex-row gap-2 my-2">
            <input
              type="file"
              @change="onFileChange"
            />
            <button
              type="submit"
              class="bg-blue-500 text-white px-4 py-2 rounded"
              @click="uploadImage"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
      <div class="flex-r">
        <button
          type="submit"
          @click="addPostSection()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Section
        </button>
        <button
          type="button"
          @click="closeSectionForm"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Update Section -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="updatesectionForm"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Update Section</h2>
      <div class="grid grid-cols-2 gap-8">
        <div class="block w-full">
          <div class="font-semibold">Section name Eng</div>
          <ckeditor
            :editor="editor"
            v-model="sectionEditForm.section_name_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="block w-full">
          <div class="font-semibold">Section name Vie</div>
          <ckeditor
            :editor="editor"
            v-model="sectionEditForm.section_name_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="block w-full">
          <div class="font-semibold">Content Eng</div>
          <ckeditor
            :editor="editor"
            v-model="sectionEditForm.content_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="block w-full">
          <div class="font-semibold">Content Vie</div>
          <ckeditor
            :editor="editor"
            v-model="sectionEditForm.content_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="block w-full">
          <div class="font-semibold">Image Name Eng</div>
          <input
            type="text"
            v-model="sectionEditForm.image_name_en"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
        </div>

        <div class="block w-full">
          <div class="font-semibold">Image Name Vie</div>
          <input
            type="text"
            v-model="sectionEditForm.image_name_vn"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
        </div>

        <div class="block w-full">
          <div class="font-semibold">Image Link</div>
          <input
            type="text"
            v-model="sectionEditForm.image_link"
            class="form-input h-10 p-3"
            style="width: 100%"
          />
          <div class="flex flex-row gap-2 my-2">
            <input
              type="file"
              @change="onFileChange"
            />
            <button
              type="submit"
              class="bg-blue-500 text-white px-4 py-2 rounded"
              @click="uploadImage"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
      <div class="flex-r">
        <button
          type="submit"
          @click="updatePostSection()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Update
        </button>
        <button
          type="button"
          @click="closeEditSectionForm"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
  <LoadingPage :isLoading="isLoading" />
</template>

<script>
import PostService from "@/services/post.service";
import axios from "axios";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
} from "ckeditor5";
import LoadingPage from "@/components/LoadingPage.vue";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "BlogPortal",
  components: { ckeditor: CKEditor.component, LoadingPage },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertTable",
          "mediaEmbed",
          "|",
          "bulletedList",
          "numberedList",
          "indent",
          "outdent",
        ],
        plugins: [
          Bold,
          Essentials,
          Heading,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          Undo,
        ],
      },
      datas: [],
      sections: [],
      isSectionView: false,
      isFormOpen: false,
      isFormUpdateOpen: false,
      addsectionForm: false,
      updatesectionForm: false,
      blogForm: {
        title_en: "",
        title_vn: "",
        description_en: "",
        description_vn: "",
        thumbnail: "",
        stt: "",
      },
      blogUpdateForm: {
        title_en: "",
        title_vn: "",
        description_en: "",
        description_vn: "",
        thumbnail: "",
        stt: "",
      },
      sectionForm: {
        section_name_en: "",
        section_name_vn: "",
        content_en: "",
        content_vn: "",
        image_link: "",
        image_name_en: "",
        image_name_vn: "",
      },
      sectionEditForm: {
        section_name_en: "",
        section_name_vn: "",
        content_en: "",
        content_vn: "",
        image_link: "",
        image_name_en: "",
        image_name_vn: "",
      },
      post_id: "",
      post_edit_id: "",
      post_section_edit: "",
      languages: [{ lang: "en" }, { lang: "vn" }],
      isLoading: false,
    };
  },
  mounted() {
    this.getBlog();
  },
  methods: {
    formatDateTime(datetime) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(datetime).toLocaleDateString("en-US", options);
    },
    getBlog() {
      this.isLoading = true;
      PostService.getData()
        .then((res) => {
          this.datas = res.data.data.posts;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    viewSection(id) {
      this.isLoading = true;
      this.post_id = id;
      this.isSectionView = true;
      try {
        PostService.getSection(id)
          .then((res) => {
            this.sections = res.data.data.post_sections;
            this.isLoading = false;
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      } catch (err) {
        notify({ type: "error", title: "Error", text: err.response.data.message });
        this.isLoading = false;
      }
    },

    closeSection() {
      this.isSectionView = false;
    },

    deleteBlog(id) {
      this.isLoading = true;
      try {
        PostService.deleteData(id)
          .then(() => {
            this.getBlog();
            notify({ type: "success", title: "Success", text: "Delete Successfully!" });
            this.isLoading = false;
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      } catch (error) {
        notify({ type: "error", title: "Error", text: error.response.data.message });
        this.isLoading = false;
      }
    },

    //add Blog
    openBlogForm() {
      this.isFormOpen = true;
      this.blogForm.title_en = "";
      this.blogForm.title_vn = "";
      this.blogForm.description_en = "";
      this.blogForm.description_vn = "";
      this.blogForm.thumbnail = "";
    },

    closeBlogForm() {
      this.isFormOpen = false;
      this.isFormUpdateOpen = false;
    },

    addBlog() {
      this.isLoading = true;
      if (
        this.blogForm.title_en == "" ||
        this.blogForm.title_vn == "" ||
        this.blogForm.description_en == "" ||
        this.blogForm.description_vn == "" ||
        this.blogForm.thumbnail == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        try {
          PostService.storeData(this.blogForm)
            .then(() => {
              this.getBlog();
              notify({ type: "success", title: "Success", text: "Add Successfully!" });
              this.isFormOpen = false;
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    //Update Blog
    openUpdateBlogForm(
      title_en,
      title_vn,
      description_en,
      description_vn,
      thumbnail,
      post_id,
      stt
    ) {
      this.blogUpdateForm.title_en = title_en;
      this.blogUpdateForm.title_vn = title_vn;
      this.blogUpdateForm.description_en = description_en;
      this.blogUpdateForm.description_vn = description_vn;
      this.blogUpdateForm.thumbnail = thumbnail;
      this.post_edit_id = post_id;
      this.blogUpdateForm.stt = stt;
      this.isFormUpdateOpen = true;
    },

    updateBlog() {
      this.isLoading = true;
      if (
        this.blogUpdateForm.title_en == "" ||
        this.blogUpdateForm.title_vn == "" ||
        this.blogUpdateForm.description_en == "" ||
        this.blogUpdateForm.description_vn == "" ||
        this.blogUpdateForm.thumbnail == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        try {
          PostService.updateData(this.post_edit_id, this.blogUpdateForm)
            .then(() => {
              this.getBlog();
              notify({ type: "success", title: "Success", text: "Update Successfully!" });
              this.isFormUpdateOpen = false;
              this.isLoading = false;
            })
            .catch((error) => {
              notify({ type: "error", title: "Error", text: error.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    //Post section
    openSectionForm() {
      this.addsectionForm = true;
      this.sectionForm.section_name_en = "";
      this.sectionForm.section_name_vn = "";
      this.sectionForm.content_en = "";
      this.sectionForm.content_vn = "";
      this.sectionForm.image_link = "";
      this.sectionForm.image_name_en = "";
      this.sectionForm.image_name_vn = "";
    },
    closeSectionForm() {
      this.addsectionForm = false;
    },
    addPostSection() {
      this.isLoading = true;
      if (
        this.sectionForm.section_name_en == "" ||
        this.sectionForm.section_name_vn == "" ||
        this.sectionForm.content_en == "" ||
        this.sectionForm.content_vn == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        try {
          PostService.addPostSection(this.post_id, this.sectionForm)
            .then(() => {
              PostService.getSection(this.post_id)
                .then((res) => {
                  this.sections = res.data.data.post_sections;
                  notify({ type: "success", title: "Success", text: "Add Successfully!" });
                  this.addsectionForm = false;
                  this.isLoading = false;
                })
                .catch((err) => {
                  notify({ type: "error", title: "Error", text: err.response.data.message });
                  this.isLoading = false;
                });
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    deletePostSection(id) {
      this.isLoading = true;
      const post_id = this.post_id;
      try {
        PostService.deletePostSection(post_id, id)
          .then(() => {
            this.viewSection(this.post_id);
            notify({ type: "success", title: "Success", text: "Delete Successfully!" });
            this.isLoading = false;
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      } catch (error) {
        notify({ type: "error", title: "Error", text: error.response.data.message });
        this.isLoading = false;
      }
    },

    //Open Edit Post Section
    openEditSectionForm(
      section_name_en,
      section_name_vn,
      content_en,
      content_vn,
      image_link,
      image_name_en,
      image_name_vn,
      id
    ) {
      this.updatesectionForm = true;
      this.sectionEditForm.section_name_en = section_name_en;
      this.sectionEditForm.section_name_vn = section_name_vn;
      this.sectionEditForm.content_en = content_en;
      this.sectionEditForm.content_vn = content_vn;
      this.sectionEditForm.image_link = image_link;
      this.sectionEditForm.image_name_en = image_name_en;
      this.sectionEditForm.image_name_vn = image_name_vn;
      this.post_section_edit = id;
    },

    closeEditSectionForm() {
      this.updatesectionForm = false;
    },
    updatePostSection() {
      this.isLoading = true;
      if (
        this.sectionEditForm.section_name_en == "" ||
        this.sectionEditForm.section_name_vn == "" ||
        this.sectionEditForm.content_en == "" ||
        this.sectionEditForm.content_vn == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        try {
          PostService.updatePostSection(this.post_id, this.post_section_edit, this.sectionEditForm)
            .then(() => {
              this.viewSection(this.post_id);
              notify({ type: "success", title: "Success", text: "Update Sucessfully!" });
              this.updatesectionForm = false;
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    async uploadImage() {
      this.isLoading = true;
      if (!this.selectedFile) {
        notify({ type: "error", title: "Error", text: "Please select a file first" });
        this.isLoading = false;
        return;
      }

      const formData = new FormData();
      formData.append("image", this.selectedFile);

      try {
        const response = await axios.post("https://api.imgur.com/3/image", formData, {
          headers: {
            Authorization: "Client-ID 8bb5dd7211002ee",
            "Content-Type": "multipart/form-data",
          },
        });
        this.blogForm.thumbnail = response.data.data.link;
        this.blogUpdateForm.thumbnail = response.data.data.link;
        this.sectionForm.image_link = response.data.data.link;
        this.sectionEditForm.image_link = response.data.data.link;
        notify({ type: "success", title: "Success", text: "Upload successful!" });
        this.isLoading = false;
      } catch (error) {
        notify({ type: "error", title: "Error", text: error.response.data.message });
        this.isLoading = false;
      }
    },

    openDuplicate(title_en, title_vn, description_en, description_vn, thumbnail, stt) {
      this.isFormOpen = true;
      this.blogForm.title_en = title_en;
      this.blogForm.title_vn = title_vn;
      this.blogForm.description_en = description_en;
      this.blogForm.description_vn = description_vn;
      this.blogForm.thumbnail = thumbnail;
      this.blogForm.stt = stt;
    },

    openDuplicateSection(
      section_name_en,
      section_name_vn,
      content_en,
      content_vn,
      image_link,
      image_name_en,
      image_name_vn
    ) {
      this.addsectionForm = true;
      this.sectionForm.section_name_en = section_name_en;
      this.sectionForm.section_name_vn = section_name_vn;
      this.sectionForm.content_en = content_en;
      this.sectionForm.content_vn = content_vn;
      this.sectionForm.image_link = image_link;
      this.sectionForm.image_name_en = image_name_en;
      this.sectionForm.image_name_vn = image_name_vn;
    },
  },
};
</script>

<style scoped>
table th,
td {
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 2;
  width: fit-content;
}

thead .sticky-col {
  z-index: 5;
}
</style>
