<script setup>
import PostService from "@/services/post.service";
import CardPost from "@/components/CardPost.vue";
// import Button from "@/components/Button.vue";
import { ref } from "vue";
// import router from "@/router";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const language = ref(localStorage.language);

let itemsPaginated = [];
try {
  const post = await PostService.getData();
  itemsPaginated = post.data.data.posts.sort((a, b) => {
    return a.stt - b.stt;
  });
} catch (err) {
  console.log(err);
}

const perPage = ref(3);

if (itemsPaginated.length == 1) {
  perPage.value = 1;
} else if (itemsPaginated.length == 2) {
  if (window.innerWidth >= 768) {
    perPage.value = 2;
  } else {
    perPage.value = 1;
  }
} else {
  if (window.innerWidth >= 768 && window.innerWidth <= 1280) {
    perPage.value = 2;
  } else if (window.innerWidth >= 1280) {
    perPage.value = 3;
  } else {
    perPage.value = 1;
  }
}

const mySwiper = ref(null);

const modules = ref([Navigation]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};
</script>

<template>
  <div class="w-full mb-8">
    <swiper
      :slides-per-view="perPage"
      :space-between="10"
      class="w-full mb-8 h-full md:grid md:items-stretch mySwiper"
      @swiper="onSwiper"
      :loop="true"
      :navigation="true"
      :modules="modules"
    >
      <swiper-slide
        v-for="(item, index) in itemsPaginated"
        :key="index"
        class="flex justify-center items-center"
      >
        <CardPost
          :post_id="item.id"
          :card_title="item[`title_${language}`]"
          :card_descr="item[`description_${language}`]"
          :imgUrl="item.thumbnail"
        >
        </CardPost>
      </swiper-slide>
    </swiper>
  </div>
</template>
