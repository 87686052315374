<!-- eslint-disable -->
<template>
  <Banner
    :imgUrl="
      activityData.activity_metadata.length > 0 ? activityData.activity_metadata[0].image_link : ''
    "
  ></Banner>
  <div
    class="block p-0 body-custom w-full px-5"
    style="max-width: 1440px"
  >
    <div class="">
      <div class="flex-c items-center gap-8 mb-8">
        <div class="blue text-lg md:text-xl text-center uppercase">{{ $t("menu.activity") }}</div>
        <div
          class="blue text-4xl md:text-5xl text-center home-page-title"
          v-html="activityData[`title_${language}`]"
        ></div>
        <!-- <div class="blue w-full text-xs lg:text-base text-center">
        <li
          class="list-none"
          v-html="activityData.location"
        ></li>
        <li
          class="list-none"
          v-html="activityData.opening_hour"
        ></li>
      </div> -->
        <div
          class="blue text-xs lg:text-base text-center w-full"
          v-html="activityData[`content_${language}`]"
        ></div>

        <swiper
          :slides-per-view="1"
          :space-between="10"
          class="w-full mb-8 mySwiperDetail"
          @swiper="onSwiper"
          :loop="true"
          :navigation="true"
          :modules="modules"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: true,
          }"
        >
          <swiper-slide
            v-for="(item, index) in activityData.activity_metadata"
            :key="index"
          >
            <img
              :src="item.image_link"
              :alt="'Image ' + (index + 1)"
            />
          </swiper-slide>
          <!-- Swiper Pagination -->
          <div
            class="swiper-pagination"
            slot="pagination"
          ></div>
        </swiper>

        <div class="blue text-lg md:text-xl text-center uppercase">{{ $t("otherActivity") }}</div>
        <ActivityAllComponent />
      </div>
      <!-- <div
      class="blue text-xs lg:text-base text-center w-full"
      v-html="activityData.content"
    ></div>
    <img
      v-for="(item, index) in activityData.activity_metadata"
      :key="index"
      class="w-full mb-8"
      :src="activityData.activity_metadata.length > 0 ? item.image_link : ''"
      height="2790"
    />
    <div class="blue text-lg md:text-xl text-center">OTHER SERVICES</div>
    <ActivityAllComponent /> -->
    </div>
  </div>
</template>

<script setup>
import Banner from "@/components/Banner.vue";
import ActivityService from "@/services/activity.service";
import ActivityAllComponent from "@/components/activity/ActivityAllComponent.vue";
import { defineProps, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";

const props = defineProps({
  activity_id: {
    type: Number,
  },
});

const language = ref(localStorage.language);

let activityData = { activity_metadata: [] };

try {
  const activity = await ActivityService.getDataById(props.activity_id);
  activityData = activity.data.data.activity;
} catch (err) {
  console.log(err);
}

const mySwiper = ref(null);

const modules = ref([Navigation, Autoplay]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};
</script>

<style>
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.mySwiperDetail .swiper-button-next,
.mySwiperDetail .swiper-button-prev {
  color: white; /* Change this to your desired color */
  padding: 20px;
}
</style>
