<template>
  <div class="about-us">
    <Suspense>
      <AboutUsDetailComponent :about_us_id="about_us_id" />
    </Suspense>
    <Menu></Menu>
    <Footer></Footer>
  </div>
</template>
<script>
import AboutUsDetailComponent from "@/components/about_us/AboutUsDetailComponent.vue";
import Footer from "@/components/Footer.vue";
import Menu from "@/components/Menu.vue";

export default {
  name: "AboutUsDetail",
  components: {
    Footer,
    Menu,
    AboutUsDetailComponent,
  },
  props: ["id"],
  computed: {
    about_us_id() {
      return this.$route.params.id;
    },
  },
};
</script>
