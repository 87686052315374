const Resource = {
  Eng: {
    homeTitle: "Pioneers in the Legacy Journey",
    homeDescr:
      "Grand Pioneers Cruise is the greenest, safest and the most modern cruise in Halong Bay. We accompany you on the exploration journey of the World Wonder’s majestic beauty.",
    voyages: {
      titleNor: "Featured voyages",
      btnAllVoyage: "Explore all voyages",
    },
    suite: {
      titleNor: "Explore your ",
      titleItalic: "luxury stays",
      btnAllSuite: "Explore all suites",
      suite: "Suites",
      detail: "Details",
      feature: "Feature",
      facility: "Facilities",
      vip: "VIP Privileges",
      bed: "Bed",
      occupancy: "Max Occupancy",
      size: "Size",
      bathroom: "Bathroom",
      uniqueFeature: "Unique Feature",
      deck: "Deck",
      from: "From",
    },
    service: {
      titleNor: "Discover",
      titleItalic: " exclusive services",
      btnAllService: "Explore all services",
    },
    activity: {
      titleNor: "Get involve in ",
      titleItalic: "cruise activity",
      btnAllActivities: "Explore all activities",
    },
    innovation: {
      titleNor: "Innovation & ",
      titleItalic: "Sustainability",
      descr:
        "Proud to be the first greenest, safest and the most modern cruise in Halong Bay, we are paving the path towards developing sustainable sea travel in Vietnam.",
    },
    blog: {
      title: "Travel Guide",
      titleNor: "News & ",
      titleItalic: "Travel blogs",
      descr:
        "A place to admire the majestic beauty of natural masterpieces, where to listen to the untold stories about the Heritage bay.",
    },
    review: {
      title: "GUEST’S REVIEW",
      subTitle: "What our guests say",
    },
    signIn: {
      title: "Sign In",
    },
    menu: {
      home: "Home",
      suites: "Suites",
      service: "Services",
      // innovation: "Our Innovation",
      voyageFinder: "Voyages",
      about: "About us",
      activity: "Activities",
      specialOffer: "Special Offer",
      booking: "Booking",
      mice: "Events",
      venue: "Venues",
    },
    voyageSearch: {
      title: "Search For Your Perfect Itineraries",
      gridOtp: "GRID",
      listOtp: "LIST",
      intinerary: "Intinerary",
      cruiseWU: "Cruising With Us",
      voyage: "Voyages",
      otherVoyage: "Other Voyages",
      intineraryDetail: "Intinerary Detail",
    },
    footer: {
      stayConnect: "Stay connected",
      followUs: "Follow us",
      contactUs: "Contact us",
      aboutUs: "About us",
      safetyPolicy: "Safety policy",
      cancelPolicy: "Cancellation policy",
      career: "Career",
      stories: "Blogs",
    },
    tab: {
      home: "Grand Pioneers Cruise (Formerly Essence Grand Cruise)",
      voyagesearch: "Grand Pioneers - Voyages",
      voyageview: "Grand Pioneers - Voyage Details",
      suitedetail: "Grand Pioneers - Suite Details",
      suiteview: "Grand Pioneers - Suites",
      serviceview: "Grand Pioneers - Services",
      servicedetail: "Grand Pioneers - Service Details",
      blogview: "Grand Pioneers - Blogs",
      blogdetail: "Grand Pioneers - Blog Details",
      careerview: "Grand Pioneers - Join us",
      aboutus: "Grand Pioneers - About Us",
      activityView: "Grand Pioneers - Activities",
      activityDetail: "Grand Pioneers - Activity Detail",
      login: "Grand Pioneers - Login",
      booking: "Grand Pioneers - Booking Now",
      cancelPolicy: "Grand Pioneers - Cancellation Policy",
      venues: "Grand Pioneers - Venues",
      venueDetail: "Grand Pioneers - Venue Detail",
      mices: "Grand Pioneers - Events",
      miceDetail: "Grand Pioneers - Event Detail",
    },
    enterYourEmail: "Enter your Email",
    otherService: "Other services",
    otherActivity: "Other activities",
    otherSuite: "Other Suites",
    numberOfDateAvailable: " DATES AVAILABLE",
    recieveEmail: "Thank you! We got your information",
    emptyEmail: "Please enter your information",
    errorEmail: "Email already in use! Please choose a different email address",
    exploreButton: "EXPLORE",
    inquireNowButton: "BOOK NOW",
    adultSearch: "Adult (11+ years)",
    childrenSearch: "Children (5 - 10 years)",
    infantSearch: "Infant (< 5 years)",
    adult: "Adults",
    children: "Childrens",
    infant: "Infants",
    selectOccupancy: "Number of pax",
    selectLocation: "Select location",
    selectDate: "Select time",
    search: "Book now",
    startDate: "Start date",
    endDate: "End date",
    selectVessel: "Select Cruise",
    selectVoyage: "Select Itinerary",
    otherMice: "Other Events",
    otherVenue: "Other Venues",
    form: {
      fullname: "Fullname",
      phone: "Phone Number",
      email: "Email",
    },
    requestInfor: "Request more information",
    subBtn: "Subcribe now",
  },
  Vi: {
    homeTitle: "Tiên phong trong hành trình Di sản",
    homeDescr:
      "Grand Pioneers tự hào là du thuyền xanh, an toàn và hiện đại nhất vịnh Hạ Long; là người đồng hành cùng bạn trên chuyến hành trình khám phá vẻ đẹp hùng vĩ của Kỳ quan thiên nhiên thế giới.",
    voyages: {
      titleNor: "Hải trình độc bản",
      btnAllVoyage: "Khám phá các hải trình",
    },
    suite: {
      titleNor: "Khám phá ",
      titleItalic: "không gian nghỉ dưỡng đẳng cấp",
      btnAllSuite: "Khám phá các hạng phòng",
      suite: "Hạng phòng",
      detail: "Chi tiết",
      feature: "Đặc điểm",
      facility: "Tiện tích",
      vip: "Đặc quyền hạng phòng VIP",
      bed: "Giường",
      occupancy: "Sức chứa",
      size: "Diện tích",
      bathroom: "Phòng tắm",
      uniqueFeature: "Đặc điểm đặc biệt",
      deck: "Tầng",
      from: "Từ",
    },
    service: {
      titleNor: "Trải nghiệm",
      titleItalic: " dịch vụ riêng biệt",
      btnAllService: "Khám phá các dịch vụ",
    },
    activity: {
      titleNor: "Trải nghiệm ",
      titleItalic: "hoạt động thú vị",
      btnAllActivities: "Khám phá các hoạt động",
    },
    innovation: {
      titleNor: "Cải tiến & ",
      titleItalic: "bền vững",
      descr:
        "Tự hào là du thuyền xanh, an toàn và hiện đại nhất trên vịnh Hạ Long, chúng tôi mang trong mình sứ mệnh tiên phong trong phát triển du lịch biển bền vững tại Việt Nam.",
    },
    blog: {
      title: "Tin tức",
      titleNor: "Tin tức & ",
      titleItalic: " Cẩm nang du lịch",
      descr:
        "Nơi tận hưởng vẻ đẹp hùng vĩ của thiên nhiên, nơi lắng nghe những câu chuyện chưa kể về Kỳ quan thiên nhiên thế giới vịnh Hạ Long.",
    },
    review: {
      title: "Nhận xét của khách hàng",
      subTitle: "Khách hàng nói gì về chúng tôi?",
    },
    signIn: {
      title: "Đăng nhập",
    },
    menu: {
      home: "Trang chủ",
      suites: "Phòng",
      service: "Dịch vụ",
      voyageFinder: "Hải trình",
      about: "Về chúng tôi",
      activity: "Hoạt động",
      specialOffer: "Ưu Đãi Đặc biệt",
      booking: "Đặt phòng",
      mice: "Sự kiện",
      venue: "Địa điểm",
    },
    voyageSearch: {
      title: "Tìm kiếm hải trình phù hợp với bạn",
      gridOtp: "Dạng lưới",
      listOtp: "Danh sách",
      intinerary: "Hành Trình",
      cruiseWU: "Trải nghiệm cùng Grand Pioneers",
      voyage: "Hải Trình",
      otherVoyage: "Hải trình khác",
      intineraryDetail: "Hành Trình Cụ Thể",
    },
    tab: {
      home: "Trang chủ - Tiên phong trong hành trình Di sản",
      voyagesearch: "Grand Pioneers - Hải trình",
      voyageview: "Grand Pioneers - Xem Hải trình",
      suitedetail: "Grand Pioneers - Chi tiết phòng",
      suiteview: "Grand Pioneers - Tất cả phòng",
      serviceview: "Grand Pioneers - Xem Dịch vụ",
      servicedetail: "Grand Pioneers - Chi tiết Dịch vụ",
      blogview: "Grand Pioneers - Bài Viết",
      blogdetail: "Grand Pioneers - Bài Viết",
      careerview: "Grand Pioneers - Tuyển dụng",
      aboutus: "Grand Pioneers - Về chúng tôi",
      activityView: "Grand Pioneers - Tất cả hoạt động",
      activityDetail: "Grand Pioneers - Chi tiết hoạt động",
      login: "Grand Pioneers - Đăng nhập",
      booking: "Grand Pioneers - Đặt phòng ngay",
      cancelPolicy: "Grand Pioneers - Chính sách huỷ phòng",
      venues: "Grand Pioneers - Địa Điểm",
      venueDetail: "Grand Pioneers - Địa Điểm Cụ Thể",
      mices: "Grand Pioneers - Sự Kiện",
      miceDetail: "Grand Pioneers - Chi tiết",
    },
    footer: {
      stayConnect: "Đăng ký ngay",
      followUs: "Theo dõi chúng tôi",
      contactUs: "Liên hệ với chúng tôi",
      aboutUs: "Về chúng tôi",
      safetyPolicy: "Chính sách an toàn",
      cancelPolicy: "Chính sách hoàn huỷ",
      career: "Cơ hội nghề nghiệp",
      stories: "Bài viết",
    },
    enterYourEmail: "Nhập vào Email của bạn",
    otherService: "Dịch vụ khác",
    otherActivity: "Hoạt động khác",
    otherSuite: "Hạng phòng khác",
    numberOfDateAvailable: " NGÀY CÓ SẴN",
    recieveEmail: "Cảm ơn! Chúng tôi đã nhận được thông tin của bạn",
    emptyEmail: "Hãy nhập vào thông tin của bạn",
    errorEmail: "Email đã tồn tại! Vui lòng sử dụng email khác",
    exploreButton: "KHÁM PHÁ",
    inquireNowButton: "ĐẶT PHÒNG NGAY",
    adultSearch: "Người lớn (11+ Tuổi)",
    childrenSearch: "Trẻ em (5 - 10 Tuổi)",
    infantSearch: "Trẻ sơ sinh (< 5 Tuổi)",
    adult: "Người lớn",
    children: "Trẻ em",
    infant: "Trẻ sơ sinh",
    selectOccupancy: "Số lượng khách",
    selectLocation: "Điểm đến",
    selectDate: "Thời gian",
    search: "Đặt phòng ngay",
    startDate: "Ngày bắt đầu",
    endDate: "Ngày kết thúc",
    selectVessel: "Chọn du thuyền",
    selectVoyage: "Chọn hải trình",
    otherMice: "Sự kiện khác",
    otherVenue: "Địa điểm khác",
    form: {
      fullname: "Họ và tên",
      phone: "Số điện thoại",
      email: "Email",
    },
    requestInfor: "Tìm hiểu thêm thông tin",
    subBtn: "Đăng ký ngay",
  },
};
export default Resource;
