<template>
  <Suspense>
    <BookingHeading />
  </Suspense>
  <div
    class="block p-0 body-custom w-full px-5"
    style="max-width: 1440px"
  >
    <div class="hbe-bws">
      <section id="hbe-bws-page">
        <div id="hbe-bws-wrapper"></div>
      </section>
    </div>
  </div>
  <Menu></Menu>
  <Footer></Footer>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
import BookingHeading from "@/components/heading/BookingHeading.vue";

export default {
  data() {
    return {
      language: localStorage.language == "en" ? "en" : "vi",
    };
  },
  mounted() {
    this.loadExternalResources();
  },
  methods: {
    loadExternalResources() {
      // Load CSS files
      const cssUrls = ["//book.securebookings.net/css/app.css"];
      cssUrls.forEach((href) => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = href;
        document.head.appendChild(link);
      });

      // Load JS files
      const scriptUrls = [
        "//book.securebookings.net/js/widget.all.js",
        `//book.securebookings.net/widgetCustomize?lang=${this.language}&widgetType=Widget&id=0aa011f4-3580-1720519469-4d78-8a8d-8d0b4b99404a&ajax=true`,
      ];
      scriptUrls.forEach((src) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        document.body.appendChild(script);
      });
    },
  },
  components: {
    Menu,
    Footer,
    BookingHeading,
  },
};
</script>

<style scoped>
/* Add any additional styles here if needed */
</style>
