<template>
  <div class="service-detail">
    <Suspense>
      <MiceDetailComponent :mice_id="mice_id" />
    </Suspense>
  </div>
  <Menu></Menu>
  <Footer></Footer>
</template>
<script>
import Footer from "@/components/Footer.vue";
import Menu from "@/components/Menu.vue";
import MiceDetailComponent from "@/components/mice/MiceDetailComponent.vue";

export default {
  name: "MiceDetail",
  components: {
    Footer,
    Menu,
    MiceDetailComponent,
  },
  props: ["id"],
  computed: {
    mice_id() {
      return this.$route.params.id;
    },
  },
};
</script>
