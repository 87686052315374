<script setup>
/* eslint-disable */
import VenueService from "@/services/venue.service";
import CardVenue from "@/components/venue/CardVenue.vue";
import { reactive, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const language = ref(localStorage.language);

let itemsPaginated = reactive({ data: [] });

try {
  const venue = await VenueService.getData();

  itemsPaginated.data = venue.data.data.venues.sort((a, b) => {
    return a.stt - b.stt;
  });
} catch (err) {
  console.log(err);
}

const mySwiper = ref(null);

const modules = ref([Navigation]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const perPage = ref(3);

if (itemsPaginated.data.length == 1) {
  perPage.value = 1;
} else if (itemsPaginated.data.length == 2) {
  if (window.innerWidth >= 768) {
    perPage.value = 2;
  } else {
    perPage.value = 1;
  }
} else {
  if (window.innerWidth >= 768 && window.innerWidth <= 1280) {
    perPage.value = 2;
  } else if (window.innerWidth >= 1280) {
    perPage.value = 3;
  } else {
    perPage.value = 1;
  }
}
</script>

<template>
  <div
    class="flex flex-col mt-20 mb-10"
    v-show="itemsPaginated.data.length > 0"
  >
    <div class="blue py-7 text-center text-lg md:text-xl md:text-start uppercase home-page-title">
      <p>
        {{ $t("menu.venue") }}
      </p>
    </div>
    <swiper
      :slides-per-view="perPage"
      :space-between="10"
      class="w-full mb-8 h-full md:grid md:items-stretch mySwiper"
      @swiper="onSwiper"
      :loop="true"
      :navigation="true"
      :modules="modules"
    >
      <swiper-slide
        v-for="(item, index) in itemsPaginated.data"
        :key="index"
        class="flex justify-center items-center"
      >
        <CardVenue
          :imgUrl="item.venue_metadata.length > 0 ? item.venue_metadata[0].image_link : ''"
          :card_title="item[`title_${language}`]"
          :card_descr="item[`content_${language}`]"
          :activity_id="item.id"
          class="text-start w-full h-full"
        />
      </swiper-slide>
      <div
        class="swiper-pagination"
        v-bind="pagination"
      ></div>
    </swiper>
  </div>
</template>
