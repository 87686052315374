<template>
  <link
    rel="preload"
    :href="menuData[0].thumbnail"
    as="image"
  />
  <div
    class="h-screen w-full bg-cover"
    :style="{ backgroundImage: `url(${menuData[0].thumbnail})` }"
  >
    <Blur></Blur>
    <div
      class="page-contain flex flex-col items-center"
      style="gap: 16%"
    >
      <PageLogo :logo-type="3"></PageLogo>
      <div class="text-4xl md:text-5xl uppercase overflow-hidden flex-c gap-10 items-center">
        <div
          class="flex-row justify-center items-center text-white text-wrap text-center w-3/4 py-3 uppercase home-page-title"
          v-html="menuData[0][`title_${language}`]"
        ></div>
        <div
          class="text-white w-3/4 text-center text-wrap text-xs md:text-base mt-5 mb-4 overflow-hidden"
          v-html="menuData[0][`description_${language}`]"
        ></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import MenuService from "@/services/menu.service";
import Blur from "@/components/Blur.vue";
import PageLogo from "@/components/PageLogo.vue";
import { ref } from "vue";

const language = ref(localStorage.language);

let menuData = {};
try {
  const menu = await MenuService.getDataByName("menu");
  menuData = menu.data.data.menu;
} catch (err) {
  console.log(err);
}
</script>
<style></style>
