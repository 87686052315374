import axios from "axios";
import { authHeader } from "@/services/base.service";
import { BASE_API_URL } from "@/helpers/base.api.url";

class MiceMetadataService {
  async getData(mice_id) {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/mices/" + mice_id + "/mice-metadata",
    });
  }

  async getDataById(mice_id, mice_metadata_id) {
    return await axios({
      method: "get",
      url: BASE_API_URL + "/mices/" + mice_id + "/mice-metadata/" + mice_metadata_id,
    });
  }

  async storeData(mice_id, image_link) {
    return await axios.post(
      BASE_API_URL + "/mices/" + mice_id + "/mice-metadata",
      { image_link: image_link },
      {
        headers: authHeader(),
      }
    );
  }

  async updateData(mice_metadata_id, mice_id, mice) {
    return await axios({
      method: "put",
      url: BASE_API_URL + "/mices/" + mice_id + "/mice-metadata/" + mice_metadata_id,
      mice,
      headers: authHeader(),
    });
  }

  async deleteData(mice_metadata_id, mice_id) {
    return await axios({
      method: "delete",
      url: BASE_API_URL + "/mices/" + mice_id + "/mice-metadata/" + mice_metadata_id,
      headers: authHeader(),
    });
  }
}

export default new MiceMetadataService();
