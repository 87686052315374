<template lang="">
  <div class="xl:h-fit xl:w-full my-20">
    <div class="blue text-center text-lg md:text-xl md:text-start mb-10 uppercase home-page-title">
      <p>{{ $t("requestInfor") }}</p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
      <div class="mb-4 px-2 w-full">
        <label class="combobox-label blue"> {{ $t("form.fullname") }} </label>
        <div class="combo-box flex-c border-bottom-blue">
          <div class="combobox-input-container overflow-hidden">
            <input
              style="background-color: transparent"
              type="text"
              class="combobox-input blue"
              v-model="form.fullname"
            />
          </div>
        </div>
      </div>

      <div class="mb-4 px-2 w-full">
        <label class="combobox-label blue"> {{ $t("form.phone") }} </label>
        <div class="combo-box flex-c border-bottom-blue">
          <div class="combobox-input-container overflow-hidden">
            <input
              style="background-color: transparent"
              type="text"
              class="combobox-input blue"
              v-model="form.phone"
            />
          </div>
        </div>
      </div>

      <div class="mb-4 px-2 w-full">
        <label class="combobox-label blue"> {{ $t("form.email") }} </label>
        <div class="combo-box flex-c border-bottom-blue">
          <div class="combobox-input-container overflow-hidden">
            <input
              style="background-color: transparent"
              type="text"
              class="combobox-input blue"
              v-model="form.email"
            />
          </div>
        </div>
      </div>
    </div>
    <Button
      class="mt-3"
      button_v="1"
      :text="$t('subBtn')"
      @click="sendEmail"
    />
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
import EmailCustomerService from "@/services/email.service";

export default {
  components: {
    Button,
  },
  data() {
    return {
      form: {
        fullname: "",
        phone: "",
        email: "",
      },
    };
  },
  methods: {
    sendEmail() {
      if (this.form.email == "") {
        this.$swal.fire({
          text: this.$t("emptyEmail"),
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        EmailCustomerService.storeData(this.form)
          .then(() => {
            this.$swal.fire({
              text: this.$t("recieveEmail"),
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.email = "";
          })
          .catch(() => {
            this.$swal.fire({
              text: this.$t("errorEmail"),
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },
};
</script>
<style lang=""></style>
