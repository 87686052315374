<template>
  <div class="about-us">
    <Suspense>
      <CancelPolicyDetailComponent :cancel_policy_id="cancel_policy_id" />
    </Suspense>
    <Menu></Menu>
    <Footer></Footer>
  </div>
</template>
<script>
import CancelPolicyDetailComponent from "@/components/cancel_policy/CancelPolicyDetailComponent.vue";
import Footer from "@/components/Footer.vue";
import Menu from "@/components/Menu.vue";

export default {
  name: "CancelPolicy",
  components: {
    Footer,
    Menu,
    CancelPolicyDetailComponent,
  },
  props: ["id"],
  computed: {
    cancel_policy_id() {
      return this.$route.params.id;
    },
  },
};
</script>
