<template>
  <div class="container mx-auto px-4 py-8 text-xs">
    <div class="container mx-auto px-4 py-8 text-xs">
      <div class="flex-c gap-5 mb-5">
        <div class="flex-r items-center mb-4 justify-between">
          <h1 class="text-2xl font-bold">Voyages List</h1>
          <div
            class="flex-r font-semibold gap-2 items-center cursor-pointer"
            @click="openForm"
          >
            <div class="addBtn bg-contain"></div>
            Add new
          </div>
        </div>
      </div>
      <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
        <table
          class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
        >
          <thead>
            <tr class="text-left">
              <th class="py-2 px-3 sticky top-0 bg-gray-200">No.</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Vie</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Vie</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Location Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Location Vie</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Destination Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Destination Vie</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Time Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Time Vie</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Fares Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Fares Vie</th>
              <!-- <th class="py-2 px-3 sticky top-0 bg-gray-200">Dates available</th> -->
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Created At</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Updated At</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="voyage in voyages"
              :key="voyage.id"
            >
              <td class="border px-4 py-2">{{ voyage.stt }}</td>
              <td class="border px-4 py-2">{{ voyage.id }}</td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="voyage.title_en"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="voyage.title_vn"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="voyage.content_en"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="voyage.content_vn"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="voyage.summary_location_en"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="voyage.summary_location_vn"
              ></td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                {{ voyage.destination_en }}
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                {{ voyage.destination_vn }}
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                {{ voyage.time_en }}
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                {{ voyage.time_vn }}
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                {{ voyage.fares_en }}
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                {{ voyage.fares_vn }}
              </td>
              <!-- <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                <li
                  class="list-none"
                  v-for="index in voyage.voyage_date_available"
                  :key="index"
                >
                  <div class="">
                    Start Date: {{ index.start_date }} / End Date: {{ index.end_date }}
                  </div>
                </li>
              </td> -->
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                {{ formatDateTime(voyage.created_at) }}
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                {{ formatDateTime(voyage.updated_at) }}
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
                <div class="flex flex-r gap-3">
                  <div class="w-full h-full grid grid-cols-2 gap-4">
                    <button
                      @click="
                        openEditVoyageForm(
                          voyage.title_en,
                          voyage.title_vn,
                          voyage.content_en,
                          voyage.content_vn,
                          voyage.destination_en,
                          voyage.destination_vn,
                          voyage.fares_en,
                          voyage.fares_vn,
                          voyage.summary_location_en,
                          voyage.summary_location_vn,
                          voyage.id,
                          voyage.stt,
                          voyage.time_en,
                          voyage.time_vn
                        )
                      "
                      class="bg-blue-500 text-white px-2 py-1 rounded"
                    >
                      Edit
                    </button>
                    <button
                      @click="deleteVoyage(voyage.id)"
                      class="bg-red-500 text-white px-2 py-1 rounded"
                    >
                      Delete
                    </button>
                    <button
                      class="bg-blue-500 text-white px-2 py-1 rounded"
                      @click="openFormUploadImage(voyage.id)"
                    >
                      Add photo
                    </button>
                    <button
                      class="bg-blue-500 text-white px-2 py-1 rounded"
                      @click="openTimeline(voyage.id)"
                    >
                      Add Timelines
                    </button>
                    <!-- <button
                      @click="showDateAvai(voyage.voyage_date_available, voyage.id)"
                      class="bg-blue-500 text-white px-2 py-1 rounded"
                    >
                      Add Date Available
                    </button> -->
                    <button
                      class="bg-blue-500 text-white px-2 py-1 rounded"
                      @click="
                        openDuplicate(
                          voyage.title_en,
                          voyage.title_vn,
                          voyage.content_en,
                          voyage.content_vn,
                          voyage.destination_en,
                          voyage.destination_vn,
                          voyage.fares_en,
                          voyage.fares_vn,
                          voyage.summary_location_en,
                          voyage.summary_location_vn,
                          voyage.stt,
                          voyage.time_en,
                          voyage.time_vn
                        )
                      "
                    >
                      Duplicate
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Add Form -->
    <div
      class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
      v-if="isFormShown"
    >
      <div class="add-form bg-white rounded p-4 overflow-auto">
        <h2 class="text-xl font-bold pb-2">Add Voyages</h2>
        <div class="grid grid-cols-2 gap-8">
          <div class="mb-2 w-full">
            <label class="block w-full">Title Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.title_en"
              :config="editorConfig"
            ></ckeditor>
          </div>
          <div class="mb-2 w-full">
            <label class="block w-full">Title Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.title_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.content_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.content_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Destination Eng</label>
            <input
              type="text"
              v-model="form.destination_en"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Destination Vie</label>
            <input
              type="text"
              v-model="form.destination_vn"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Detail Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.fares_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Detail Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.fares_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Summary Location Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.summary_location_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Summary Location Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.summary_location_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Time Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.time_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Time Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.time_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Order </label>
            <vue-number-input
              v-model="form.stt"
              :min="1"
              inline
              controls
            ></vue-number-input>
          </div>
        </div>

        <div class="flex-r gap-3">
          <button
            @click="addVoyages"
            class="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Add Voyages
          </button>
          <button
            @click="closeForm"
            class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>

    <!-- Voyage update form -->
    <div
      class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
      v-if="isUpdateForm"
    >
      <div class="add-form bg-white rounded p-4 overflow-auto">
        <h2 class="text-xl font-bold pb-2">Update Voyage</h2>
        <div class="grid grid-cols-2 gap-8">
          <div class="mb-2 w-full">
            <label class="block w-full">Title Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form_update.title_en"
              :config="editorConfig"
            ></ckeditor>
          </div>
          <div class="mb-2 w-full">
            <label class="block w-full">Title Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form_update.title_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form_update.content_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form_update.content_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Destination Eng</label>
            <input
              type="text"
              v-model="form_update.destination_en"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Destination Vie</label>
            <input
              type="text"
              v-model="form_update.destination_vn"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Detail Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form_update.fares_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Detail Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form_update.fares_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Summary Location Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form_update.summary_location_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Summary Location Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form_update.summary_location_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Time Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form_update.time_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Time Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form_update.time_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Order </label>
            <vue-number-input
              v-model="form_update.stt"
              :min="1"
              inline
              controls
            ></vue-number-input>
          </div>
        </div>

        <div class="flex-r gap-3">
          <button
            @click="updateVoyage"
            class="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Update Voyages
          </button>
          <button
            @click="closeEditVoyageForm"
            class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>

    <div
      class="fixed top-0 right-0 left-0 bottom-0 z-10"
      style="background-color: rgba(0, 0, 0, 56%)"
      v-if="isDateAvai"
    >
      <div
        class="bg-white absolute right-1/2 translate-x-1/2 top-1/2 -translate-y-1/2 p-4"
        style="width: 500px; height: 800px"
      >
        <div class="flex-c gap-3">
          <div
            class="flex-r justify-between"
            v-for="index in this.date_available"
            :key="index"
          >
            <div class="flex-c">
              <div class="flex-c">Start date: {{ index.start_date }}</div>
              <div class="flex-c">End date: {{ index.end_date }}</div>
            </div>
            <button
              @click="deleteVoyageDate(index.id)"
              class="bg-red-500 text-white px-2 py-1 rounded"
            >
              Delete
            </button>
          </div>
          <div class="flex-c">
            <div class="font-medim">Start date</div>
            <input
              type="datetime-local"
              v-model="date_detail.start_date"
            />
          </div>
          <div class="flex-c">
            <div class="font-medim">End date</div>
            <input
              type="datetime-local"
              v-model="date_detail.end_date"
            />
          </div>
          <div class="flex flex-row mt-4">
            <button
              type="submit"
              class="bg-blue-500 text-white px-4 py-2 rounded"
              @click="addDateDetail"
            >
              Add date
            </button>
            <button
              type="button"
              @click="closeDateAvail"
              class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Upload image -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isFormUploadImageOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Upload Image</h2>
      <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
        <table
          class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
        >
          <thead>
            <tr class="text-left">
              <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Image Link</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data in metadata"
              :key="data.id"
            >
              <td class="border px-4 py-2">{{ data.id }}</td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                <img
                  :src="data.image_link"
                  class="w-48"
                />
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
                <button
                  @click="deleteMetadata(data.id)"
                  class="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-col mb-5">
        <label>Image Link</label>
        <input
          type="text"
          class="border border-black p-1"
          v-model="imageLink"
        />
      </div>
      <input
        type="file"
        @change="onFileChange"
        multiple
      />
      <div class="flex flex-row mt-4">
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded"
          @click="uploadImageLink"
        >
          Upload With Image Link
        </button>
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded ml-2"
          @click="uploadImage"
        >
          Upload
        </button>
        <button
          type="button"
          @click="closeFormUploadImage"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Timeline -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isTimelineOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <div class="flex flex-row items-center justify-between">
        <h2 class="text-xl font-bold pb-2">Add Timeline</h2>
        <div
          class="flex-r font-semibold gap-2 items-center cursor-pointer"
          @click="openFormAddTimeline"
        >
          <div class="addBtn bg-contain"></div>
          Add new
        </div>
      </div>

      <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
        <table
          class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
        >
          <thead>
            <tr class="text-left">
              <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Day Time Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Day Time Vie</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Detail Eng</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Detail Vie</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data in timelines"
              :key="data.id"
            >
              <td class="border px-4 py-2">{{ data.id }}</td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="data.daytime_en"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="data.daytime_vn"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="data.detail_en"
              ></td>
              <td
                class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
                v-html="data.detail_vn"
              ></td>
              <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
                <div class="flex flex-row gap-3">
                  <button
                    class="bg-red-500 text-white px-4 py-2 rounded"
                    @click="deleteTimeline(data.id)"
                  >
                    Delete
                  </button>
                  <button
                    class="bg-blue-500 text-white px-4 py-2 rounded"
                    @click="
                      openUpdateTimeline(
                        data.id,
                        data.daytime_en,
                        data.daytime_vn,
                        data.detail_en,
                        data.detail_vn
                      )
                    "
                  >
                    Edit Timeline
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button
        class="bg-gray-500 text-white px-4 py-2 rounded"
        @click="closeForm"
      >
        Close
      </button>
    </div>
  </div>

  <!-- Add Timeline -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isAddTimelineOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <div class="text-xl font-bold pb-2">Add Timeline</div>
      <div class="grid grid-cols-2 gap-8">
        <div class="mb-2 w-full">
          <div class="block w-full">Daytime Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formTimeline.daytime_en"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="mb-2 w-full">
          <div class="block w-full">Daytime Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formTimeline.daytime_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="mb-2 w-full">
          <div class="block w-full">Detail Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formTimeline.detail_en"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="mb-2 w-full">
          <div class="block w-full">Detail Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formTimeline.detail_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>
      </div>
      <div class="flex-r gap-3">
        <button
          @click="addTimeline()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Timeline
        </button>
        <button
          @click="isAddTimelineOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Update Timeline -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isUpdateTimelineOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <div class="text-xl font-bold pb-2">Timeline</div>
      <div class="grid grid-cols-2 gap-8">
        <div class="mb-2 w-full">
          <div class="block w-full">Daytime Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdateTimeline.daytime_en"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="mb-2 w-full">
          <div class="block w-full">Daytime Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdateTimeline.daytime_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="mb-2 w-full">
          <div class="block w-full">Detail Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdateTimeline.detail_en"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="mb-2 w-full">
          <div class="block w-full">Detail Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdateTimeline.detail_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>
      </div>
      <div class="flex-r gap-3">
        <button
          @click="UpdateTimeline()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Update Timeline
        </button>
        <button
          @click="isUpdateTimelineOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
  <LoadingPage :isLoading="isLoading" />
</template>
<script>
import Admin from "@/services/admin.service";
import VoyageService from "@/services/voyage.service";
import axios from "axios";
import VoyageMetadataService from "@/services/voyage.metadata.service";
import TimelineService from "@/services/timeline.service";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
import LoadingPage from "@/components/LoadingPage.vue";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
} from "ckeditor5";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "VoyagesPortal",
  components: {
    ckeditor: CKEditor.component,
    LoadingPage,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertTable",
          "mediaEmbed",
          "|",
          "bulletedList",
          "numberedList",
          "indent",
          "outdent",
        ],
        plugins: [
          Bold,
          Essentials,
          Heading,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          Undo,
        ],
      },
      vessels: [],
      voyages: [],
      metadata: [],
      voyage_id: "",
      form: {
        title_en: "",
        title_vn: "",
        content_en: "",
        content_vn: "",
        destination_en: "",
        destination_vn: "",
        fares_en: "",
        fares_vn: "",
        summary_location_en: "",
        summary_location_vn: "",
        stt: "",
        time_en: "",
        time_vn: "",
      },
      form_update: {
        title_en: "",
        title_vn: "",
        content_en: "",
        content_vn: "",
        destination_en: "",
        destination_vn: "",
        fares_en: "",
        fares_vn: "",
        summary_location_en: "",
        summary_location_vn: "",
        stt: "",
        time_en: "",
        time_vn: "",
      },
      isFormShown: false,
      isUpdateForm: false,
      isFormUploadImageOpen: false,
      voyage_update_id: "",
      isDateAvai: false,
      date_available: [],
      date_detail: {
        start_date: "",
        end_date: "",
      },

      //timeline
      timelines: [],
      timeline_metadata: [],
      isTimelineOpen: false,
      isAddTimelineOpen: false,
      formTimeline: {
        daytime_en: "",
        daytime_vn: "",
        detail_en: "",
        detail_vn: "",
      },
      isUpdateTimelineOpen: false,
      formUpdateTimeline: {
        daytime_en: "",
        daytime_vn: "",
        detail_en: "",
        detail_vn: "",
      },
      isFormUploadImageTimelineOpen: false,
      timeline_id: "",
      isLoading: false,
      languages: [{ lang: "en" }, { lang: "vn" }],
      imageLink: "",
    };
  },
  mounted() {
    this.getVesselData();
  },
  methods: {
    formatDateTime(datetime) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(datetime).toLocaleDateString("en-US", options);
    },
    async getVesselData() {
      this.isLoading = true;
      Admin.getVoyageByVessel()
        .then((res) => {
          this.voyages = res.data.data.voyages;
          this.isLoading = false;
        })
        .catch((error) => {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        });
      this.isLoading = false;
    },

    deleteVoyage(id) {
      this.isLoading = true;
      try {
        VoyageService.deleteData(id)
          .then(() => {
            VoyageService.getData()
              .then((res) => {
                this.voyages = res.data.data.voyages;
                notify({ type: "success", title: "Success", text: "Delete Successfully!" });
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      } catch (error) {
        notify({ type: "error", title: "Error", text: error.response.data.message });
        this.isLoading = false;
      }
    },

    //Add voyages
    addVoyages() {
      this.isLoading = true;
      if (
        this.form.title_en == "" ||
        this.form.title_vn == "" ||
        this.form.content_en == "" ||
        this.form.content_vn == "" ||
        this.form.destination_en == "" ||
        this.form.destination_vn == "" ||
        this.form.fares_en == "" ||
        this.form.fares_vn == "" ||
        this.form.summary_location_en == "" ||
        this.form.summary_location_vn == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        VoyageService.storeData(this.form)
          .then(() => {
            VoyageService.getData()
              .then((res) => {
                this.voyages = res.data.data.voyages;
                this.isFormShown = false;
                notify({
                  type: "success",
                  title: "Success",
                  text: "Add Successfully!",
                });
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    openForm() {
      this.isFormShown = true;
      this.form.title_en = "";
      this.form.title_vn = "";
      this.form.content_en = "";
      this.form.content_vn = "";
      this.form.fares_en = "";
      this.form.fares_vn = "";
      this.form.summary_location_en = "";
      this.form.summary_location_vn = "";
      this.form.destination_en = "";
      this.form.destination_vn = "";
      this.form.time_en = "";
      this.form.time_vn = "";
    },

    closeForm() {
      this.isFormShown = false;
      this.isLocationOpen = false;
      this.isExperienceOpen = false;
      this.isTimelineOpen = false;
    },

    handleSelectLanguage(event) {
      const language = event.target.value;
      this.form.language = language;
      this.form_update.language = language;
      this.formTimeline.language = language;
      this.formUpdateTimeline.language = language;
    },

    //Edit Voyage
    openEditVoyageForm(
      title_en,
      title_vn,
      content_en,
      content_vn,
      destination_en,
      destination_vn,
      fares_en,
      fares_vn,
      summary_location_en,
      summary_location_vn,
      id,
      stt,
      time_en,
      time_vn
    ) {
      this.isUpdateForm = true;
      this.form_update.title_en = title_en;
      this.form_update.title_vn = title_vn;
      this.form_update.content_en = content_en;
      this.form_update.content_vn = content_vn;
      this.form_update.destination_en = destination_en;
      this.form_update.destination_vn = destination_vn;
      this.form_update.fares_en = fares_en;
      this.form_update.fares_vn = fares_vn;
      this.form_update.summary_location_en = summary_location_en;
      this.form_update.summary_location_vn = summary_location_vn;
      this.voyage_update_id = id;
      this.form_update.stt = stt;
      this.form_update.time_en = time_en;
      this.form_update.time_vn = time_vn;
    },
    closeEditVoyageForm() {
      this.isUpdateForm = false;
    },
    updateVoyage() {
      this.isLoading = true;
      if (
        this.form_update.title_en == "" ||
        this.form_update.title_vn == "" ||
        this.form_update.content_en == "" ||
        this.form_update.content_vn == "" ||
        this.form_update.destination_en == "" ||
        this.form_update.destination_vn == "" ||
        this.form_update.fares_en == "" ||
        this.form_update.fares_vn == "" ||
        this.form_update.summary_location_en == "" ||
        this.form_update.summary_location_vn == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        try {
          Admin.updateVoyage(this.voyage_update_id, this.form_update).then(() => {
            Admin.getVoyageByVessel()
              .then((res) => {
                this.voyages = res.data.data.voyages;
                notify({ type: "success", title: "Success", text: "Update Successfully!" });
                this.isUpdateForm = false;
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    //Date Available
    showDateAvai(date_avail, voyage_id) {
      this.isLoading = true;
      this.voyage_id = voyage_id;
      this.date_available = date_avail;
      this.isDateAvai = true;
      this.isLoading = false;
      this.date_detail.start_date = "";
      this.date_detail.end_date = "";
    },
    closeDateAvail() {
      this.isDateAvai = false;
    },
    deleteVoyageDate(id) {
      this.isLoading = true;
      try {
        Admin.deleteDateAvail(this.voyage_id, id)
          .then(() => {
            Admin.getDateAvailable(this.voyage_id)
              .then((res) => {
                this.date_available = res.data.data.voyage_date_availables;
                notify({ type: "success", title: "Success", text: "Delete Successfully!" });
                this.isLoading = false;
              })
              .catch((error) => {
                notify({ type: "error", title: "Error", text: error.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((error) => {
            notify({ type: "error", title: "Error", text: error.response.data.message });
            this.isLoading = false;
          });
      } catch (error) {
        notify({ type: "error", title: "Error", text: error.response.data.message });
        this.isLoading = false;
      }
    },

    addDateDetail() {
      this.isLoading = true;
      if (this.date_detail.start_date == "" || this.date_detail.end_date == "") {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        try {
          Admin.addDateAvail(this.voyage_id, this.date_detail)
            .then(() => {
              Admin.getDateAvailable(this.voyage_id)
                .then((res) => {
                  this.date_available = res.data.data.voyage_date_availables;
                  notify({
                    type: "success",
                    title: "Success",
                    text: "Add date successfully!",
                  });
                  this.isLoading = false;
                })
                .catch((error) => {
                  notify({ type: "error", title: "Error", text: error.response.data.message });
                  this.isLoading = false;
                });
            })
            .catch((error) => {
              notify({ type: "error", title: "Error", text: error.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    //Image
    onFileChange(event) {
      this.selectedFile = event.target.files;
    },

    closeFormUploadImage() {
      this.isFormUploadImageOpen = false;
    },
    openFormUploadImage(id) {
      this.isLoading = true;
      this.voyage_id = id;
      this.isFormUploadImageOpen = true;
      VoyageMetadataService.getData(id)
        .then((res) => {
          this.metadata = res.data.data.voyage_metadata;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    async uploadImage() {
      if (!this.selectedFile) {
        notify({ type: "error", title: "Error", text: "Please select a file first" });
        this.isLoading = false;
        return;
      }
      for (let i = 0; i < this.selectedFile.length; ++i) {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("image", this.selectedFile[i]);

        try {
          const response = await axios.post("https://api.imgur.com/3/image", formData, {
            headers: {
              Authorization: "Client-ID 8bb5dd7211002ee",
              "Content-Type": "multipart/form-data",
            },
          });

          await VoyageMetadataService.storeData(this.voyage_id, response.data.data.link)
            .then(async () => {
              await VoyageMetadataService.getData(this.voyage_id)
                .then((res) => {
                  this.metadata = res.data.data.voyage_metadata;
                  notify({ type: "success", title: "Success", text: "Upload successful!" });
                  this.isLoading = false;
                })
                .catch((err) => {
                  notify({ type: "error", title: "Error", text: err.response.data.message });
                  this.isLoading = false;
                });
            })
            .catch((error) => {
              notify({ type: "error", title: "Error", text: error.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    uploadImageLink() {
      this.isLoading = true;
      if (this.imageLink == "") {
        notify({ type: "error", title: "Error", text: "Please fill image link!" });
        this.isLoading = false;
      } else {
        VoyageMetadataService.storeData(this.voyage_id, this.imageLink)
          .then(() => {
            VoyageMetadataService.getData(this.voyage_id)
              .then((res) => {
                this.metadata = res.data.data.voyage_metadata;
                notify({ type: "success", title: "Success", text: "Upload successful!" });
                this.isLoading = false;
                this.imageLink = "";
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    deleteMetadata(id) {
      this.isLoading = true;
      VoyageMetadataService.deleteData(id, this.voyage_id)
        .then(() => {
          VoyageMetadataService.getData(this.voyage_id)
            .then((res) => {
              notify({ type: "success", title: "Success", text: "Delete successfully!" });
              this.metadata = res.data.data.voyage_metadata;
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    // Timeline
    openTimeline(id) {
      this.isLoading = true;
      this.voyage_id = id;
      this.formTimeline.daytime_en = "";
      this.formTimeline.daytime_vn = "";
      this.formTimeline.detail_en = "";
      this.formTimeline.detail_vn = "";
      TimelineService.getDataByVoyage(id)
        .then((res) => {
          this.timelines = res.data.data.voyage_timelines;
          this.isTimelineOpen = true;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    openFormAddTimeline() {
      this.isAddTimelineOpen = true;
      this.formTimeline.daytime_en = "";
      this.formTimeline.daytime_vn = "";
      this.formTimeline.detail_en = "";
      this.formTimeline.detail_vn = "";
    },

    addTimeline() {
      this.isLoading = true;
      if (
        this.formTimeline.daytime_en == "" ||
        this.formTimeline.daytime_vn == "" ||
        this.formTimeline.detail_en == "" ||
        this.formTimeline.detail_vn == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        TimelineService.storeData(this.formTimeline, this.voyage_id)
          .then(() => {
            TimelineService.getDataByVoyage(this.voyage_id)
              .then((res) => {
                this.timelines = res.data.data.voyage_timelines;
                this.isAddTimelineOpen = false;
                notify({ type: "success", title: "Success", text: "Add Successfully!" });
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    deleteTimeline(id) {
      this.isLoading = true;
      TimelineService.deleteData(id, this.voyage_id)
        .then(() => {
          TimelineService.getDataByVoyage(this.voyage_id)
            .then((res) => {
              this.timelines = res.data.data.voyage_timelines;
              notify({ type: "success", title: "Success", text: "Delete Successfully!" });
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    openUpdateTimeline(id, daytime_en, daytime_vn, detail_en, detail_vn) {
      this.timeline_id = id;
      this.formUpdateTimeline.daytime_en = daytime_en;
      this.formUpdateTimeline.daytime_vn = daytime_vn;
      this.formUpdateTimeline.detail_en = detail_en;
      this.formUpdateTimeline.detail_vn = detail_vn;
      this.isUpdateTimelineOpen = true;
    },

    UpdateTimeline() {
      this.isLoading = true;
      if (
        this.formUpdateTimeline.daytime_en == "" ||
        this.formUpdateTimeline.daytime_vn == "" ||
        this.formUpdateTimeline.detail_en == "" ||
        this.formUpdateTimeline.detail_vn == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        TimelineService.updateData(this.timeline_id, this.voyage_id, this.formUpdateTimeline)
          .then(() => {
            TimelineService.getDataByVoyage(this.voyage_id)
              .then((res) => {
                this.timelines = res.data.data.voyage_timelines;
                notify({ type: "success", title: "Success", text: "Update Successfully!" });
                this.isUpdateTimelineOpen = false;
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    openDuplicate(
      title_en,
      title_vn,
      content_en,
      content_vn,
      destination_en,
      destination_vn,
      fares_en,
      fares_vn,
      summary_location_en,
      summary_location_vn,
      stt,
      time_en,
      time_vn
    ) {
      this.isFormShown = true;
      this.form.title_en = title_en;
      this.form.title_vn = title_vn;
      this.form.content_en = content_en;
      this.form.content_vn = content_vn;
      this.form.fares_en = fares_en;
      this.form.fares_vn = fares_vn;
      this.form.summary_location_en = summary_location_en;
      this.form.summary_location_vn = summary_location_vn;
      this.form.destination_en = destination_en;
      this.form.destination_vn = destination_vn;
      this.form.stt = stt;
      this.form.time_en = time_en;
      this.form_time_vn = time_vn;
    },
  },
};
</script>
<style scoped>
input,
textarea {
  border: 1px solid black;
  flex: 2;
  outline: none;
  min-height: 24px;
  padding: 4px 4px;
}

select {
  height: 24px;
}
</style>
