<template>
  <notifications
    width="50%"
    duration="3"
    class="mt-10"
    animation-type="css"
    speed="300"
    position="top right"
    classes="vue-notification"
  />
  <router-view />
</template>
