<template>
  <div class="voyage-search">
    <Suspense>
      <VoyageSearchComponent />
    </Suspense>
    <Menu></Menu>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
// import Card from "@/components/Card.vue";
import Menu from "@/components/Menu.vue";
import VoyageSearchComponent from "@/components/voyage/VoyageSearchComponent.vue";

export default {
  name: "VoyageSearch",
  components: {
    Footer,
    Menu,
    VoyageSearchComponent,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.selected {
  font-weight: bold;
  border-bottom: 2px solid var(--blue-color);
}
</style>
