<template>
  <div class="service-detail">
    <Suspense>
      <ActivityDetailComponent :activity_id="activity_id" />
    </Suspense>
  </div>
  <Menu></Menu>
  <Footer></Footer>
</template>
<script>
import Footer from "@/components/Footer.vue";
// import PageLogo from "@/components/PageLogo.vue";
// import CardService from "@/components/CardService.vue";
import Menu from "@/components/Menu.vue";
import ActivityDetailComponent from "@/components/activity/ActivityDetailComponent.vue";

export default {
  name: "ActivityDetail",
  components: {
    Footer,
    // PageLogo,
    // CardService,
    Menu,
    ActivityDetailComponent,
  },
  props: ["id"],
  computed: {
    activity_id() {
      return this.$route.params.id;
    },
  },
};
</script>
