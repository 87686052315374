<template>
  <Banner :imgUrl="aboutUsData.thumbnail" />
  <div
    class="grid grid-cols-1 px-5 p-0 gap-10 body-custom mb-10"
    style="max-width: 1440px"
  >
    <div class="flex flex-col">
      <div
        class="blue text-4xl md:text-5xl text-center md:text-start mb-8 home-page-title"
        v-html="aboutUsData[`title_${language}`]"
      ></div>
      <div
        class="blue"
        v-html="aboutUsData[`description_${language}`]"
      ></div>
      <div
        class="blue flex flex-col"
        v-for="(item, index) in aboutUsData.about_us_sections"
        :key="index"
      >
        <div class="section flex flex-col">
          <div
            class="blue font-medium mb-3"
            v-html="item[`section_name_${language}`]"
          ></div>
          <div
            class="blue mb-3"
            v-html="item[`content_${language}`]"
          ></div>
          <div
            class="flex flex-col justify-center items-center"
            v-if="item.image_link != ''"
          >
            <img
              :src="item.image_link"
              height="2790"
              class="w-full mb-3"
            />
            <div
              class="blue mb-3"
              v-html="item[`image_name_${language}`]"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// import PageLogo from "@/components/PageLogo.vue";
// import Blur from "@/components/Blur.vue";
import AboutUsService from "@/services/about.us.service";
import { defineProps, ref } from "vue";
import Banner from "@/components/Banner.vue";

const language = ref(localStorage.language);

const props = defineProps({
  about_us_id: {
    type: Number,
  },
});

let aboutUsData = { about_us_sections: [] };
try {
  const about_us = await AboutUsService.getDataById(props.about_us_id);
  aboutUsData = about_us.data.data.about_us;
} catch (err) {
  console.log(err);
}
</script>
<style></style>
