<template>
  <Banner :imgUrl="menuData[0].thumbnail" />
  <div
    class="flex flex-col p-0 items-center text-center gap-8 mb-8 body-custom px-5"
    style="max-width: 1440px"
  >
    <div class="blue text-lg md:text-xl uppercase">{{ $t("menu.suites") }}</div>
    <div
      class="blue text-4xl md:text-5xl py-3 uppercase home-page-title"
      v-html="menuData[0][`title_${language}`]"
    ></div>
    <div
      class="blue text-xs lg:text-base text-center w-full"
      v-html="menuData[0][`description_${language}`]"
    ></div>
  </div>
</template>
<script setup>
import MenuService from "@/services/menu.service";
import Banner from "@/components/Banner.vue";
import { ref } from "vue";

const language = ref(localStorage.language);

let menuData = {};
try {
  const menu = await MenuService.getDataByName("suite");
  menuData = menu.data.data.menu;
} catch (err) {
  console.log(err);
}
</script>
<style></style>
