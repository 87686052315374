<template>
  <div class="container mx-auto px-4 py-8 text-xs">
    <div class="flex-r items-center mb-4 justify-between">
      <h1 class="text-2xl font-bold">Vessel List</h1>
      <div
        class="flex-r font-semibold gap-2 items-center cursor-pointer"
        @click="openForm"
      >
        <div class="addBtn bg-contain"></div>
        Add new
      </div>
    </div>
    <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
      <table
        class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
      >
        <thead>
          <tr class="text-left">
            <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Booking Number</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Name Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Name Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Status</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Inclusion Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Inclusion Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Exclusion Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Exclusion Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Created At</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Updated At</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in datas"
            :key="data.id"
          >
            <td class="border px-4 py-2">{{ data.id }}</td>
            <td class="border px-4 py-2">{{ data.order_number }}</td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">{{ data.name_en }}</td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">{{ data.name_vn }}</td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ data.status == 0 ? "Đang hoạt động" : "Không hoạt động" }}
            </td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.inclusion_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.inclusion_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.exclusion_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.exclusion_vn"
            ></td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ formatDateTime(data.created_at) }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ formatDateTime(data.updated_at) }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
              <div class="w-full h-full grid grid-cols-2 gap-4">
                <button
                  @click="
                    openFormUpdate(
                      data.id,
                      data.name_en,
                      data.name_vn,
                      data.status,
                      data.inclusion_en,
                      data.inclusion_vn,
                      data.exclusion_en,
                      data.exclusion_vn,
                      data.order_number
                    )
                  "
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Edit
                </button>

                <button
                  @click="deleteBtn(data.id)"
                  class="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>

                <button
                  @click="openFormUploadImage(data.id)"
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Add Image
                </button>

                <button
                  @click="
                    openDuplicate(
                      data.name_en,
                      data.name_vn,
                      data.status,
                      data.inclusion_en,
                      data.inclusion_vn,
                      data.exclusion_en,
                      data.exclusion_vn,
                      data.order_number
                    )
                  "
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Duplicate
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add Vessel -->
    <div
      v-if="isFormOpen"
      class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    >
      <div class="add-form bg-white rounded p-4 overflow-auto">
        <h2 class="text-xl font-bold pb-2">Add New Vessel</h2>
        <form
          @submit.prevent="addVessel"
          class="flex flex-col justify-between"
        >
          <div class="mb-2 flex-col flex">
            <label class="block mb-1">Status</label>
            <div class="flex-r gap-3">
              <div class="flex-r gap-1">
                <input
                  type="radio"
                  name="gender"
                  value="0"
                  v-model="form.status"
                />
                Actived
              </div>
              <div class="flex-r gap-1">
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  v-model="form.status"
                />
                Deactived
              </div>
              <div class="flex-r gap-1">
                <input
                  type="radio"
                  name="gender"
                  value="2"
                  v-model="form.status"
                />
                Under Maintenance
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-8">
            <div class="mb-2 w-full">
              <label class="block w-full">Vessel's name eng</label>
              <input
                v-model="form.name_en"
                type="text"
                class="form-input h-10 p-3"
                style="width: 100%"
              />
            </div>
            <div class="mb-2 w-full">
              <label class="block w-full">Vessel's name vie</label>
              <input
                v-model="form.name_vn"
                type="text"
                class="form-input h-10 p-3"
                style="width: 100%"
              />
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Inclusion eng</label>
              <ckeditor
                :editor="editor"
                v-model="form.inclusion_en"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="mb-2 w-full">
              <label class="block w-full">Inclusion vie</label>
              <ckeditor
                :editor="editor"
                v-model="form.inclusion_vn"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Exclusion eng</label>
              <ckeditor
                :editor="editor"
                v-model="form.exclusion_en"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="mb-2 w-full">
              <label class="block w-full">Exclusion vie</label>
              <ckeditor
                :editor="editor"
                v-model="form.exclusion_vn"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="mb-2 w-full">
              <label class="block w-full">Booking Number</label>
              <input
                v-model="form.order_number"
                type="text"
                class="form-input h-10 p-3"
                style="width: 100%"
              />
            </div>
          </div>

          <div class="flex-r">
            <button
              type="submit"
              class="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Vessel
            </button>
            <button
              type="button"
              @click="closeForm"
              class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Update Vessel -->
    <div
      v-if="isUpdateFormOpen"
      class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    >
      <div class="add-form bg-white rounded p-4 overflow-auto">
        <h2 class="text-xl font-bold pb-2">Update Vessel</h2>
        <form
          @submit.prevent="updateVessel"
          class="flex flex-col justify-between"
        >
          <div class="mb-2 flex-col flex">
            <label class="block mb-1">Status</label>
            <div class="flex-r gap-3">
              <div class="flex-r gap-1">
                <input
                  type="radio"
                  name="gender"
                  value="0"
                  v-model="formUpdate.status"
                />
                Actived
              </div>
              <div class="flex-r gap-1">
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  v-model="formUpdate.status"
                />
                Deactived
              </div>
              <div class="flex-r gap-1">
                <input
                  type="radio"
                  name="gender"
                  value="2"
                  v-model="formUpdate.status"
                />
                Under Maintenance
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-8">
            <div class="mb-2 w-full">
              <label class="block w-full">Vessel's name eng</label>
              <input
                v-model="formUpdate.name_en"
                type="text"
                class="form-input h-10 p-3"
                style="width: 100%"
              />
            </div>
            <div class="mb-2 w-full">
              <label class="block w-full">Vessel's name vie</label>
              <input
                v-model="formUpdate.name_vn"
                type="text"
                class="form-input h-10 p-3"
                style="width: 100%"
              />
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Inclusion eng</label>
              <ckeditor
                :editor="editor"
                v-model="formUpdate.inclusion_en"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="mb-2 w-full">
              <label class="block w-full">Inclusion vie</label>
              <ckeditor
                :editor="editor"
                v-model="formUpdate.inclusion_vn"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Exclusion eng</label>
              <ckeditor
                :editor="editor"
                v-model="formUpdate.exclusion_en"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="mb-2 w-full">
              <label class="block w-full">Exclusion vie</label>
              <ckeditor
                :editor="editor"
                v-model="formUpdate.exclusion_vn"
                :config="editorConfig"
              ></ckeditor>
            </div>

            <div class="mb-2 w-full">
              <label class="block w-full">Booking Number</label>
              <input
                v-model="formUpdate.order_number"
                type="text"
                class="form-input h-10 p-3"
                style="width: 100%"
              />
            </div>
          </div>

          <div class="flex-r">
            <button
              type="submit"
              class="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Update Vessel
            </button>
            <button
              type="button"
              @click="closeForm"
              class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Upload Image Form -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isFormUploadImageOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Upload Image</h2>
      <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
        <table
          class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
        >
          <thead>
            <tr class="text-left">
              <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Image Link</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data in metadata"
              :key="data.id"
            >
              <td class="border px-4 py-2">{{ data.id }}</td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                <img
                  :src="data.image_link"
                  class="w-48"
                />
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
                <button
                  @click="deleteMetadata(data.id)"
                  class="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-col mb-5">
        <label>Image Link</label>
        <input
          type="text"
          class="border border-black p-1"
          v-model="imageLink"
        />
      </div>
      <input
        type="file"
        @change="onFileChange"
      />
      <div class="flex flex-row mt-4">
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded"
          @click="uploadImageLink"
        >
          Upload With Image Link
        </button>
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded ml-2"
          @click="uploadImage"
        >
          Upload
        </button>
        <button
          type="button"
          @click="closeFormUploadImage"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
  <LoadingPage :isLoading="isLoading"></LoadingPage>
</template>

<script>
import Admin from "@/services/admin.service";
import axios from "axios";
import VesselMetadataService from "@/services/vessel.metadata.service";
import VesselService from "@/services/vessel.service";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
import LoadingPage from "@/components/LoadingPage.vue";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
} from "ckeditor5";
import { notify } from "@kyvg/vue3-notification";
export default {
  name: "VesselPortal",
  components: {
    ckeditor: CKEditor.component,
    LoadingPage,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertTable",
          "mediaEmbed",
          "|",
          "bulletedList",
          "numberedList",
          "indent",
          "outdent",
        ],
        plugins: [
          Bold,
          Essentials,
          Heading,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          Undo,
        ],
      },
      datas: [],
      form: {
        name_en: "",
        name_vn: "",
        status: 0,
        inclusion_en: "",
        inclusion_vn: "",
        exclusion_en: "",
        exclusion_vn: "",
        order_number: "",
      },
      formUpdate: {
        name_en: "",
        name_vn: "",
        status: 0,
        inclusion_en: "",
        inclusion_vn: "",
        exclusion_en: "",
        exclusion_vn: "",
        order_number: "",
      },
      isUpdateFormOpen: false,
      isFormOpen: false,
      isFormUploadImageOpen: false,
      metadata: [],
      vessel_id: "",
      isLoading: false,
      languages: [{ lang: "en" }, { lang: "vn" }],
      imageLink: "",
    };
  },
  mounted() {
    this.getVesselData();
  },
  methods: {
    formatDateTime(datetime) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(datetime).toLocaleDateString("en-US", options);
    },
    async getVesselData() {
      this.isLoading = true;
      const response = await Admin.getVesselData();
      console.log(response.data.data.vessels);
      this.datas = response.data.data.vessels;
      this.isLoading = false;
    },
    openForm() {
      this.isFormOpen = true;
      this.form.name_en = "";
      this.form.name_vn = "";
      this.form.inclusion_en = "";
      this.form.inclusion_vn = "";
      this.form.exclusion_en = "";
      this.form.exclusion_vn = "";
      this.form.order_number = "";
    },
    openFormUpdate(
      id,
      name_en,
      name_vn,
      status,
      inclusion_en,
      inclusion_vn,
      exclusion_en,
      exclusion_vn,
      order_number
    ) {
      this.vessel_id = id;
      this.formUpdate.name_en = name_en;
      this.formUpdate.name_vn = name_vn;
      this.formUpdate.status = status;
      this.formUpdate.inclusion_en = inclusion_en;
      this.formUpdate.inclusion_vn = inclusion_vn;
      this.formUpdate.exclusion_en = exclusion_en;
      this.formUpdate.exclusion_vn = exclusion_vn;
      this.formUpdate.order_number = order_number;

      this.isUpdateFormOpen = true;
    },
    closeForm() {
      this.isFormOpen = false;
      this.isUpdateFormOpen = false;
    },
    addVessel() {
      this.isLoading = true;
      if (
        this.form.name_en == "" ||
        this.form.name_vn == "" ||
        this.form.inclusion_en == "" ||
        this.form.inclusion_vn == "" ||
        this.form.exclusion_en == "" ||
        this.form.exclusion_vn == "" ||
        this.form.order_number == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        try {
          Admin.addVessel(this.form)
            .then(() => {
              this.getVesselData();
              this.closeForm();
              notify({ type: "success", title: "Success", text: "Add vessel successful" });
              this.isLoading = false;
              this.closeForm();
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },
    updateVessel() {
      this.isLoading = true;
      if (
        this.formUpdate.name_en == "" ||
        this.formUpdate.name_vn == "" ||
        this.formUpdate.inclusion_en == "" ||
        this.formUpdate.inclusion_vn == "" ||
        this.formUpdate.exclusion_en == "" ||
        this.formUpdate.exclusion_vn == "" ||
        this.formUpdate.order_number == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        VesselService.updateData(this.vessel_id, this.formUpdate)
          .then(() => {
            this.getVesselData();
            notify({ type: "success", title: "Success", text: "Update Successfully!" });
            this.isLoading = false;
            this.isUpdateFormOpen = false;
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    deleteBtn(id) {
      this.isLoading = true;
      try {
        VesselService.deleteData(id)
          .then(() => {
            this.getVesselData();
            notify({ type: "success", title: "Success", text: "Delete Successfully!" });
            this.isLoading = false;
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      } catch (error) {
        notify({ type: "error", title: "Error", text: error.response.data.message });
        this.isLoading = false;
      }
    },

    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    handleSelectLanguage(event) {
      const language = event.target.value;
      this.form.language = language;
      this.formUpdate.language = language;
    },

    closeFormUploadImage() {
      this.isFormUploadImageOpen = false;
    },
    async openFormUploadImage(id) {
      this.isLoading = true;
      this.vessel_id = id;
      this.isFormUploadImageOpen = true;
      await VesselMetadataService.getData(id)
        .then((res) => {
          this.metadata = res.data.data.vessel_metadata;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
      this.imageLink = "";
    },

    async uploadImage() {
      if (!this.selectedFile) {
        notify({ type: "error", title: "Error", text: "Please select a file first" });
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      const formData = new FormData();
      formData.append("image", this.selectedFile);

      try {
        const response = await axios.post("https://api.imgur.com/3/image", formData, {
          headers: {
            Authorization: "Client-ID 8bb5dd7211002ee",
            "Content-Type": "multipart/form-data",
          },
        });

        await VesselMetadataService.storeData(this.vessel_id, response.data.data.link).then(
          async () => {
            await VesselMetadataService.getData(this.vessel_id)
              .then((res) => {
                this.metadata = res.data.data.vessel_metadata;
                notify({ type: "success", title: "Success", text: "Upload successful!" });
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          }
        );
      } catch (error) {
        notify({ type: "error", title: "Error", text: error.response.data.message });
        this.isLoading = false;
      }
    },

    uploadImageLink() {
      this.isLoading = true;
      if (this.imageLink == "") {
        notify({ type: "error", title: "Error", text: "Please fill image link!" });
        this.isLoading = false;
      } else {
        VesselMetadataService.storeData(this.vessel_id, this.imageLink)
          .then(() => {
            VesselMetadataService.getData(this.vessel_id)
              .then((res) => {
                this.metadata = res.data.data.vessel_metadata;
                notify({ type: "success", title: "Success", text: "Upload successful!" });
                this.isLoading = false;
                this.imageLink = "";
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    deleteMetadata(id) {
      this.isLoading = true;
      VesselMetadataService.deleteData(id, this.vessel_id)
        .then(async () => {
          notify({ type: "success", title: "Success", text: "Delete successfully!" });
          const res = await VesselMetadataService.getData(this.vessel_id);
          this.metadata = res.data.data.vessel_metadata;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },
    openDuplicate(
      name_en,
      name_vn,
      status,
      inclusion_en,
      inclusion_vn,
      exclusion_en,
      exclusion_vn,
      order_number
    ) {
      this.isFormOpen = true;
      this.form.name_en = name_en;
      this.form.name_vn = name_vn;
      this.form.inclusion_en = inclusion_en;
      this.form.inclusion_vn = inclusion_vn;
      this.form.exclusion_en = exclusion_en;
      this.form.exclusion_vn = exclusion_vn;
      this.form.status = status;
      this.form.order_number = order_number;
    },
  },
};
</script>

<style scoped>
table th,
td {
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 2;
  width: fit-content;
}

thead .sticky-col {
  z-index: 5;
}
</style>
