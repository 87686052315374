<template>
  <Banner :imgUrl="menuData[0].thumbnail" />
  <div class="blue flex justify-center text-lg md:text-xl pb-8 uppercase">
    {{ $t("menu.voyageFinder") }}
  </div>
  <div
    class="flex flex-row text-4xl md:text-5xl justify-center text-center items-center blue pb-8 py-3 uppercase home-page-title"
    v-html="menuData[0][`title_${language}`]"
  ></div>
</template>
<script setup>
import MenuService from "@/services/menu.service";
import Banner from "@/components/Banner.vue";
import { ref } from "vue";

const language = ref(localStorage.language);

let menuData = {};
try {
  const menu = await MenuService.getDataByName("voyage-search");
  menuData = menu.data.data.menu;
} catch (err) {
  console.log(err);
}
</script>
<style></style>
