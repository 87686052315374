<template>
  <div
    class="dropdown"
    style="width: 100%"
  >
    <button
      @click="toggleDropdown"
      class="dropdown-toggle gap-2 w-full"
    >
      <div class="flex-r items-center gap-2 max-w-64 min-w-fit">
        <font-awesome-icon :icon="['fas', 'user-group']" />
        <div>
          {{
            this.adult == 0 && this.children == 0 && this.infant == 0
              ? $t("selectOccupancy")
              : (this.adult > 0 ? this.adult + " " + $t("adult") + " " : "") +
                (this.children > 0 ? this.children + " " + $t("children") + " " : "") +
                (this.infant > 0 ? this.infant + " " + $t("infant") : "")
          }}
        </div>
      </div>
      <font-awesome-icon :icon="['fas', 'chevron-down']" />
    </button>
    <div
      v-if="isOpen"
      class="dropdown-menu w-full z-10"
    >
      <div class="my-1 grid grid-cols-2 items-center px-2 gap-2">
        <vue-number-input
          v-model="adult"
          @update:model-value="updateAdult"
          :min="0"
          :max="10"
          inline
          controls
        ></vue-number-input>
        <div>{{ $t("adultSearch") }}</div>
      </div>
      <div class="my-1 grid grid-cols-2 items-center px-2 gap-2">
        <vue-number-input
          v-model="children"
          @update:model-value="updateChildren"
          :min="0"
          :max="10"
          inline
          controls
        ></vue-number-input>
        <div>{{ $t("childrenSearch") }}</div>
      </div>
      <div class="my-1 grid grid-cols-2 items-center px-2 gap-2">
        <vue-number-input
          v-model="infant"
          @update:model-value="updateInfant"
          :min="0"
          :max="10"
          inline
          controls
        ></vue-number-input>
        <div>{{ $t("infantSearch") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComboboxLocation",
  props: {
    icon: String,
    options: {
      type: Array,
      required: true,
    },
    modelValue: Object,
  },
  data() {
    return {
      isOpen: false,
      selectedOption: null,
      adult: this.modelValue.adult || 0,
      children: this.modelValue.children || 0,
      infant: this.modelValue.infant || 0,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isOpen = false; // Đóng dropdown khi lựa chọn được thực hiện
    },
    updateAdult(value) {
      this.adult = value;
      this.updateModel();
    },
    updateChildren(value) {
      this.children = value;
      this.updateModel();
    },
    updateInfant(value) {
      this.infant = value;
      this.updateModel();
    },
    updateModel() {
      this.$emit("update:modelValue", {
        adult: this.adult,
        children: this.children,
        infant: this.infant,
      });
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.adult = newValue.adult || 0;
        this.children = newValue.children || 0;
        this.infant = newValue.infant || 0;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu {
  min-width: 350px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}
</style>
