<template>
  <div class="footer pb-24">
    <div class="flex flex-row justify-center body-custom w-full px-5">
      <PageLogo :logo-type="3"></PageLogo>
    </div>
    <div
      class="grid grid-cols-2 lg:grid-cols-4 p-0 body-custom w-full px-5"
      style="max-width: 1440px; margin-bottom: 0px !important"
    >
      <div class="grid grid-cols-1 lg:grid-cols-2 lg:col-span-2">
        <div class="text-sm md:text-lg text-white mb-4">GRAND PIONEERS</div>
        <!-- <div class="text-xs md:text-base text-white py-2 flex gap-1">
          {{ $t("footer.contactUs") }}
        </div> -->
        <div class="text-xs md:text-base text-white py-2 flex gap-1">
          <a href="/about-us/2"> {{ $t("footer.aboutUs") }} </a>
        </div>
        <div class="text-xs md:text-base text-white py-2 flex gap-1">
          {{ $t("footer.safetyPolicy") }}
        </div>
        <div class="text-xs md:text-base text-white py-2 flex gap-1">
          <a href="/cancel-policy/1"> {{ $t("footer.cancelPolicy") }} </a>
        </div>
        <div class="text-xs md:text-base text-white py-2 flex gap-1">{{ $t("footer.career") }}</div>
        <div class="text-xs md:text-base text-white py-2 flex gap-1">
          <a href="/blogs">{{ $t("footer.stories") }}</a>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="text-sm md:text-lg text-white mb-4">{{ $t("footer.contactUs") }}</div>
        <div class="text-xs md:text-base text-white py-2 flex gap-1 items-center">
          <div class="mobie-icon icon h-8 w-8"></div>
          <a href="tel:+84376589368"> (+84) 376 589 368 </a>
        </div>
        <!-- <div class="text-xs md:text-base text-white py-2 flex gap-1 items-center">
          <div class="phone-icon icon h-8 w-8"></div>
          02438389368
        </div> -->
        <div class="text-xs md:text-base text-white py-2 flex gap-1 items-center">
          <div class="mail-icon icon h-8 w-8"></div>
          <a href="mailto:info@grandpioneers.com">info@grandpioneers.com</a>
        </div>
      </div>

      <div class="flex-col hidden lg:flex ml-5">
        <div class="text-sm md:text-lg text-white mb-4">{{ $t("footer.followUs") }}</div>
        <div class="grid grid-cols-3 xl:grid-cols-6">
          <div class="text-xs text-white py-2 flex items-center">
            <a
              class="tripod-icon icon h-8 w-8"
              href="https://www.tripadvisor.com/Attraction_Review-g293923-d27093045-Reviews-Grand_Pioneers_Cruises-Halong_Bay_Quang_Ninh_Province.html"
              target="_blank"
            ></a>
          </div>
          <div class="text-xs text-white py-2 flex items-center">
            <a
              class="fb-icon icon h-8 w-8"
              href="https://www.facebook.com/grandpioneerscruises/"
              target="_blank"
            ></a>
          </div>
          <div class="text-xs text-white py-2 flex items-center">
            <a
              class="tiktok-icon icon h-8 w-8"
              href="https://www.tiktok.com/@grandpioneers.com"
              target="_blank"
            ></a>
          </div>
          <div class="text-xs text-white py-2 flex items-center">
            <a
              class="ytb-icon icon h-8 w-8"
              href="https://www.youtube.com/@grandpioneers"
              target="_blank"
            ></a>
          </div>
          <div class="text-xs text-white py-2 flex items-center">
            <a
              class="ins-icon icon h-8 w-8"
              href="https://www.instagram.com/grandpioneers/"
              target="_blank"
            ></a>
          </div>
          <div class="text-xs text-white py-2 flex items-center">
            <a
              class="linked-in-icon icon h-8 w-8"
              href="https://www.linkedin.com/company/grand-pioneers-cruises-vn/mycompany/"
              target="_blank"
            ></a>
          </div>
        </div>
        <a
          href="//www.dmca.com/Protection/Status.aspx?ID=1d74390b-d91e-40d5-abf2-4229b7b96398"
          title="DMCA.com Protection Status"
          class="dmca-badge"
        >
          <img
            src="https://images.dmca.com/Badges/dmca_protected_sml_120n.png?ID=1d74390b-d91e-40d5-abf2-4229b7b96398"
            alt="DMCA.com Protection Status"
          />
        </a>
      </div>
    </div>
    <div
      class="flex flex-col my-8 justify-center lg:mt-0 w-full pb-10 px-5 lg:hidden"
      style="max-width: 1440px"
    >
      <div class="text-sm text-white mb-4">{{ $t("footer.followUs") }}</div>
      <div class="flex flex-row justify-between">
        <div class="text-xs text-white py-2 flex items-center">
          <a
            class="tripod-icon icon h-8 w-8"
            href="https://www.tripadvisor.com/Attraction_Review-g293923-d27093045-Reviews-Grand_Pioneers_Cruises-Halong_Bay_Quang_Ninh_Province.html"
            target="_blank"
          ></a>
        </div>
        <div class="text-xs text-white py-2 flex items-center">
          <a
            class="fb-icon icon h-8 w-8"
            href="https://www.facebook.com/grandpioneerscruises/"
            target="_blank"
          ></a>
        </div>
        <div class="text-xs text-white py-2 flex items-center">
          <a
            class="tiktok-icon icon h-8 w-8"
            href="https://www.tiktok.com/@grandpioneers.com"
            target="_blank"
          ></a>
        </div>
        <div class="text-xs text-white py-2 flex items-center">
          <a
            class="ytb-icon icon h-8 w-8"
            href="https://www.youtube.com/@grandpioneers"
            target="_blank"
          ></a>
        </div>
        <div class="text-xs text-white py-2 flex items-center">
          <a
            class="ins-icon icon h-8 w-8"
            href="https://www.instagram.com/grandpioneers/"
            target="_blank"
          ></a>
        </div>
        <div class="text-xs text-white py-2 flex items-center">
          <a
            class="linked-in-icon icon h-8 w-8"
            href="https://www.linkedin.com/company/grand-pioneers-cruises-vn/mycompany/"
            target="_blank"
          ></a>
        </div>
      </div>
      <a
        href="//www.dmca.com/Protection/Status.aspx?ID=1d74390b-d91e-40d5-abf2-4229b7b96398"
        title="DMCA.com Protection Status"
        class="dmca-badge"
      >
        <img
          src="https://images.dmca.com/Badges/dmca_protected_sml_120n.png?ID=1d74390b-d91e-40d5-abf2-4229b7b96398"
          alt="DMCA.com Protection Status"
        />
      </a>
    </div>

    <div class="flex flex-row w-full items-center justify-center text-sm">
      <div class="yellow">Copyright © 2024. Grand Pioneers. All rights reserved.</div>
    </div>
  </div>
</template>
<script>
import PageLogo from "@/components/PageLogo.vue";
import EmailCustomerService from "@/services/email.service";

const Footer = {
  name: "Footer",
  components: {
    PageLogo,
  },
  data() {
    return {
      form: {
        email: "",
      },
    };
  },
  methods: {
    sendEmail() {
      if (this.form.email == "") {
        this.$swal.fire({
          text: this.$t("emptyEmail"),
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        EmailCustomerService.storeData(this.form)
          .then(() => {
            this.$swal.fire({
              text: this.$t("recieveEmail"),
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.email = "";
          })
          .catch(() => {
            this.$swal.fire({
              text: this.$t("errorEmail"),
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },
};
export default Footer;
</script>

<style>
.swal2-timer-progress-bar {
  background: #e4b587;
}
</style>
