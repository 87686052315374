<template>
  <div class="container mx-auto px-4 py-8 text-xs">
    <div class="flex flex-col gap-5 mb-5">
      <div class="flex-r items-center mb-4 justify-between">
        <h1 class="text-2xl font-bold">Suites List</h1>
        <div
          class="flex-r font-semibold gap-2 items-center cursor-pointer"
          @click="openForm"
        >
          <div class="addBtn bg-contain"></div>
          Add new
        </div>
      </div>

      <div class="">
        <select @change="handleSelect">
          <option
            value=""
            disabled
            selected
          >
            Select a vessel
          </option>
          <option
            :value="index.id"
            v-for="index in vessels"
            :key="index"
          >
            {{ index.name_en }}
          </option>
        </select>
      </div>
    </div>

    <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
      <table
        class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
      >
        <thead>
          <tr class="text-left">
            <th class="py-2 px-3 sticky top-0 bg-gray-200">No.</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Vessel</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Type</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Detail Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Detail Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Bed Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Bed Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Bathroom Bathtub Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Bathroom Bathtub Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Bathroom Shower Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Bathroom Shower Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Suite Size Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Suite Size Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Children</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Infant</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Adult</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Amentities Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Amentities Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Unique Feature Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Unique Feature Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">VIP Privileges Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">VIP Privileges Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Created at</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Updated at</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in datas"
            :key="data.id"
          >
            <td class="border px-4 py-2">{{ data.stt }}</td>
            <td class="border px-4 py-2">{{ data.id }}</td>
            <td class="border px-4 py-2">{{ data.vessel }}</td>
            <td class="border px-4 py-2">
              {{ data.is_vip == 1 ? "VIP" : "NORMAL" }}
            </td>
            <td
              class="border px-4 py-2"
              v-html="data.title_en"
            ></td>
            <td
              class="border px-4 py-2"
              v-html="data.title_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.content_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.content_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.content_detail_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.content_detail_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.bed_number_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.bed_number_vn"
            ></td>
            <td
              class="border px-4 py-2"
              v-html="data.bathroom_bathtub_en"
            ></td>
            <td
              class="border px-4 py-2"
              v-html="data.bathroom_bathtub_vn"
            ></td>
            <td
              class="border px-4 py-2"
              v-html="data.bathroom_shower_en"
            ></td>
            <td
              class="border px-4 py-2"
              v-html="data.bathroom_shower_vn"
            ></td>
            <td
              class="border px-4 py-2"
              v-html="data.suite_size_en"
            ></td>
            <td
              class="border px-4 py-2"
              v-html="data.suite_size_vn"
            ></td>
            <td class="border px-4 py-2">
              {{ data.children + " Childrens" }}
            </td>
            <td class="border px-4 py-2">
              {{ data.infant + " Infants" }}
            </td>
            <td class="border px-4 py-2">
              {{ data.infant + " Adults" }}
            </td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.amentities_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.amentities_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.unique_feature_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.unique_feature_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.vip_privileges_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.vip_privileges_vn"
            ></td>
            <td class="border px-4 py-2">
              {{ formatDateTime(data.created_at) }}
            </td>
            <td class="border px-4 py-2">
              {{ formatDateTime(data.updated_at) }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
              <div class="w-full h-full grid grid-cols-2 gap-4">
                <button
                  @click="
                    openEditForm(
                      data.vessel_id,
                      data.id,
                      data.title_en,
                      data.title_vn,
                      data.content_en,
                      data.content_vn,
                      data.content_detail_en,
                      data.content_detail_vn,
                      data.bed_number_en,
                      data.bed_number_vn,
                      data.suite_size_en,
                      data.suite_size_vn,
                      data.bathroom_bathtub_en,
                      data.bathroom_bathtub_vn,
                      data.bathroom_shower_en,
                      data.bathroom_shower_vn,
                      data.children,
                      data.infant,
                      data.adult,
                      data.amentities_en,
                      data.amentities_vn,
                      data.unique_feature_en,
                      data.unique_feature_vn,
                      data.decks,
                      data.number_of_suites,
                      data.price,
                      data.is_vip,
                      data.vip_privileges_en,
                      data.vip_privileges_vn,
                      data.stt
                    )
                  "
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Edit
                </button>
                <button
                  @click="deleteSuite(data.vessel_id, data.id)"
                  class="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
                <button
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                  @click="openFormUploadImage(data.id)"
                >
                  Add Image
                </button>
                <button
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                  @click="
                    openDuplicate(
                      data.vessel_id,
                      data.title_en,
                      data.title_vn,
                      data.content_en,
                      data.content_vn,
                      data.content_detail_en,
                      data.content_detail_vn,
                      data.bed_number_en,
                      data.bed_number_vn,
                      data.suite_size_en,
                      data.suite_size_vn,
                      data.bathroom_bathtub_en,
                      data.bathroom_bathtub_vn,
                      data.bathroom_shower_en,
                      data.bathroom_shower_vn,
                      data.children,
                      data.infant,
                      data.adult,
                      data.amentities_en,
                      data.amentities_vn,
                      data.unique_feature_en,
                      data.unique_feature_vn,
                      data.decks,
                      data.number_of_suites,
                      data.price,
                      data.is_vip,
                      data.vip_privileges_en,
                      data.vip_privileges_vn,
                      data.stt
                    )
                  "
                >
                  Duplicate
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Add Suite Form -->
  <div
    v-if="isFormOpen"
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Add New Suite</h2>
      <form
        @submit.prevent="addSuite"
        class="flex flex-col justify-between"
      >
        <div class="">
          <select
            @change="handleSelect"
            v-model="this.vessel_id"
          >
            <option
              value=""
              disabled
              selected
            >
              Select a vessel
            </option>
            <option
              :value="index.id"
              v-for="index in vessels"
              :key="index"
            >
              {{ index.name_en }}
            </option>
          </select>
        </div>

        <div class="flex-r gap-3">
          <div class="mb-2 flex-col flex">
            <label class="block mb-1">Type</label>
            <div class="flex-r gap-3">
              <div class="flex-r gap-1">
                <input
                  type="radio"
                  name="gender"
                  value="0"
                  v-model="form.is_vip"
                />
                NORMAL
              </div>
              <div class="flex-r gap-1">
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  v-model="form.is_vip"
                />
                VIP
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-8">
          <div class="mb-2 w-full">
            <label class="block w-full">Title Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.title_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Title Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.title_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.content_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.content_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Detail Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.content_detail_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Detail Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.content_detail_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Suite Size Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.suite_size_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Suite Size Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.suite_size_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bed Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.bed_number_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bed Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.bed_number_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bathroom Bathtub Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.bathroom_bathtub_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bathroom Bathtub Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.bathroom_bathtub_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bathroom Shower Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.bathroom_shower_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bathroom Shower Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.bathroom_shower_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Amentities Eng </label>
            <ckeditor
              :editor="editor"
              v-model="form.amentities_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Amentities Vie </label>
            <ckeditor
              :editor="editor"
              v-model="form.amentities_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Unique Feature Eng </label>
            <ckeditor
              :editor="editor"
              v-model="form.unique_feature_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Unique Feature Vie </label>
            <ckeditor
              :editor="editor"
              v-model="form.unique_feature_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> VIP Privileges Eng </label>
            <ckeditor
              :editor="editor"
              v-model="form.vip_privileges_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> VIP Privileges Vie </label>
            <ckeditor
              :editor="editor"
              v-model="form.vip_privileges_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Children </label>
            <vue-number-input
              v-model="form.children"
              :min="0"
              :max="10"
              inline
              controls
            ></vue-number-input>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Infant </label>
            <vue-number-input
              v-model="form.infant"
              :min="0"
              :max="10"
              inline
              controls
            ></vue-number-input>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Adult </label>
            <vue-number-input
              v-model="form.adult"
              :min="0"
              :max="10"
              inline
              controls
            ></vue-number-input>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Number Of Suites</label>
            <vue-number-input
              v-model="form.number_of_suites"
              inline
              controls
            ></vue-number-input>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Decks</label>
            <input
              type="text"
              v-model="form.decks"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Order </label>
            <vue-number-input
              v-model="form.stt"
              :min="1"
              inline
              controls
            ></vue-number-input>
          </div>
        </div>

        <div class="flex-r">
          <button
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Add Sutie
          </button>
          <button
            type="button"
            @click="closeForm"
            class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- Update Suite Form -->
  <div
    v-if="isFormEditOpen"
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Update Suite</h2>
      <form
        @submit.prevent="updateSuite"
        class="flex flex-col justify-between"
      >
        <div class="flex-r gap-3">
          <div class="mb-2 flex-col flex">
            <label class="block mb-1">Type</label>
            <div class="flex-r gap-3">
              <div class="flex-r gap-1">
                <input
                  type="radio"
                  name="gender"
                  value="0"
                  v-model="formEdit.is_vip"
                />
                NORMAL
              </div>
              <div class="flex-r gap-1">
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  v-model="formEdit.is_vip"
                />
                VIP
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-8">
          <div class="mb-2 w-full">
            <label class="block w-full">Title Eng</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.title_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Title Vie</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.title_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Eng</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.content_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Vie</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.content_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Detail Eng</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.content_detail_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Detail Vie</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.content_detail_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Suite Size Eng</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.suite_size_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Suite Size Vie </label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.suite_size_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bed Eng</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.bed_number_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bed Vie</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.bed_number_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bathroom Bathtub Eng</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.bathroom_bathtub_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bathroom Bathtub Vie</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.bathroom_bathtub_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bathroom Shower Eng</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.bathroom_shower_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Bathroom Shower Vie</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.bathroom_shower_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Amentities Eng </label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.amentities_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Amentities Vie </label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.amentities_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Unique Feature Eng </label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.unique_feature_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Unique Feature Vie </label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.unique_feature_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> VIP Privileges Eng </label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.vip_privileges_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> VIP Privileges Vie </label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.vip_privileges_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Children </label>
            <vue-number-input
              v-model="formEdit.children"
              :min="0"
              :max="10"
              inline
              controls
            ></vue-number-input>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Infant </label>
            <vue-number-input
              v-model="formEdit.infant"
              :min="0"
              :max="10"
              inline
              controls
            ></vue-number-input>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Adult </label>
            <vue-number-input
              v-model="formEdit.adult"
              :min="0"
              :max="10"
              inline
              controls
            ></vue-number-input>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Number Of Suites</label>
            <vue-number-input
              v-model="formEdit.number_of_suites"
              inline
              controls
            ></vue-number-input>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Decks</label>
            <input
              type="text"
              v-model="formEdit.decks"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Order </label>
            <vue-number-input
              v-model="formEdit.stt"
              :min="1"
              inline
              controls
            ></vue-number-input>
          </div>
        </div>
        <div class="flex-r">
          <button
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Update Suite
          </button>
          <button
            type="button"
            @click="closeForm"
            class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- Upload Image Form -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isFormUploadImageOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Upload Image</h2>
      <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
        <table
          class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
        >
          <thead>
            <tr class="text-left">
              <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Image Link</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data in metadata"
              :key="data.id"
            >
              <td class="border px-4 py-2">{{ data.id }}</td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                <img
                  :src="data.image_link"
                  class="w-48"
                />
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
                <button
                  @click="deleteMetadata(data.id)"
                  class="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-col mb-5">
        <label>Image Link</label>
        <input
          type="text"
          class="border border-black p-1"
          v-model="imageLink"
        />
      </div>
      <input
        type="file"
        @change="onFileChange"
        multiple
      />
      <div class="flex flex-row mt-4">
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded"
          @click="uploadImageLink"
        >
          Upload With Image Link
        </button>
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded ml-2"
          @click="uploadImage"
        >
          Upload
        </button>
        <button
          type="button"
          @click="closeFormUploadImage"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
  <LoadingPage :isLoading="isLoading"></LoadingPage>
</template>

<script>
import Admin from "@/services/admin.service";
import axios from "axios";
import SuiteMetadataService from "@/services/suite.metadata.service";
import SuiteService from "@/services/suite.service";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
import LoadingPage from "@/components/LoadingPage.vue";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
} from "ckeditor5";
import { notify } from "@kyvg/vue3-notification";
import { formatNumberWithCommas } from "@/helpers/convert.number";

export default {
  name: "SuitePortal",
  components: {
    ckeditor: CKEditor.component,
    LoadingPage,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertTable",
          "mediaEmbed",
          "|",
          "BulletedList",
          "NumberedList",
          "indent",
          "outdent",
        ],
        plugins: [
          Bold,
          Essentials,
          Heading,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          Undo,
        ],
      },
      datas: [],
      vessels: [],
      metadata: [],
      isFormOpen: false,
      isFormEditOpen: false,
      isFormUploadImageOpen: false,
      vessel_id: "",
      form: {
        title_en: "",
        title_vn: "",
        content_en: "",
        content_vn: "",
        content_detail_en: "",
        content_detail_vn: "",
        bed_number_en: "",
        bed_number_vn: "",
        suite_size_en: "",
        suite_size_vn: "",
        bathroom_bathtub_en: "",
        bathroom_bathtub_vn: "",
        bathroom_shower_en: "",
        bathroom_shower_vn: "",
        children: 0,
        infant: 0,
        adult: 0,
        amentities_en: "",
        amentities_vn: "",
        decks: "",
        unique_feature_en: "",
        unique_feature_vn: "",
        number_of_suites: "",
        price: "1000",
        is_vip: 0,
        vip_privileges_en: "",
        vip_privileges_vn: "",
        stt: "",
      },
      vessel_id_edit: "",
      suite_id: "",
      formEdit: {
        title_en: "",
        title_vn: "",
        content_en: "",
        content_vn: "",
        content_detail_en: "",
        content_detail_vn: "",
        bed_number_en: "",
        bed_number_vn: "",
        suite_size_en: "",
        suite_size_vn: "",
        bathroom_bathtub_en: "",
        bathroom_bathtub_vn: "",
        bathroom_shower_en: "",
        bathroom_shower_vn: "",
        children: "",
        infant: "",
        adult: "",
        amentities_en: "",
        amentities_vn: "",
        decks: "",
        unique_feature_en: "",
        unique_feature_vn: "",
        number_of_suites: "",
        price: "1000",
        is_vip: 0,
        vip_privileges_en: "",
        vip_privileges_vn: "",
        stt: "",
      },
      imageUrl: "",
      isLoading: false,
      languages: [{ lang: "en" }, { lang: "vn" }],
      imageLink: "",
    };
  },
  mounted() {
    this.getVesselData();
  },
  methods: {
    formatNumberWithCommas,
    formatDateTime(datetime) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(datetime).toLocaleDateString("en-US", options);
    },
    updateData(newValue) {
      this.formEdit.content = newValue;
    },
    getVesselData() {
      this.isLoading = true;
      Admin.getVesselData()
        .then((res) => {
          this.vessels = res.data.data.vessels;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    getSuiteData(vessel_id) {
      this.isLoading = true;
      SuiteService.getData(vessel_id)
        .then((res) => {
          this.datas = res.data.data.suites;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    deleteSuite(vessel_id, id) {
      this.isLoading = true;
      try {
        Admin.deleteSuite(vessel_id, id)
          .then(() => {
            this.getSuiteData(vessel_id);
            notify({ type: "success", title: "Success", text: "Delete Successfully!" });
            this.isLoading = false;
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      } catch (error) {
        notify({ type: "error", title: "Error", text: error.response.data.message });
        this.isLoading = false;
      }
    },

    //Add function
    openForm() {
      this.isFormOpen = true;
      this.form.title_en = "";
      this.form.title_vn = "";
      this.form.content_en = "";
      this.form.content_vn = "";
      this.form.content_detail_en = "";
      this.form.content_detail_vn = "";
      this.form.bed_number_en = "";
      this.form.bed_number_vn = "";
      this.form.suite_size_en = "";
      this.form.suite_size_vn = "";
      this.form.bathroom_bathtub_en = "";
      this.form.bathroom_bathtub_vn = "";
      this.form.bathroom_shower_en = "";
      this.form.bathroom_shower_vn = "";
      this.form.children = 0;
      this.form.infant = 0;
      this.form.adult = 0;
      this.form.amentities_en = "";
      this.form.amentities_vn = "";
      this.form.decks = "";
      this.form.number_of_suites = "";
      this.form.unique_feature_en = "";
      this.form.unique_feature_vn = "";
      this.form.is_vip = 0;
      this.form.vip_privileges_en = "";
      this.form.vip_privileges_vn = "";
      this.form.stt = "";
    },

    closeForm() {
      this.isFormOpen = false;
      this.isFormEditOpen = false;
    },

    handleSelect(event) {
      const vesselId = event.target.value;
      if (vesselId) {
        this.vessel_id = vesselId;
        this.getSuiteData(vesselId);
      }
    },

    addSuite() {
      this.isLoading = true;
      if (
        this.form.title_en == "" ||
        this.form.title_vn == "" ||
        this.form.content_en == "" ||
        this.form.content_vn == "" ||
        this.form.content_detail_en == "" ||
        this.form.content_detail_vn == "" ||
        this.form.bed_number_en == "" ||
        this.form.bed_number_vn == "" ||
        this.form.suite_size_en == "" ||
        this.form.suite_size_vn == "" ||
        this.form.bathroom_bathtub_en == "" ||
        this.form.bathroom_bathtub_vn == "" ||
        this.form.bathroom_shower_en == "" ||
        this.form.bathroom_shower_vn == "" ||
        this.form.amentities_en == "" ||
        this.form.amentities_vn == "" ||
        this.form.price == "" ||
        this.form.decks == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        try {
          SuiteService.storeData(this.form, this.vessel_id)
            .then(() => {
              notify({ type: "success", title: "Success", text: "Add Suite Successfully!" });
              this.getSuiteData(this.vessel_id);
              this.isFormOpen = false;
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    //Update function
    openEditForm(
      vessel_id,
      id,
      title_en,
      title_vn,
      content_en,
      content_vn,
      content_detail_en,
      content_detail_vn,
      bed_number_en,
      bed_number_vn,
      suite_size_en,
      suite_size_vn,
      bathroom_bathtub_en,
      bathroom_bathtub_vn,
      bathroom_shower_en,
      bathroom_shower_vn,
      children,
      infant,
      adult,
      amentities_en,
      amentities_vn,
      unique_feature_en,
      unique_feature_vn,
      decks,
      number_of_suites,
      price,
      is_vip,
      vip_privileges_en,
      vip_privileges_vn,
      stt
    ) {
      this.isFormEditOpen = true;
      this.formEdit.title_en = title_en;
      this.formEdit.title_vn = title_vn;
      this.formEdit.content_en = content_en;
      this.formEdit.content_vn = content_vn;
      this.formEdit.content_detail_en = content_detail_en;
      this.formEdit.content_detail_vn = content_detail_vn;
      this.formEdit.bed_number_en = bed_number_en;
      this.formEdit.bed_number_vn = bed_number_vn;
      this.formEdit.suite_size_en = suite_size_en;
      this.formEdit.suite_size_vn = suite_size_vn;
      this.formEdit.bathroom_bathtub_en = bathroom_bathtub_en;
      this.formEdit.bathroom_bathtub_vn = bathroom_bathtub_vn;
      this.formEdit.bathroom_shower_en = bathroom_shower_en;
      this.formEdit.bathroom_shower_vn = bathroom_shower_vn;
      this.formEdit.children = children;
      this.formEdit.infant = infant;
      this.formEdit.adult = adult;
      this.formEdit.amentities_en = amentities_en;
      this.formEdit.amentities_vn = amentities_vn;
      this.formEdit.unique_feature_en = unique_feature_en;
      this.formEdit.unique_feature_vn = unique_feature_vn;
      this.formEdit.decks = decks;
      this.formEdit.number_of_suites = number_of_suites;
      this.formEdit.price = price;
      this.formEdit.is_vip = is_vip;
      this.formEdit.vip_privileges_en = vip_privileges_en;
      this.formEdit.vip_privileges_vn = vip_privileges_vn;
      this.vessel_id_edit = vessel_id;
      this.suite_id = id;
      this.formEdit.stt = stt;
    },

    updateSuite() {
      this.isLoading = true;
      if (
        this.formEdit.title_en == "" ||
        this.formEdit.title_vn == "" ||
        this.formEdit.content_en == "" ||
        this.formEdit.content_vn == "" ||
        this.formEdit.content_detail_en == "" ||
        this.formEdit.content_detail_vn == "" ||
        this.formEdit.bed_number_en == "" ||
        this.formEdit.bed_number_vn == "" ||
        this.formEdit.suite_size_en == "" ||
        this.formEdit.suite_size_vn == "" ||
        this.formEdit.bathroom_bathtub_en == "" ||
        this.formEdit.bathroom_bathtub_vn == "" ||
        this.formEdit.bathroom_shower_en == "" ||
        this.formEdit.bathroom_shower_vn == "" ||
        this.formEdit.amentities_en == "" ||
        this.formEdit.amentities_vn == "" ||
        this.formEdit.price == "" ||
        this.formEdit.decks == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        console.log(this.formEdit);
        this.isLoading = false;
      } else {
        try {
          SuiteService.updateData(this.suite_id, this.vessel_id_edit, this.formEdit)
            .then(() => {
              this.isFormEditOpen = false;
              this.getSuiteData(this.vessel_id_edit);
              notify({ type: "success", title: "Success", text: "Update Successfully!" });
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    //Duplicate
    openDuplicate(
      vessel_id,
      title_en,
      title_vn,
      content_en,
      content_vn,
      content_detail_en,
      content_detail_vn,
      bed_number_en,
      bed_number_vn,
      suite_size_en,
      suite_size_vn,
      bathroom_bathtub_en,
      bathroom_bathtub_vn,
      bathroom_shower_en,
      bathroom_shower_vn,
      children,
      infant,
      adult,
      amentities_en,
      amentities_vn,
      unique_feature_en,
      unique_feature_vn,
      decks,
      number_of_suites,
      price,
      stt
    ) {
      this.form.title_en = title_en;
      this.form.title_vn = title_vn;
      this.form.content_en = content_en;
      this.form.content_vn = content_vn;
      this.form.content_detail_en = content_detail_en;
      this.form.content_detail_vn = content_detail_vn;
      this.form.bed_number_en = bed_number_en;
      this.form.bed_number_vn = bed_number_vn;
      this.form.suite_size_en = suite_size_en;
      this.form.suite_size_vn = suite_size_vn;
      this.form.bathroom_bathtub_en = bathroom_bathtub_en;
      this.form.bathroom_bathtub_vn = bathroom_bathtub_vn;
      this.form.bathroom_shower_en = bathroom_shower_en;
      this.form.bathroom_shower_vn = bathroom_shower_vn;
      this.form.children = children;
      this.form.infant = infant;
      this.form.adult = adult;
      this.form.amentities_en = amentities_en;
      this.form.amentities_vn = amentities_vn;
      this.form.unique_feature_en = unique_feature_en;
      this.form.unique_feature_vn = unique_feature_vn;
      this.form.decks = decks;
      this.form.number_of_suites = number_of_suites;
      this.form.price = price;
      this.form.stt = stt;
      this.vessel_id = vessel_id;
      this.isFormOpen = true;
    },

    //Upload Image
    onFileChange(event) {
      this.selectedFile = event.target.files;
    },

    handleSelectLanguage(event) {
      const language = event.target.value;
      this.form.language = language;
      this.formEdit.language = language;
    },

    closeFormUploadImage() {
      this.isFormUploadImageOpen = false;
    },

    openFormUploadImage(id) {
      this.isLoading = true;
      this.suite_id = id;
      this.isFormUploadImageOpen = true;
      SuiteMetadataService.getData(id)
        .then((res) => {
          this.metadata = res.data.data.suite_metadata;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    async uploadImage() {
      if (!this.selectedFile) {
        notify({ type: "error", title: "Error", text: "Please select a file first" });
        this.isLoading = false;
        return;
      }

      for (let i = 0; i < this.selectedFile.length; ++i) {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("image", this.selectedFile[i]);
        try {
          const response = await axios.post("https://api.imgur.com/3/image", formData, {
            headers: {
              Authorization: "Client-ID 8bb5dd7211002ee",
              "Content-Type": "multipart/form-data",
            },
          });
          SuiteMetadataService.storeData(this.suite_id, response.data.data.link).then(() => {
            SuiteMetadataService.getData(this.suite_id)
              .then((res) => {
                this.metadata = res.data.data.suite_metadata;
                notify({ type: "success", title: "Success", text: "Add Image Successfully!" });
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    uploadImageLink() {
      this.isLoading = true;
      if (this.imageLink == "") {
        notify({ type: "error", title: "Error", text: "Please fill image link!" });
        this.isLoading = false;
      } else {
        SuiteMetadataService.storeData(this.suite_id, this.imageLink)
          .then(() => {
            SuiteMetadataService.getData(this.suite_id)
              .then((res) => {
                this.metadata = res.data.data.suite_metadata;
                notify({ type: "success", title: "Success", text: "Upload successful!" });
                this.isLoading = false;
                this.imageLink = "";
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    deleteMetadata(id) {
      this.isLoading = true;
      SuiteMetadataService.deleteData(id, this.suite_id)
        .then(() => {
          SuiteMetadataService.getData(this.suite_id)
            .then((res) => {
              this.metadata = res.data.data.suite_metadata;
              notify({ type: "success", title: "Success", text: "Delete successfully!" });
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
table th,
td {
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 2;
  width: fit-content;
}

thead .sticky-col {
  z-index: 5;
}
</style>
