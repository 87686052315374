<template>
  <div class="card flex flex-col">
    <div class="flex flex-col">
      <div
        class="card-img bg-cover"
        :style="{ 'background-image': 'url(' + imgUrl + ')' }"
      ></div>
    </div>
    <div class="p-9 flex flex-col justify-between h-full">
      <div
        class="card-title blue line-clime-3"
        style="font-size: 20px"
        v-html="card_title"
      ></div>
      <div class="flex flex-col">
        <div
          class="card-heading__1 text-xs md:text-base blue mt-5 mb-3 line-clime-3"
          style="font-weight: 400"
          v-html="day"
        ></div>
        <div
          class="card-heading__1 text-xs md:text-base blue pb-3"
          style="font-weight: 400"
          v-html="fare"
        ></div>
        <!-- <div
          class="card-heading__1 blue pb-5 text-xs md:text-base"
          style="font-weight: 400"
          v-html="time"
        ></div> -->
      </div>
      <div class="flex flex-row gap-4 mt-10">
        <Button
          button_v="0"
          :text="$t('exploreButton')"
          @click="redirectTo(voyage_id)"
        ></Button>
        <Button
          button_v="1"
          :text="$t('inquireNowButton')"
          @click="redirectToBooking"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import VesselService from "@/services/vessel.service";

const CardVoyage = {
  props: {
    card_title: {
      type: String,
    },
    day: {
      type: String,
    },
    fare: {
      type: String,
    },
    time: {
      type: String,
    },
    imgUrl: {
      type: String,
    },
    voyage_id: {
      type: Number,
    },
  },
  components: {
    Button,
  },
  data() {
    return {
      booking_number: 1,
    };
  },
  created() {
    this.getBookingNumber();
  },
  methods: {
    getBookingNumber() {
      VesselService.getData()
        .then((res) => {
          const data = res.data.data.vessels.filter((e) => {
            return e.id == localStorage.vessel;
          });
          this.booking_number = data[0].order_number;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    redirectTo(id) {
      this.$router.push("/voyage-view/" + id).then(() => {
        window.location.reload();
      });
    },
    redirectToBooking() {
      if (this.card_title.includes("3")) {
        this.$router.push("/vessels/2/booking");
      } else if (this.card_title.includes("2")) {
        this.$router.push("/vessels/1/booking");
      } else {
        this.$router.push("/vessels/" + this.booking_number + "/booking");
      }
    },
  },
};

export default CardVoyage;
</script>

<style>
.line-clime-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
