<template>
  <div class="custom-select">
    <div
      class="selected-option"
      @click="toggleDropdown"
    >
      <span>{{ selectedLabel }}</span>
      <font-awesome-icon :icon="['fas', 'chevron-down']" />
    </div>
    <ul
      class="options-list"
      v-if="isOpen"
    >
      <li
        v-for="vessel in vessels"
        :key="vessel"
        @click="selectOption(vessel)"
        :class="{ selected: vessel === selectedVessel }"
      >
        <span>{{ vessel }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "CruiseSelectMb",
  data() {
    return {
      vessels: ["Cruise 1", "Cruise 2"],
      selectedVessel: null,
      isOpen: false,
    };
  },
  computed: {
    ...mapState(["vessel"]),
    selected: {
      get() {
        return this.vessel;
      },
      set(value) {
        this.updateVessel(value);
      },
    },
    selectedLabel() {
      const selectedOption = this.vessels.find((e) => e == this.selected);
      return selectedOption ? selectedOption : "Select Cruise"; // Placeholder text or default label
    },
  },
  methods: {
    ...mapMutations(["setVessel"]),
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(vessel) {
      this.selected = vessel;
      this.isOpen = false; // Close the dropdown after selection
    },
    updateVessel(value) {
      this.setVessel(value);
    },
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 200px;
}

.selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  color: white;
  /* margin-bottom: 100px; */
  margin-bottom: 30px;
  border-radius: 4px;
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 4px;
  list-style-type: none;
  padding: 0;
}

.options-list li {
  padding: 8px;
  cursor: pointer;
  color: white;
}

.options-list li:hover {
  background-color: #f0f0f0;
}

.selected {
  font-weight: bold;
}
</style>
