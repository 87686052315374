<template>
  <div class="suite-view">
    <Suspense>
      <SuiteHeading />
    </Suspense>

    <div
      class="flex flex-col p-0 items-center gap-8 mb-8 body-custom px-5"
      style="max-width: 1440px"
    >
      <Suspense>
        <AllSuiteComponent :vessel_id="vessel_id" />
      </Suspense>
    </div>
    <Menu></Menu>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
// import PageLogo from "@/components/PageLogo.vue";
// import Blur from "@/components/Blur.vue";
import Menu from "@/components/Menu.vue";
import AllSuiteComponent from "@/components/suite/AllSuiteComponent.vue";
import SuiteHeading from "@/components/heading/SuiteHeading.vue";

export default {
  name: "SuiteView",
  components: {
    // Blur,
    // CardVer2,
    Footer,
    // Combobox,
    // CardList,
    // PageLogo,
    Menu,
    // CardService,
    AllSuiteComponent,
    SuiteHeading,
  },
  props: ["id"],
  data() {
    return {
      selectedOption: "grid",
      detail: [
        "Twin/Double bed",
        "Suite: 150m² (+ 200m² sundeck)",
        "Front View",
        "3rd floor",
        "Max Occupancy: 04 adults and 02 children under 5",
      ],
    };
  },
  methods: {},
  computed: {
    vessel_id() {
      return this.$route.params.id;
    },
  },
};
</script>
