<template>
  <swiper
    :slides-per-view="1"
    :space-between="10"
    class="w-full mb-8 h-full xl:grid xl:items-stretch"
    @swiper="onSwiper"
    :loop="true"
    :navigation="true"
    :modules="modules"
  >
    <swiper-slide
      v-for="(item, index) in itemsPaginated"
      :key="index"
      class="grid grid-cols-1 xl:grid-cols-2"
    >
      <!-- Image PC -->
      <div class="flex items-center w-full justify-center md:pl-16">
        <img
          loading="lazy"
          :src="item.activity_metadata.length > 0 ? item.activity_metadata[0].image_link : ''"
          height="1330"
          class="w-full hidden xl:block img-home"
        />
      </div>

      <div class="service-slide-content md:px-16">
        <div class="flex flex-col justify-center h-full">
          <div class="flex flex-row overflow-hidden justify-start">
            <div class="flex flex-col justify-around h-full overflow-hidden">
              <div class="blue uppercase text-start text-lg md:text-xl overflow-hidden">
                <p>{{ $t("activity.titleNor") + $t("activity.titleItalic") }}</p>
              </div>
            </div>
          </div>
          <div
            class="blue overflow-hidden text-3xl py-5 md:text-5xl text-start home-page-title"
            v-html="item[`title_${language}`]"
          ></div>
          <div class="flex flex-row items-center justify-start flex-wrap blue overflow-hidden">
            <div
              :class="
                isMobile
                  ? 'text-start text-xs md:text-base font-normal my-4 line-clime-2'
                  : 'slide-content text-start text-xs md:text-base font-normal my-4'
              "
              v-html="item[`content_${language}`]"
            ></div>
          </div>
          <img
            loading="lazy"
            :src="item.activity_metadata.length > 0 ? item.activity_metadata[0].image_link : ''"
            height="1330"
            class="w-full block xl:hidden mb-5"
          />

          <div class="flex flex-row py-5 overflow-hidden justify-start w-full">
            <Button
              button_v="1"
              :text="$t('exploreButton')"
              @click="redirectTo(item.id)"
            ></Button>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { ref } from "vue";
import ActivityService from "@/services/activity.service";
import Button from "@/components/Button.vue";
import router from "@/router";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

let itemsPaginated = [];
try {
  const activities = await ActivityService.getData();
  itemsPaginated = activities.data.data.activities.sort((a, b) => {
    return a.stt - b.stt;
  });
} catch (err) {
  console.log(err);
}

const isMobile = ref(window.innerWidth <= 768);

const language = ref(localStorage.language);

const mySwiper = ref(null);

const modules = ref([Navigation]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const redirectTo = (id) => {
  router.push("/activity-detail/" + id).then(() => {
    window.location.reload();
  });
};
</script>
<style></style>
