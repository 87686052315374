<!-- eslint-disable -->
<script setup>
// import PageLogo from "@/components/PageLogo.vue";
// import Banner from "@/components/Banner.vue"
// import Button from "@/components/Button.vue";
import SuiteService from "@/services/suite.service";
import Banner from "@/components/Banner.vue";
import { defineProps, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import SuiteComponent from "@/components/suite/SuiteComponent.vue";

const props = defineProps({
  suite_id: {
    type: Number,
  },
});

const language = ref(localStorage.language);

let suiteData = { suite_metadata: [] };
try {
  const suite = await SuiteService.getDataById(localStorage.vessel, props.suite_id);
  suiteData = suite.data.data.suite;
} catch (err) {
  console.log(err);
}
const mySwiper = ref(null);

const modules = ref([Navigation, Autoplay]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};
// const activeDays = reactive({ data: [] });
// // const toggleDay = (index) => {
// //   if (activeDays.data.includes(index)) {
// //     activeDays.data = activeDays.data.filter((day) => day !== index);
// //   } else {
// //     activeDays.data.push(index);
// //   }
// // };

const selectedOption = ref("features");

const selectOption = (option) => {
  selectedOption.value = option;
};
</script>

<template>
  <Banner
    :imgUrl="suiteData.suite_metadata.length > 0 ? suiteData.suite_metadata[0].image_link : ''"
  ></Banner>
  <div
    class="block p-0 body-custom w-full px-5"
    style="max-width: 1440px"
  >
    <div class="flex-c items-center gap-8 relative">
      <div class="blue text-lg md:text-xl uppercase">{{ $t("suite.suite") }}</div>
      <div
        class="blue text-4xl md:text-5xl home-page-title"
        v-html="suiteData[`title_${language}`]"
      ></div>
      <div
        class="blue text-center w-full text-xs lg:text-base"
        v-html="suiteData[`content_detail_${language}`]"
      ></div>
      <div class="flex-r justify-center gap-8 w-full mb-8"></div>
    </div>

    <!-- <div class="flex-r gap-8 mb-8 items-center justify-between overflow-auto"> -->
    <!-- <button class="prevBtn h-8 w-8 md:h-10 md:w-10"></button> -->
    <swiper
      :slides-per-view="1"
      :space-between="10"
      class="w-full mb-8 mySwiperDetail"
      @swiper="onSwiper"
      :loop="true"
      :navigation="true"
      :modules="modules"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: true,
      }"
    >
      <swiper-slide
        v-for="(item, index) in suiteData.suite_metadata"
        :key="index"
      >
        <img
          :src="item.image_link"
          :alt="'Image ' + (index + 1)"
        />
      </swiper-slide>
      <div
        class="swiper-pagination"
        v-bind="pagination"
      ></div>
    </swiper>
    <!-- <img class="w-full" v-for="(value, key) in suiteData.suite_metadata" :key="key"
      :src="suiteData.suite_metadata.length > 0 ? value.image_link : ''" height="2790" /> -->
    <!-- </div> -->
    <div class="flex-c items-center mb-8">
      <div class="blue text-lg md:text-xl uppercase">{{ $t("suite.detail") }}</div>
    </div>
    <div class="flex-r border-bottom-blue mb-8 border-b justify-between flex">
      <div class="grid grid-cols-3 lg:flex lg:flex-row w-full gap-3">
        <div
          :class="[
            'grid-otp',
            'flex-r',
            'items-center',
            'blue',
            'gap-2',
            'uppercase',
            { selected: selectedOption == 'features' },
          ]"
          @click="selectOption('features')"
        >
          {{ $t("suite.feature") }}
        </div>
        <div
          :class="[
            'list-otp',
            'flex-r',
            'items-center',
            'blue',
            'gap-2',
            'uppercase',
            { selected: selectedOption == 'facilities' },
          ]"
          @click="selectOption('facilities')"
        >
          {{ $t("suite.facility") }}
        </div>
        <div
          :class="[
            'list-otp',
            'flex-r',
            'items-center',
            'blue',
            'gap-2',
            'uppercase',
            { selected: selectedOption == 'vip' },
          ]"
          v-if="suiteData.is_vip == 1"
          @click="selectOption('vip')"
        >
          {{ $t("suite.vip") }}
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-2 gap-4 w-full mb-8"
      v-if="selectedOption == 'features'"
    >
      <div class="flex flex-col md:flex-row items-center">
        <div class="flex items-center justify-center w-20 aspect-video">
          <img
            src="@/assets/suite-bed.png"
            style="width: 80px; width: 40px; object-fit: contain"
            alt=""
          />
        </div>
        <div class="flex-c gap-2">
          <div class="blue font-bold text-center md:text-start">{{ $t("suite.bed") }}</div>
          <div
            class="blue text-center md:text-start"
            v-html="suiteData[`bed_number_${language}`]"
          ></div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row items-center">
        <div class="flex items-center justify-center w-20 aspect-video">
          <img
            src="@/assets/suite-occupie.png"
            style="width: 80px; width: 40px; object-fit: contain"
            alt=""
          />
        </div>
        <div class="flex-c gap-2">
          <div class="blue text-center font-bold md:text-start">{{ $t("suite.occupancy") }}</div>
          <div class="blue text-center md:text-start">
            <p>{{ suiteData.adult + " " + $t("adult") }}</p>
            <p v-if="suiteData.children > 0">
              {{ suiteData.children > 0 ? suiteData.children + " " + $t("children") : "" }}
            </p>
            <p v-if="suiteData.infant > 0">
              {{ suiteData.infant > 0 ? suiteData.infant + " " + $t("infant") : "" }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row items-center">
        <div class="flex items-center justify-center w-20 aspect-video">
          <img
            src="@/assets/suite-size.png"
            style="width: 80px; width: 40px; object-fit: contain"
            alt=""
          />
        </div>
        <div class="flex-c gap-2">
          <div class="blue text-center font-bold md:text-start">{{ $t("suite.size") }}</div>
          <div
            class="blue text-center md:text-start"
            v-html="suiteData[`suite_size_${language}`]"
          ></div>
        </div>
      </div>
      <!-- Bathroom -->
      <div
        class="flex flex-col md:flex-row items-center"
        v-if="suiteData[`bathroom_bathtub_${language}`] != '0'"
      >
        <div class="flex items-center justify-center w-20 aspect-video">
          <img
            src="@/assets/suite-bathroom.png"
            style="width: 80px; width: 40px; object-fit: contain"
            alt=""
          />
        </div>
        <div class="flex-c gap-2">
          <div class="blue text-center font-bold md:text-start">{{ $t("suite.bathroom") }}</div>
          <div
            class="blue text-center md:text-start"
            v-html="suiteData[`bathroom_bathtub_${language}`]"
          ></div>
        </div>
      </div>
      <!-- shower -->
      <div
        class="flex flex-col md:flex-row items-center"
        v-if="suiteData[`bathroom_shower_${language}`] != '0'"
      >
        <div class="flex items-center justify-center w-20 aspect-video">
          <!-- <img src="@/assets/suite-bathroom.png" style="width: 80px; width: 40px; object-fit: contain" alt="" /> -->
          <img
            src="@/assets/shower.png"
            style="width: 80px; width: 40px; object-fit: contain"
            alt=""
          />
          <!-- <font-awesome-icon :icon="['fas', 'shower']" /> -->
        </div>
        <div class="flex-c gap-2">
          <div class="blue text-center font-bold md:text-start">{{ $t("suite.bathroom") }}</div>
          <div
            class="blue text-center md:text-start"
            v-html="suiteData[`bathroom_shower_${language}`]"
          ></div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row items-center">
        <div class="flex items-center justify-center w-20 aspect-video">
          <img
            src="@/assets/decks.png"
            style="width: 80px; width: 40px; object-fit: contain"
            alt=""
          />
        </div>
        <div class="flex-c gap-2">
          <div class="blue text-center font-bold md:text-start">{{ $t("suite.deck") }}</div>
          <div
            class="blue text-center md:text-start"
            v-html="suiteData[`decks`]"
          ></div>
        </div>
      </div>
      <div
        class="flex flex-col md:flex-row items-center"
        v-if="
          suiteData[`unique_feature_${language}`] != '' &&
          suiteData[`unique_feature_${language}`] != null
        "
      >
        <div class="flex items-center justify-center w-20 aspect-video">
          <img
            src="@/assets/unique.png"
            style="width: 80px; width: 40px; object-fit: contain"
            alt=""
          />
        </div>
        <div class="flex-c gap-2">
          <div class="blue text-center font-bold md:text-start">
            {{ $t("suite.uniqueFeature") }}
          </div>
          <div
            class="blue text-center md:text-start"
            v-html="suiteData[`unique_feature_${language}`]"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="gap-4 w-full mb-8 text-wrap facility"
      v-if="selectedOption == 'facilities'"
    >
      <div
        class="blue"
        v-html="suiteData[`amentities_${language}`]"
      ></div>
    </div>
    <div
      class="gap-4 w-full mb-8 text-wrap vip"
      v-if="selectedOption == 'vip'"
    >
      <div
        class="blue"
        v-html="suiteData[`vip_privileges_${language}`]"
      ></div>
    </div>

    <div class="mb-20 w-full">
      <div class="blue text-lg md:text-xl text-center uppercase mb-8">{{ $t("otherSuite") }}</div>
      <SuiteComponent />
    </div>
    <!-- <div class="flex-c mb-8 md:hidden">
      <div
        :class="[
          'grid-otp',
          'flex-r',
          'items-center',
          'blue',
          'gap-2',
          'border-bottom-blue',
          'mb-8',
          'uppercase',
        ]"
        @click="selectOption('features')"
      >
        {{ $t("suite.feature") }}
      </div>
      <div class="grid md:hidden grid-cols-2 md:grid-cols-2 gap-4 w-full">
        <div class="flex flex-col md:flex-row items-center">
          <div class="flex items-center justify-center w-20 aspect-video">
            <img
              src="@/assets/suite-bed.png"
              style="width: 80px; width: 40px; object-fit: contain"
              alt=""
            />
          </div>
          <div class="flex-c gap-2">
            <div class="blue font-bold text-center md:text-start">{{ $t("suite.bed") }}</div>
            <div
              class="blue text-center md:text-start"
              v-html="suiteData[`bed_number_${language}`]"
            ></div>
          </div>
        </div>

        <div class="flex flex-col md:flex-row items-center">
          <div class="flex items-center justify-center w-20 aspect-video">
            <img
              src="@/assets/suite-occupie.png"
              style="width: 80px; width: 40px; object-fit: contain"
              alt=""
            />
          </div>
          <div class="flex-c gap-2">
            <div class="blue text-center font-bold md:text-start">{{ $t("suite.occupancy") }}</div>
            <div class="blue text-center md:text-start">
              <p>{{ suiteData.adult + " " + $t("adult") }}</p>
              <p v-if="suiteData.children > 0">
                {{ suiteData.children > 0 ? suiteData.children + " " + $t("children") : "" }}
              </p>
              <p v-if="suiteData.infant > 0">
                {{ suiteData.infant > 0 ? suiteData.infant + " " + $t("infant") : "" }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row items-center">
          <div class="flex items-center justify-center w-20 aspect-video">
            <img
              src="@/assets/suite-size.png"
              style="width: 80px; width: 40px; object-fit: contain"
              alt=""
            />
          </div>
          <div class="flex-c gap-2">
            <div class="blue text-center font-bold md:text-start">{{ $t("suite.size") }}</div>
            <div
              class="blue text-center md:text-start"
              v-html="suiteData[`suite_size_${language}`]"
            ></div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row items-center">
          <div class="flex items-center justify-center w-20 aspect-video">
            <img
              src="@/assets/suite-bathroom.png"
              style="width: 80px; width: 40px; object-fit: contain"
              alt=""
            />
          </div>
          <div class="flex-c gap-2">
            <div class="blue text-center font-bold md:text-start">{{ $t("suite.bathroom") }}</div>
            <div
              class="blue text-center md:text-start"
              v-html="suiteData[`bathroom_bathtub_${language}`]"
            ></div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row items-center">
          <div class="flex items-center justify-center w-20 aspect-video">
            <img
              src="@/assets/shower.png"
              style="width: 80px; width: 40px; object-fit: contain"
              alt=""
            />
          </div>
          <div class="flex-c gap-2">
            <div class="blue text-center font-bold md:text-start">{{ $t("suite.bathroom") }}</div>
            <div
              class="blue text-center md:text-start"
              v-html="suiteData[`bathroom_shower_${language}`]"
            ></div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row items-center">
          <div class="flex items-center justify-center w-20 aspect-video">
            <img
              src="@/assets/decks.png"
              style="width: 80px; width: 40px; object-fit: contain"
              alt=""
            />
          </div>
          <div class="flex-c gap-2">
            <div class="blue text-center font-bold md:text-start">{{ $t("suite.deck") }}</div>
            <div
              class="blue text-center md:text-start"
              v-html="suiteData[`decks`]"
            ></div>
          </div>
        </div>
        <div
          class="flex flex-col md:flex-row items-center"
          v-if="
            suiteData[`unique_feature_${language}`] != '' &&
            suiteData[`unique_feature_${language}`] != null
          "
        >
          <div class="flex items-center justify-center w-20 aspect-video">
            <img
              src="@/assets/unique.png"
              style="width: 80px; width: 40px; object-fit: contain"
              alt=""
            />
          </div>
          <div class="flex-c gap-2">
            <div class="blue text-center font-bold md:text-start">
              {{ $t("suite.uniqueFeature") }}
            </div>
            <div
              class="blue text-center md:text-start"
              v-html="suiteData[`unique_feature_${language}`]"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-c mb-8 md:hidden">
      <div
        :class="[
          'grid-otp text-xs',
          'flex-r',
          'items-center',
          'blue',
          'gap-2',
          'border-bottom-blue',
          'mb-8',
          'uppercase',
        ]"
        @click="selectOption('features')"
      >
        {{ $t("suite.facility") }}
      </div>
      <div
        class="blue"
        v-html="suiteData[`amentities_${language}`]"
      ></div>
    </div>
    <div class="flex-c mb-8 md:hidden">
      <div
        :class="[
          'grid-otp text-xs',
          'flex-r',
          'items-center',
          'blue',
          'gap-2',
          'border-bottom-blue',
          'mb-8',
          'uppercase',
        ]"
        @click="selectOption('features')"
      >
        {{ $t("suite.facility") }}
      </div>
      <div
        class="blue"
        v-html="suiteData[`amentities_${language}`]"
      ></div>
    </div> -->
  </div>
</template>
