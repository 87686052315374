function isValidNumber(value) {
  return typeof value === "number" && !isNaN(value) && isFinite(value);
}

export const formatNumberWithCommas = (number) => {
  console.log(number);
  if (isValidNumber) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
