<template>
  <div class="flex-col items-center justify-center mb-8 hidden md:flex">
    <div
      class="blue text-5xl py-3 uppercase home-page-title mb-8"
      v-html="menuData[0][`title_${language}`]"
    ></div>
    <div
      class="blue text-xs lg:text-base text-center w-full mb-8"
      v-html="menuData[0][`description_${language}`]"
    ></div>
  </div>
  <div class="blue uppercase text-center text-lg md:hidden mb-8 home-page-title">
    <p>
      {{ $t("suite.titleNor") + $t("suite.titleItalic") }}
    </p>
  </div>
  <div class="mx-auto mb-8 w-full">
    <swiper
      :slides-per-view="perPage"
      :space-between="10"
      class="w-full mb-8 h-full md:grid md:items-stretch mySwiper"
      @swiper="onSwiper"
      :loop="true"
      :navigation="true"
      :modules="modules"
    >
      <swiper-slide
        v-for="(item, index) in itemsPaginated"
        :key="index"
        class="flex justify-center items-center"
      >
        <SuiteCardComponent
          :card_title="item[`title_${language}`]"
          :bed_number="item[`bed_number_${language}`]"
          :suite_size="item[`suite_size_${language}`]"
          :imgUrl="item.suite_metadata.length > 0 ? item.suite_metadata[0].image_link : ''"
          :occupancy="
            item.children > 0 && item.adult > 0 && item.infant > 0
              ? `${item.adult + ' ' + $t('adult')}, ${item.children + ' ' + $t('children')} ${
                  item.infant + $t('infant')
                }`
              : item.children <= 0 && item.adult > 0 && item.infant > 0
              ? `${item.adult + ' ' + $t('adult')} ${item.infant + ' ' + $t('infant')}`
              : item.children <= 0 && item.infant <= 0 && item.adult > 0
              ? `${item.adult + ' ' + $t('adult')}`
              : item.children > 0 && item.infant <= 0 && item.adult > 0
              ? `${item.adult + ' ' + $t('adult')} ${item.children + ' ' + $t('children')}`
              : `0 ${$t('adult')}`
          "
          :price="$t('suite.from') + ' ' + item.price"
          :suite_id="item.id"
          class="text-start w-full h-full"
        />
      </swiper-slide>
      <div
        class="swiper-pagination"
        v-bind="pagination"
      ></div>
    </swiper>
  </div>
</template>

<script setup>
// import Button from "@/components/Button.vue";
import MenuService from "@/services/menu.service";
import { ref } from "vue";
import SuiteService from "@/services/suite.service";
import SuiteCardComponent from "@/components/suite/SuiteCardComponent.vue";
// import router from "@/router";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

let itemsPaginated = [];
try {
  const suite = await SuiteService.getData(localStorage.vessel);
  itemsPaginated = suite.data.data.suites.sort((a, b) => {
    return a.stt - b.stt;
  });
} catch (err) {
  console.log(err);
}

const language = ref(localStorage.language);

const mySwiper = ref(null);
const modules = ref([Navigation]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const perPage = ref(3);

if (itemsPaginated.length == 1) {
  perPage.value = 1;
} else if (itemsPaginated.length == 2) {
  if (window.innerWidth >= 768) {
    perPage.value = 2;
  } else {
    perPage.value = 1;
  }
} else {
  if (window.innerWidth >= 768 && window.innerWidth <= 1280) {
    perPage.value = 2;
  } else if (window.innerWidth >= 1280) {
    perPage.value = 3;
  } else {
    perPage.value = 1;
  }
}

let menuData = {};
try {
  const menu = await MenuService.getDataByName("suite");
  menuData = menu.data.data.menu;
} catch (err) {
  console.log(err);
}
</script>
<style>
.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  color: rgb(21, 57, 90);
  /* Change this to your desired color */
  padding: 20px;
}

.mySwiper .swiper-slide {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Scale up and add shadow on hover */
.mySwiper .swiper-slide:hover {
  transform: scale(1.05);
  /* Adjust scale value as needed */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  /* Adjust shadow as needed */
}

@media (max-width: 767px) {
  .swiper .swiper-button-next,
  .swiper .swiper-button-prev {
    display: none;
  }
}
</style>
