import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import "./css/style.css";
import "./css/tailwind.css";
import Resource from "./helpers/resource";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faBars, faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import {
  faUserGroup,
  faCalendar,
  faSearch,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Notifications from "@kyvg/vue3-notification";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import sweetalert2 from "@/plugins/sweetalert2";
import "ant-design-vue/dist/reset.css";
import Antd from "ant-design-vue";
import { createMetaManager, defaultConfig } from "vue-meta";
const i18n = createI18n({
  locale: "en", // Ngôn ngữ mặc định
  fallbackLocale: "en",
  messages: {
    en: Resource.Eng,
    vn: Resource.Vi,
  },
});

export function getI18n() {
  return i18n;
}

if (!localStorage.vessel) {
  localStorage.setItem("vessel", 1);
}

if (!localStorage.language) {
  localStorage.setItem("language", "en");
}

if (!localStorage.adult) {
  localStorage.setItem("adult", 0);
}

if (!localStorage.children) {
  localStorage.setItem("children", 0);
}

if (!localStorage.infant) {
  localStorage.setItem("infant", 0);
}

library.add(faChevronDown);
library.add(faBars);
library.add(faGripVertical);
library.add(faLocationDot);
library.add(faUserGroup);
library.add(faCalendar, faSearch, faChevronRight);

const app = createApp(App);
const metaManager = createMetaManager(false, defaultConfig);

app
  .use(Notifications)
  .use(store)
  .use(Antd)
  .use(router)
  .use(i18n)
  .use(metaManager)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(sweetalert2)
  .component(VueNumberInput.name, VueNumberInput);
app.mount("#app");
