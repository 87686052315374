<template>
  <div class="container mx-auto px-4 py-8 text-xs">
    <div class="flex-r items-center mb-4 justify-between">
      <h1 class="text-2xl font-bold">Activities List</h1>
      <div
        class="flex-r font-semibold gap-2 items-center cursor-pointer"
        @click="openFormAddActivity"
      >
        <div class="addBtn bg-contain"></div>
        Add new
      </div>
    </div>

    <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
      <table
        class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
      >
        <thead>
          <tr class="text-left">
            <th class="py-2 px-3 sticky top-0 bg-gray-200">No.</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in activities"
            :key="data.id"
          >
            <td class="border px-4 py-2">{{ data.stt }}</td>
            <td class="border px-4 py-2">{{ data.id }}</td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.title_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.title_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.content_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.content_vn"
            ></td>
            <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
              <div class="grid grid-cols-2 w-full h-full gap-4">
                <button
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                  @click="
                    openUpdateActivities(
                      data.id,
                      data.title_en,
                      data.title_vn,
                      data.content_en,
                      data.content_vn,
                      data.stt
                    )
                  "
                >
                  Edit Activity
                </button>
                <button
                  class="bg-red-500 text-white px-4 py-2 rounded"
                  @click="deleteActivity(data.id)"
                >
                  Delete
                </button>
                <button
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                  @click="openFormUploadImageActivity(data.id)"
                >
                  Add Photo
                </button>
                <button
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                  @click="
                    openDuplicate(
                      data.title_en,
                      data.title_vn,
                      data.content_en,
                      data.content_vn,
                      data.stt
                    )
                  "
                >
                  Duplicate
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Add Activity -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isAddActivityOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Add Activity</h2>
      <div class="grid grid-cols-2 gap-8">
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Title Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formActivity.title_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Title Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formActivity.title_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Content Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formActivity.content_en"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Content Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formActivity.content_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <label class="block w-full"> Order </label>
          <vue-number-input
            v-model="formActivity.stt"
            :min="1"
            inline
            controls
          ></vue-number-input>
        </div>
      </div>

      <div class="flex-r gap-3">
        <button
          @click="addActivities()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Activities
        </button>
        <button
          @click="isAddActivityOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Update Activity -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isUpdateActivityOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Update Activities</h2>

      <div class="grid grid-cols-2 gap-8">
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Title Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdateActivity.title_en"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Title Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdateActivity.title_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Content Eng</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdateActivity.content_en"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <div class="mb-2 w-full">
          <div class="font-semibold flex-1">Content Vie</div>
          <ckeditor
            :editor="editor"
            v-model="formUpdateActivity.content_vn"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <div class="mb-2 w-full">
          <label class="block w-full"> Order </label>
          <vue-number-input
            v-model="formUpdateActivity.stt"
            :min="1"
            inline
            controls
          ></vue-number-input>
        </div>
      </div>

      <div class="flex-r gap-3">
        <button
          @click="UpdateActivities()"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Update Activities
        </button>
        <button
          @click="isUpdateActivityOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Upload Image Activity -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isFormUploadImageActivityOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Upload Image</h2>
      <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
        <table
          class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
        >
          <thead>
            <tr class="text-left">
              <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Image Link</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data in activity_metadata"
              :key="data.id"
            >
              <td class="border px-4 py-2">{{ data.id }}</td>
              <td class="border px-4 py-2 w-fit">
                <img
                  :src="data.image_link"
                  class="w-48"
                />
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
                <button
                  @click="deleteActivityMetadata(data.id)"
                  class="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-col mb-5">
        <label>Image Link</label>
        <input
          type="text"
          class="border border-black p-1"
          v-model="imageLink"
        />
      </div>
      <input
        type="file"
        @change="onFileChange"
        multiple
      />
      <div class="flex flex-row mt-4">
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded"
          @click="uploadImageLink"
        >
          Upload With Image Link
        </button>
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded ml-2"
          @click="uploadImageActivity"
        >
          Upload
        </button>
        <button
          type="button"
          @click="isFormUploadImageActivityOpen = false"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
  <LoadingPage :isLoading="isLoading" />
</template>

<script>
import ActivityService from "@/services/activity.service";
import ActivityMetadataService from "@/services/activity.metadata.service";
import axios from "axios";
import LoadingPage from "@/components/LoadingPage.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
} from "ckeditor5";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "ActivityPortal",
  components: {
    LoadingPage,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertTable",
          "mediaEmbed",
          "|",
          "bulletedList",
          "numberedList",
          "indent",
          "outdent",
        ],
        plugins: [
          Bold,
          Essentials,
          Heading,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          Undo,
        ],
      },
      activities: [],
      activity_metadata: [],
      activity_id: "",
      isAddActivityOpen: false,
      formActivity: {
        title_en: "",
        title_vn: "",
        content_en: "",
        content_vn: "",
        stt: "",
      },
      isUpdateActivityOpen: false,
      formUpdateActivity: {
        title_en: "",
        title_vn: "",
        content_en: "",
        content_vn: "",
        stt: "",
      },
      isFormUploadImageActivityOpen: false,
      isLoading: false,
      languages: [{ lang: "en" }, { lang: "vn" }],
      imageLink: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatDateTime(datetime) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(datetime).toLocaleDateString("en-US", options);
    },
    handleSelectLanguage(event) {
      const language = event.target.value;
      this.formActivity.language = language;
      this.formUpdateActivity.language = language;
    },
    getData() {
      this.isLoading = true;
      ActivityService.getData()
        .then((response) => {
          this.activities = response.data.data.activities;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    closeForm() {
      this.isAddActivityOpen = false;
    },

    openFormAddActivity() {
      this.isAddActivityOpen = true;
      this.formActivity.title_en = "";
      this.formActivity.content_en = "";
      this.formActivity.title_vn = "";
      this.formActivity.content_vn = "";
    },

    addActivities() {
      this.isLoading = true;
      if (
        this.formActivity.title_en == "" ||
        this.formActivity.content_en == "" ||
        this.formActivity.title_vn == "" ||
        this.formActivity.content_vn == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        ActivityService.storeData(this.formActivity)
          .then(() => {
            ActivityService.getData()
              .then((res) => {
                this.activities = res.data.data.activities;
                this.isAddActivityOpen = false;
                notify({ type: "success", title: "Success", text: "Add Successfully!" });
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    deleteActivity(id) {
      this.isLoading = true;
      ActivityService.deleteData(id)
        .then(() => {
          ActivityService.getData()
            .then((res) => {
              this.activities = res.data.data.activities;
              notify({ type: "success", title: "Success", text: "Delete Successfully!" });
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    openUpdateActivities(id, title_en, title_vn, content_en, content_vn, stt) {
      this.activity_id = id;
      this.formUpdateActivity.title_en = title_en;
      this.formUpdateActivity.title_vn = title_vn;
      this.formUpdateActivity.content_en = content_en;
      this.formUpdateActivity.content_vn = content_vn;
      this.formUpdateActivity.stt = stt;
      this.isUpdateActivityOpen = true;
    },

    onFileChange(event) {
      this.selectedFile = event.target.files;
    },

    UpdateActivities() {
      this.isLoading = true;
      if (
        this.formUpdateActivity.title_en == "" ||
        this.formUpdateActivity.title_vn == "" ||
        this.formUpdateActivity.content_en == "" ||
        this.formUpdateActivity.content_vn == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        ActivityService.updateData(this.activity_id, this.formUpdateActivity)
          .then(() => {
            ActivityService.getData()
              .then((res) => {
                this.activities = res.data.data.activities;
                notify({ type: "success", title: "Success", text: "Update Successfully!" });
                this.isUpdateActivityOpen = false;
                this.isLoading = false;
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    openFormUploadImageActivity(id) {
      this.isLoading = true;
      this.activity_id = id;
      ActivityMetadataService.getData(id)
        .then((res) => {
          this.activity_metadata = res.data.data.activity_metadata;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
      this.isFormUploadImageActivityOpen = true;
      this.imageLink = "";
    },

    async uploadImageActivity() {
      if (!this.selectedFile) {
        notify({ type: "error", title: "Error", text: "Please select a file first" });
        this.isLoading = false;
        return;
      }
      for (let i = 0; i < this.selectedFile.length; ++i) {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("image", this.selectedFile[i]);

        try {
          const response = await axios.post("https://api.imgur.com/3/image", formData, {
            headers: {
              Authorization: "Client-ID 8bb5dd7211002ee",
              "Content-Type": "multipart/form-data",
            },
          });
          ActivityMetadataService.storeData(this.activity_id, response.data.data.link)
            .then(() => {
              ActivityMetadataService.getData(this.activity_id)
                .then((res) => {
                  this.activity_metadata = res.data.data.activity_metadata;
                  notify({ type: "success", title: "Success", text: "Upload successful!" });
                  this.isLoading = false;
                })
                .catch((err) => {
                  notify({ type: "error", title: "Error", text: err.response.data.message });
                  this.isLoading = false;
                });
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.error });
          this.isLoading = false;
        }
      }
    },

    uploadImageLink() {
      this.isLoading = true;
      if (this.imageLink == "") {
        notify({ type: "error", title: "Error", text: "Please fill image link!" });
        this.isLoading = false;
      } else {
        ActivityMetadataService.storeData(this.activity_id, this.imageLink)
          .then(() => {
            ActivityMetadataService.getData(this.activity_id)
              .then((res) => {
                this.activity_metadata = res.data.data.activity_metadata;
                notify({ type: "success", title: "Success", text: "Upload successful!" });
                this.isLoading = false;
                this.imageLink = "";
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    deleteActivityMetadata(id) {
      this.isLoading = true;
      ActivityMetadataService.deleteData(id, this.activity_id)
        .then(() => {
          ActivityMetadataService.getData(this.activity_id).then((res) => {
            this.activity_metadata = res.data.data.activity_metadata;
            notify({ type: "success", title: "Success", text: "Delete successfully!" });
            this.isLoading = false;
          });
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    openDuplicate(title_en, title_vn, content_en, content_vn, stt) {
      this.formActivity.title_en = title_en;
      this.formActivity.title_vn = title_vn;
      this.formActivity.content_en = content_en;
      this.formActivity.content_vn = content_vn;
      this.formActivity.stt = stt;
      this.isAddActivityOpen = true;
    },
  },
};
</script>

<style scoped>
table th,
td {
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 2;
  width: fit-content;
}

thead .sticky-col {
  z-index: 5;
}
</style>
