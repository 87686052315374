<template>
  <div class="container mx-auto px-4 py-8 text-xs">
    <div class="flex-c gap-5 mb-5">
      <div class="flex-r items-center mb-4 justify-between">
        <h1 class="text-2xl font-bold">Services List</h1>
        <div
          class="flex-r font-semibold gap-2 items-center cursor-pointer"
          @click="openForm"
        >
          <div class="addBtn bg-contain"></div>
          Add new
        </div>
      </div>
      <div class="">
        <select @change="handleSelect">
          <option
            value=""
            disabled
            selected
          >
            Select a vessel
          </option>
          <option
            :value="index.id"
            v-for="index in vessels"
            :key="index"
          >
            {{ index.name_en }}
          </option>
        </select>
      </div>
    </div>

    <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
      <table
        class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
      >
        <thead>
          <tr class="text-left">
            <th class="py-2 px-3 sticky top-0 bg-gray-200">No.</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Title Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Content Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Opening hour Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Opening hour Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Location Eng</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Location Vie</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Created At</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200">Updated At</th>
            <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in datas"
            :key="data.id"
          >
            <td class="border px-4 py-2">{{ data.stt }}</td>
            <td class="border px-4 py-2">{{ data.id }}</td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.title_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.title_vn"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.content_en"
            ></td>
            <td
              class="border px-4 py-2 min-w-72 max-w-96 text-wrap"
              v-html="data.content_vn"
            ></td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ data.opening_hour_en }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ data.opening_hour_vn }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ data.location_en }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ data.location_vn }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ formatDateTime(data.created_at) }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
              {{ formatDateTime(data.updated_at) }}
            </td>
            <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
              <div class="w-full h-full grid grid-cols-2 gap-4">
                <button
                  @click="
                    openFormEdit(
                      data.id,
                      data.vessel_id,
                      data.title_en,
                      data.title_vn,
                      data.content_en,
                      data.content_vn,
                      data.opening_hour_en,
                      data.opening_hour_vn,
                      data.location_en,
                      data.location_vn,
                      data.stt
                    )
                  "
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Edit
                </button>
                <button
                  @click="deleteService(data.vessel_id, data.id)"
                  class="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
                <button
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                  @click="openFormUploadImage(data.id)"
                >
                  Add Image
                </button>
                <button
                  class="bg-blue-500 text-white px-2 py-1 rounded"
                  @click="
                    openDuplicate(
                      data.vessel_id,
                      data.title_en,
                      data.title_vn,
                      data.content_en,
                      data.content_vn,
                      data.opening_hour_en,
                      data.opening_hour_vn,
                      data.location_en,
                      data.location_vn,
                      data.stt
                    )
                  "
                >
                  Duplicate
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Add Service Form -->
  <div
    v-if="isFormOpen"
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Add New Service</h2>
      <form
        @submit.prevent="addService"
        class="flex flex-col justify-between"
      >
        <div class="">
          <select
            v-model="this.vesselId"
            @change="handleSelectForm"
          >
            <option
              value=""
              disabled
              selected
            >
              Select a vessel
            </option>
            <option
              :value="index.id"
              v-for="index in vessels"
              :key="index"
            >
              {{ index.name_en }}
            </option>
          </select>
        </div>

        <div class="grid grid-cols-2 gap-8">
          <div class="mb-2 w-full">
            <label class="block w-full">Title Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.title_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Title Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.title_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Eng</label>
            <ckeditor
              :editor="editor"
              v-model="form.content_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Vie</label>
            <ckeditor
              :editor="editor"
              v-model="form.content_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Opening hour Eng</label>
            <input
              type="text"
              v-model="form.opening_hour_en"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Opening hour Vie </label>
            <input
              type="text"
              v-model="form.opening_hour_vn"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Location Eng </label>
            <input
              type="text"
              v-model="form.location_en"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Location Vie </label>
            <input
              type="text"
              v-model="form.location_vn"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Order </label>
            <vue-number-input
              v-model="form.stt"
              :min="1"
              inline
              controls
            ></vue-number-input>
          </div>
        </div>

        <div class="flex-r">
          <button
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Add Service
          </button>
          <button
            type="button"
            @click="closeForm"
            class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- Form edit -->
  <div
    v-if="isFormEditOpen"
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Update Service</h2>
      <form
        @submit.prevent="updateService()"
        class="flex flex-col justify-between"
      >
        <div class="">
          <select
            v-model="this.vesselId"
            @change="handleSelectEditForm"
          >
            <option
              :value="index.id"
              selected
              v-for="index in vessels"
              :key="index.id"
            >
              {{ index.name_en }}
            </option>
          </select>
        </div>
        <div class="grid grid-cols-2 gap-8">
          <div class="mb-2 w-full">
            <label class="block w-full">Title Eng</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.title_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Title Vie</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.title_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Eng</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.content_en"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Content Vie</label>
            <ckeditor
              :editor="editor"
              v-model="formEdit.content_vn"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full">Opening hour Eng</label>
            <input
              type="text"
              v-model="formEdit.opening_hour_en"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Opening hour Vie </label>
            <input
              type="text"
              v-model="formEdit.opening_hour_vn"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Location Eng </label>
            <input
              type="text"
              v-model="formEdit.location_en"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Location Vie </label>
            <input
              type="text"
              v-model="formEdit.location_vn"
              class="form-input h-10 p-3"
              style="width: 100%"
            />
          </div>

          <div class="mb-2 w-full">
            <label class="block w-full"> Order </label>
            <vue-number-input
              v-model="formEdit.stt"
              :min="1"
              inline
              controls
            ></vue-number-input>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-8"></div>

        <div class="flex-r">
          <button
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Update Service
          </button>
          <button
            type="button"
            @click="closeForm"
            class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- Upload Image Form -->
  <div
    class="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-10 flex items-center justify-center"
    v-if="isFormUploadImageOpen"
  >
    <div class="add-form bg-white rounded p-4 overflow-auto">
      <h2 class="text-xl font-bold pb-2">Upload Image</h2>
      <div class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-10">
        <table
          class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
        >
          <thead>
            <tr class="text-left">
              <th class="py-2 px-3 sticky top-0 bg-gray-200">ID</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200">Image Link</th>
              <th class="py-2 px-3 sticky top-0 bg-gray-200 sticky-col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data in metadata"
              :key="data.id"
            >
              <td class="border px-4 py-2">{{ data.id }}</td>
              <td class="border px-4 py-2 min-w-72 max-w-96 text-wrap">
                <img
                  :src="data.image_link"
                  class="w-48"
                />
              </td>
              <td class="border px-4 py-2 min-w-72 max-w-96 sticky-col bg-white space-x-2">
                <button
                  @click="deleteMetadata(data.id)"
                  class="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-col mb-5">
        <label>Image Link</label>
        <input
          type="text"
          class="border border-black p-1"
          v-model="imageLink"
        />
      </div>
      <input
        type="file"
        @change="onFileChange"
        multiple
      />
      <div class="flex flex-row mt-4">
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded"
          @click="uploadImageLink"
        >
          Upload With Image Link
        </button>
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded ml-2"
          @click="uploadImage"
        >
          Upload
        </button>
        <button
          type="button"
          @click="closeFormUploadImage"
          class="bg-gray-500 text-white px-4 py-2 rounded ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
  <LoadingPage :isLoading="isLoading"></LoadingPage>
</template>

<script>
import Admin from "@/services/admin.service";
import LoadingPage from "@/components/LoadingPage.vue";
import axios from "axios";
import ServiceMetadataService from "@/services/service.metadata.service";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
} from "ckeditor5";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "ServicePortal",
  components: {
    ckeditor: CKEditor.component,
    LoadingPage,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertTable",
          "mediaEmbed",
          "|",
          "bulletedList",
          "numberedList",
          "indent",
          "outdent",
        ],
        plugins: [
          Bold,
          Essentials,
          Heading,
          Indent,
          IndentBlock,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          Undo,
        ],
      },
      datas: [],
      vessels: [],
      metadata: [],
      isFormOpen: false,
      isFormEditOpen: false,
      isFormUploadImageOpen: false,
      form: {
        title_en: "",
        title_vn: "",
        content_en: "",
        content_vn: "",
        opening_hour_en: "",
        opening_hour_vn: "",
        location_en: "",
        location_vn: "",
        stt: "",
      },
      formEdit: {
        title_en: "",
        title_vn: "",
        content_en: "",
        content_vn: "",
        opening_hour_en: "",
        opening_hour_vn: "",
        location_en: "",
        location_vn: "",
        stt: "",
      },
      vesselId: "",
      updateServiceId: "",
      service_id: "",
      isLoading: false,
      languages: [{ lang: "en" }, { lang: "vn" }],
      imageLink: "",
    };
  },
  mounted() {
    this.getVesselData();
  },
  methods: {
    formatDateTime(datetime) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(datetime).toLocaleDateString("en-US", options);
    },
    getVesselData() {
      this.isLoading = true;
      Admin.getVesselData()
        .then((res) => {
          this.vessels = res.data.data.vessels;
          this.isLoading = false;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    handleSelect(event) {
      const vesselId = event.target.value;
      if (vesselId) {
        this.getServiceByVessel(vesselId);
      }
      localStorage.setItem("vessel", vesselId);
    },

    getServiceByVessel(vessel_id) {
      this.isLoading = true;
      try {
        Admin.getServiceByVessel(vessel_id)
          .then((res) => {
            this.datas = res.data.data.services;
            this.isLoading = false;
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      } catch (error) {
        notify({ type: "error", title: "Error", text: error.response.data.message });
        this.isLoading = false;
      }
    },

    deleteService(vessel_id, id) {
      this.isLoading = true;
      try {
        Admin.deleteService(vessel_id, id)
          .then(() => {
            this.getServiceByVessel(vessel_id);
            notify({ type: "success", title: "Success", text: "Delete Successfully!" });
            this.isLoading = false;
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      } catch (error) {
        notify({ type: "error", title: "Error", text: error.response.data.message });
        this.isLoading = false;
      }
    },

    openForm() {
      this.isFormOpen = true;
      this.form.title_en = "";
      this.form.title_vn = "";
      this.form.content_en = "";
      this.form.content_vn = "";
      this.form.opening_hour_en = "";
      this.form.opening_hour_vn = "";
      this.form.location_en = "";
      this.form.location_vn = "";
      this.vesselId = "";
      this.form.stt = "";
    },
    closeForm() {
      this.isFormOpen = false;
      this.isFormEditOpen = false;
    },
    handleSelectForm(event) {
      const vesselId = event.target.value;
      if (vesselId) {
        this.vesselId = vesselId;
      }
    },
    addService() {
      this.isLoading = true;
      if (
        this.form.title_en == "" ||
        this.form.title_vn == "" ||
        this.form.content_en == "" ||
        this.form.content_vn == "" ||
        this.form.opening_hour_en == "" ||
        this.form.opening_hour_vn == "" ||
        this.form.location_en == "" ||
        this.form.location_vn == "" ||
        this.vesselId == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        try {
          Admin.addService(this.vesselId, this.form)
            .then(() => {
              notify({ type: "success", title: "Success", text: "Add Successfully!" });
              this.getServiceByVessel(this.vesselId);
              this.isFormOpen = false;
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    // Update funcion
    openFormEdit(
      id,
      vessel_id,
      title_en,
      title_vn,
      content_en,
      content_vn,
      opening_hour_en,
      opening_hour_vn,
      location_en,
      location_vn,
      stt
    ) {
      this.isFormEditOpen = true;
      this.updateServiceId = id;
      this.vesselId = vessel_id;
      this.formEdit.title_en = title_en;
      this.formEdit.title_vn = title_vn;
      this.formEdit.content_en = content_en;
      this.formEdit.content_vn = content_vn;
      this.formEdit.opening_hour_en = opening_hour_en;
      this.formEdit.opening_hour_vn = opening_hour_vn;
      this.formEdit.location_en = location_en;
      this.formEdit.location_vn = location_vn;
      this.formEdit.stt = stt;
    },
    handleSelectEditForm(event) {
      const vesselId = event.target.value;
      if (vesselId) {
        this.formEdit.vessel_id = vesselId;
        console.log(this.formEdit.vessel_id);
      }
    },
    updateService() {
      this.isLoading = true;
      if (
        this.formEdit.title_en == "" ||
        this.formEdit.title_vn == "" ||
        this.formEdit.content_en == "" ||
        this.formEdit.content_vn == "" ||
        this.formEdit.opening_hour_en == "" ||
        this.formEdit.opening_hour_vn == "" ||
        this.formEdit.location_en == "" ||
        this.formEdit.location_vn == "" ||
        this.vesselId == ""
      ) {
        notify({ type: "error", title: "Error", text: "Please fill all information!" });
        this.isLoading = false;
      } else {
        try {
          const serviceId = this.updateServiceId;
          Admin.updateServiceById(this.vesselId, serviceId, this.formEdit)
            .then(() => {
              this.getServiceByVessel(this.vesselId);
              notify({ type: "success", title: "Success", text: "Update Successfully!" });
              this.isFormEditOpen = false;
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    onFileChange(event) {
      this.selectedFile = event.target.files;
    },

    handleSelectLanguage(event) {
      const language = event.target.value;
      this.form.language = language;
      this.formEdit.language = language;
    },

    closeFormUploadImage() {
      this.isFormUploadImageOpen = false;
    },
    openFormUploadImage(id) {
      this.isLoading = true;
      this.service_id = id;
      this.isFormUploadImageOpen = true;
      ServiceMetadataService.getData(id)
        .then((res) => {
          this.isLoading = false;
          this.metadata = res.data.data.service_metadata;
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    async uploadImage() {
      if (!this.selectedFile) {
        notify({ type: "error", title: "Error", text: "Please select a file first" });
        this.isLoading = false;
        return;
      }
      for (let i = 0; i < this.selectedFile.length; ++i) {
        const formData = new FormData();
        formData.append("image", this.selectedFile[i]);
        this.isLoading = true;
        try {
          const response = await axios.post("https://api.imgur.com/3/image", formData, {
            headers: {
              Authorization: "Client-ID 8bb5dd7211002ee",
              "Content-Type": "multipart/form-data",
            },
          });

          ServiceMetadataService.storeData(this.service_id, response.data.data.link)
            .then(() => {
              ServiceMetadataService.getData(this.service_id)
                .then((res) => {
                  notify({ type: "success", title: "Success", text: "Upload successful!" });
                  this.metadata = res.data.data.service_metadata;
                  this.isLoading = false;
                })
                .catch((err) => {
                  notify({ type: "error", title: "Error", text: err.response.data.message });
                  this.isLoading = false;
                });
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        } catch (error) {
          notify({ type: "error", title: "Error", text: error.response.data.message });
          this.isLoading = false;
        }
      }
    },

    uploadImageLink() {
      this.isLoading = true;
      if (this.imageLink == "") {
        notify({ type: "error", title: "Error", text: "Please fill image link!" });
        this.isLoading = false;
      } else {
        ServiceMetadataService.storeData(this.service_id, this.imageLink)
          .then(() => {
            ServiceMetadataService.getData(this.service_id)
              .then((res) => {
                this.metadata = res.data.data.service_metadata;
                notify({
                  type: "success",
                  title: "Success",
                  text: "Upload successful!",
                });
                this.isLoading = false;
                this.imageLink = "";
              })
              .catch((err) => {
                notify({ type: "error", title: "Error", text: err.response.data.message });
                this.isLoading = false;
              });
          })
          .catch((err) => {
            notify({ type: "error", title: "Error", text: err.response.data.message });
            this.isLoading = false;
          });
      }
    },

    deleteMetadata(id) {
      this.isLoading = true;
      ServiceMetadataService.deleteData(id, this.service_id)
        .then(() => {
          ServiceMetadataService.getData(this.service_id)
            .then((res) => {
              this.metadata = res.data.data.service_metadata;
              notify({ type: "success", title: "Success", text: "Delete successfully!" });
              this.isLoading = false;
            })
            .catch((err) => {
              notify({ type: "error", title: "Error", text: err.response.data.message });
              this.isLoading = false;
            });
        })
        .catch((err) => {
          notify({ type: "error", title: "Error", text: err.response.data.message });
          this.isLoading = false;
        });
    },

    openDuplicate(
      vessel_id,
      title_en,
      title_vn,
      content_en,
      content_vn,
      opening_hour_en,
      opening_hour_vn,
      location_en,
      location_vn,
      stt
    ) {
      this.form.title_en = title_en;
      this.form.title_vn = title_vn;
      this.form.content_en = content_en;
      this.form.content_vn = content_vn;
      this.form.opening_hour_en = opening_hour_en;
      this.form.opening_hour_vn = opening_hour_vn;
      this.form.location_en = location_en;
      this.form.location_vn = location_vn;
      this.form.stt = stt;
      this.vesselId = vessel_id;
      this.isFormOpen = true;
    },
  },
};
</script>

<style scoped>
table th,
td {
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 2;
  width: fit-content;
}

thead .sticky-col {
  z-index: 5;
}
</style>
