<template>
  <!-- Menu PC -->
  <div :class="['menu-contain hidden lg:flex', { 'menu-open': menuOpen }]">
    <div class="menu whitespace-nowrap">
      <div class="uppercase flex-r gap-4">
        <div
          @click="redirectToHome"
          class="menu-item"
        >
          <div
            class="flex-r gap-2 items-center"
            :class="isActive('home') ? 'border-b-2' : ''"
          >
            <!-- <div
              class="bg-white rounded-full w-1 h-1 dot"
              v-if="isActive('home')"
            ></div> -->
            {{ $t("menu.home") }}
          </div>
        </div>

        <div
          class="menu-item"
          @click="toggleDropdownSuite"
        >
          <div
            class="flex-r gap-2 items-center relative suite-select"
            :class="isActive('suites') ? 'border-b-2' : ''"
          >
            <!-- <div
              class="bg-white rounded-full w-1 h-1 dot"
              v-if="isActive('suites')"
            ></div> -->
            {{ $t("menu.suites") }}
            <div
              class="absolute bottom-full min-w-full right-1/2 translate-x-1/2 rounded hidden suite-options"
              style="background-color: rgba(0, 0, 0, 0.5)"
            >
              <ul class="rounded">
                <li
                  v-for="vessel in vesselOptions"
                  :key="vessel.id"
                  @click="selectOption(vessel.id)"
                  :class="
                    vessel.id == selectedOption
                      ? 'p-5 border border-gray-700 rounded text-nowrap backgroundHover backgroundSelect'
                      : ''
                  "
                  class="p-5 border border-gray-700 rounded text-nowrap backgroundHover"
                >
                  <span>{{ vessel[`name_${language}`] }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          class="menu-item"
          @click="redirectToVoyageSearch()"
        >
          <div
            class="flex-r items-center relative voyage-select"
            :class="isActive('voyages') ? 'border-b-2' : ''"
          >
            <!-- <div
              class="bg-white rounded-full w-1 h-1 dot"
              v-if="isActive('voyages')"
            ></div> -->
            {{ $t("menu.voyageFinder") }}
          </div>
        </div>

        <div class="menu-item">
          <div
            class="flex-r gap-2 items-center relative service-select"
            :class="isActive('services') ? 'border-b-2' : ''"
          >
            <!-- <div
              class="bg-white rounded-full w-1 h-1 dot"
              v-if="isActive('services')"
            ></div> -->
            {{ $t("menu.service") }}
            <div
              class="absolute bottom-full min-w-full right-1/2 translate-x-1/2 rounded hidden service-options"
              style="background-color: rgba(0, 0, 0, 0.5)"
            >
              <ul class="rounded">
                <li
                  v-for="vessel in vesselOptions"
                  :key="vessel.id"
                  @click="selectOptionService(vessel.id)"
                  :class="
                    vessel.id == selectedOption
                      ? 'p-5 border border-gray-700 rounded text-nowrap backgroundHover backgroundSelect'
                      : ''
                  "
                  class="p-5 border border-gray-700 rounded text-nowrap backgroundHover"
                >
                  <span>{{ vessel[`name_${language}`] }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          @click="redirectToActivity"
          class="menu-item"
        >
          <div
            class="flex-r gap-2 items-center"
            :class="isActive('activities') ? 'border-b-2' : ''"
          >
            <!-- <div
              class="bg-white rounded-full w-1 h-1 dot"
              v-if="isActive('home')"
            ></div> -->
            {{ $t("menu.activity") }}
          </div>
        </div>

        <div class="menu-item">
          <!-- <div
            class="flex-r gap-2 items-center"
            :class="isActive('blogs') ? 'border-b-2' : ''"
          > -->
          <!-- <div
              class="bg-white rounded-full w-1 h-1 dot"
              v-if="isActive('blogs')"
            ></div> -->
          {{ $t("menu.specialOffer") }}
          <!-- </div> -->
        </div>

        <div
          @click="redirectToAbout"
          class="menu-item"
        >
          <div
            class="flex-r gap-2 items-center"
            :class="isActive('about-us') ? 'border-b-2' : ''"
          >
            <!-- <div
              class="bg-white rounded-full w-1 h-1 dot"
              v-if="isActive('about-us')"
            ></div> -->
            {{ $t("menu.about") }}
          </div>
        </div>

        <NavbarPc
          v-model="language"
          :options="languageOptions"
          @input="changeLanguage"
        ></NavbarPc>
      </div>
    </div>
  </div>

  <!-- Moblie menu -->
  <div
    :class="[
      'menu-contain flex lg:hidden',
      isOpenMobile ? 'h-full w-full top-0 right-0 bottom-0 rounded-none' : '',
    ]"
    style="background: rgba(0, 0, 0, 50%); backdrop-filter: blur(20px)"
  >
    <div
      :class="['menu', { hidden: isOpenMobile }]"
      @click="toggleOpenMobile"
    >
      <font-awesome-icon
        :icon="['fas', 'bars']"
        style="color: #ffffff"
      />
      {{ "MENU" }}
    </div>

    <div
      class="relative h-full w-full px-7 pt-16 pb-7"
      v-if="isOpenMobile"
    >
      <div class="">
        <div class="mb-14 flex-r justify-between items-center">
          <div class="menu-signin flex-r gap-3 text-white items-center h-full">
            <img
              src="../assets/user-icon.webp"
              class="h-11"
            />
            <span class="ml-1 uppercase">{{ $t("signIn.title") }}</span>
          </div>
          <Navbar
            v-model="language"
            :options="languageOptions"
            @input="changeLanguage"
          ></Navbar>
        </div>
        <!-- <CruiseSelectMb></CruiseSelectMb> -->

        <div class="flex flex-row">
          <div class="w-64">
            <div
              class="flex flex-row justify-start items-center pb-7"
              @click="redirectToHome"
            >
              <div
                class="bg-white rounded-full w-1 h-1 dot"
                v-if="isActive('home')"
              ></div>
              <div
                class="text-white"
                @click="toggleOpenMobile"
              >
                <a class="uppercase ml-2">{{ $t("menu.home") }}</a>
              </div>
            </div>
            <div class="flex flex-row justify-start items-center pb-7">
              <div
                class="bg-white rounded-full w-1 h-1"
                v-if="isActive('suites')"
              ></div>
              <div
                class="text-white relative"
                @click="toggleSuiteDropdown"
              >
                <div class="uppercase ml-3">{{ $t("menu.suites") }}</div>
                <div
                  class="absolute top-0 min-w-full left-full rounded"
                  style="background-color: rgba(0, 0, 0, 0.5)"
                  v-if="isSuiteOpen"
                >
                  <ul class="rounded">
                    <li
                      v-for="vessel in vesselOptions"
                      :key="vessel.id"
                      @click="selectOption(vessel.id)"
                      :class="
                        vessel.id == selectedOption
                          ? 'p-5 border border-gray-700 min-w-28 rounded backgroundHover backgroundSelect'
                          : ''
                      "
                      class="p-5 border border-gray-700 rounded text-nowrap backgroundHover"
                    >
                      <span>{{ vessel[`name_${language}`] }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="flex flex-row justify-start items-center pb-7">
              <div
                class="bg-white rounded-full w-1 h-1"
                v-if="isActive('voyages')"
              ></div>
              <div
                class="text-white relative"
                @click="redirectToVoyageSearch()"
              >
                <div class="uppercase ml-3">{{ $t("menu.voyageFinder") }}</div>
              </div>
            </div>
            <div class="flex flex-row justify-start items-center pb-7">
              <div
                class="bg-white rounded-full w-1 h-1"
                v-if="isActive('services')"
              ></div>
              <div
                class="text-white relative"
                @click="toggleServiceDropdown"
              >
                <div class="uppercase ml-3">{{ $t("menu.service") }}</div>
                <div
                  class="absolute top-0 min-w-full left-full rounded"
                  style="background-color: rgba(0, 0, 0, 0.5)"
                  v-if="isServiceOpen"
                >
                  <ul class="rounded">
                    <li
                      v-for="vessel in vesselOptions"
                      :key="vessel.id"
                      @click="selectOptionService(vessel.id)"
                      :class="
                        vessel.id == selectedOption
                          ? 'p-5 border border-gray-700 min-w-28 rounded backgroundHover backgroundSelect'
                          : ''
                      "
                      class="p-5 border border-gray-700 rounded text-nowrap backgroundHover"
                    >
                      <span>{{ vessel[`name_${language}`] }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="flex flex-row justify-start items-center pb-7"
              @click="redirectToActivity"
            >
              <div
                class="bg-white rounded-full w-1 h-1"
                v-if="isActive('activities')"
              ></div>
              <div
                class="text-white"
                @click="toggleOpenMobile"
              >
                <a class="uppercase ml-3"> {{ $t("menu.activity") }}</a>
              </div>
            </div>
            <div class="flex flex-row justify-start items-center pb-7">
              <!-- <div
                class="bg-white rounded-full w-1 h-1"
                v-if="isActive('blogs')"
              ></div> -->
              <div
                class="text-white"
                @click="toggleOpenMobile"
              >
                <a class="uppercase ml-3"> {{ $t("menu.specialOffer") }}</a>
              </div>
            </div>
            <div
              class="flex flex-row justify-start items-center pb-7"
              @click="redirectToAbout"
            >
              <div
                class="bg-white rounded-full w-1 h-1"
                v-if="isActive('about-us')"
              ></div>
              <div
                class="text-white"
                @click="toggleOpenMobile"
              >
                <a class="uppercase ml-3">{{ $t("menu.about") }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="absolute w-full left-0 flex flex-col justify-center items-center bottom-4">
        <button @click="toggleOpenMobile">
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M12.0303 12.7374L20.1768 20.8839L20.8839 20.1768L12.7374 12.0303L20.8839 3.88386L20.1768 3.17676L12.0303 11.3232L3.88386 3.17676L3.17676 3.88386L11.3232 12.0303L3.17676 20.1768L3.88386 20.8839L12.0303 12.7374Z"
              fill="white"
              fill-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import Navbar from "@/components/Navbar.vue";
import { mapState, mapActions } from "vuex";
import NavbarPc from "@/components/NavBarPc.vue";
import CruiseSelect from "@/components/CruiseSelect.vue";
import CruiseSelectMb from "@/components/CruiseSelectMb.vue";
import VesselService from "@/services/vessel.service";

const Menu = {
  components: {
    Navbar,
    NavbarPc,
    CruiseSelect,
    CruiseSelectMb,
  },
  data() {
    return {
      menuOpen: false,
      isOpenMobile: false,
      languageOptions: [
        { value: "en", label: "English", icon: require("@/assets/english.png") },
        { value: "vn", label: "Tiếng Việt", icon: require("@/assets/vietnamese.png") },
      ],
      vesselOptions: [],
      selectedOption: localStorage.vessel,
      isSuiteOpen: false,
      isVoyageOpen: false,
      isServiceOpen: false,
      language: localStorage.language,
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const isActive = (name) => {
      return route.name == name;
    };

    const navigateTo = (path) => {
      router.push(path);
    };

    return {
      isActive,
      navigateTo,
    };
  },
  computed: {
    ...mapState(["language", "vessel"]),
  },
  methods: {
    ...mapActions(["changeLanguage", "changeVessel"]),

    toggleMenu() {
      if (this.menuOpen == false) {
        this.menuOpen = true;
      } else {
        this.menuOpen = false;
      }
    },

    toggleOpenMobile() {
      if (this.isOpenMobile == true) {
        this.isOpenMobile = false;
      } else {
        this.isOpenMobile = true;
      }
    },

    toggleSuiteDropdown() {
      this.isSuiteOpen = !this.isSuiteOpen;
    },

    toggleVoyageDropdown() {
      this.isVoyageOpen = !this.isVoyageOpen;
    },

    toggleServiceDropdown() {
      this.isServiceOpen = !this.isServiceOpen;
    },

    getVesselData() {
      VesselService.getData().then((response) => {
        this.vesselOptions = response.data.data.vessels;
      });
    },

    selectOption(id) {
      localStorage.vessel = id;
      this.selectedOption = id;
      this.redirectToSuites(id);
    },

    selectOptionService(id) {
      localStorage.vessel = id;
      this.selectedOption = id;
      this.redirectToServices(id);
    },

    // selectOptionVoyage(id) {
    //   localStorage.vessel = id;
    //   this.selectedOption = id;
    //   this.redirectToVoyageSearch(id);
    // },

    redirectToHome() {
      this.$router.push("/");
    },
    redirectToVoyageSearch() {
      this.$router.push("/voyages");
    },
    redirectToSuites(id) {
      this.$router.push("/suites/" + id).then(() => {
        window.location.reload();
      });
    },
    redirectToServices(id) {
      this.$router.push("/services/" + id).then(() => {
        window.location.reload();
      });
    },
    redirectToActivity() {
      this.$router.push("/activities");
    },
    redirectToInnovation() {
      this.$router.push("/innovation-view");
    },
    redirectToAbout() {
      this.$router.push("/about-us/2");
    },
  },
  watch: {
    language(newLang) {
      this.$i18n.locale = newLang; // Cập nhật ngôn ngữ của i18n khi trạng thái ngôn ngữ thay đổi
      window.location.reload();
    },
    vessel() {
      window.location.reload();
    },
  },
  created() {
    this.$i18n.locale = this.language; // Thiết lập ngôn ngữ khi component được tạo
    this.getVesselData();
  },
};
export default Menu;
</script>
<style scoped>
option {
  background: transparent;
  color: black;
}

.service-select:hover .service-options {
  display: block;
}

/* .voyage-select:hover .voyage-options {
  display: block;
} */

.suite-select:hover .suite-options {
  display: block;
}

.backgroundSelect {
  background-color: rgba(80, 80, 80, 0.5);
}

.backgroundHover:hover {
  background-color: rgba(160, 160, 160, 0.8);
}

select {
  background: transparent;
  color: white;
}
</style>
