<script setup>
// import PageLogo from "@/components/PageLogo.vue";
import Banner from "@/components/Banner.vue";
// import Combobox from "@/components/Combobox.vue";
import VoyageComponent from "@/components/voyage/VoyageComponent.vue";
import Button from "@/components/Button.vue";
import VoyageService from "@/services/voyage.service";
import { defineProps, reactive } from "vue";
// import { diffDate } from "@/helpers/diff.date.function";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import { ref } from "vue";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import VesselService from "@/services/vessel.service";
import router from "@/router";

const language = ref(localStorage.language);

const props = defineProps({
  voyage_id: {
    type: Number,
  },
});

let voyageData = {
  voyage_date_available: [],
  voyage_timeline: [],
  voyage_experience: [],
  voyage_location: [],
  voyage_metadata: [],
};

let voyage_timeline = reactive({ data: [] });

const mySwiper = ref(null);

const modules = ref([Navigation, Autoplay]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const activeDays = reactive({ data: [] });

try {
  const voyage = await VoyageService.getDataById(props.voyage_id);
  voyageData = voyage.data.data.voyage;
  voyage_timeline = reactive({ data: voyageData.voyage_timeline });
  voyage_timeline.data.forEach((value, index) => {
    activeDays.data.push(index);
  });
} catch (err) {
  console.log(err);
}

const toggleDay = (index) => {
  if (activeDays.data.includes(index)) {
    activeDays.data = activeDays.data.filter((day) => day !== index);
  } else {
    activeDays.data.push(index);
  }
};
// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   const options = { month: "short", day: "numeric" };
//   return date.toLocaleDateString("en-US", options);
// };

// const selected_date = reactive({
//   start_date:
//     voyageData.voyage_date_available.length > 0
//       ? voyageData.voyage_date_available[0].start_date
//       : new Date(),
//   end_date:
//     voyageData.voyage_date_available.length > 0
//       ? voyageData.voyage_date_available[0].end_date
//       : new Date(),
// });

// const selectDate = (start_date, end_date) => {
//   selected_date.start_date = start_date;
//   selected_date.end_date = end_date;
// };

// const isDateOpen = ref(false);

const booking_number = ref(1);

VesselService.getData().then((res) => {
  const data = res.data.data.vessels.filter((e) => {
    return e.id == localStorage.vessel;
  });

  booking_number.value = data[0].order_number;
});

const redirectToBooking = () => {
  if (voyageData[`title_${language.value}`].includes("3")) {
    router.push("/vessels/2/booking");
  } else if (voyageData[`title_${language.value}`].includes("2")) {
    router.push("/vessels/1/booking");
  } else {
    router.push("/vessels/" + booking_number.value + "/booking");
  }
};
</script>

<template>
  <Banner
    :imgUrl="voyageData.voyage_metadata.length > 0 ? voyageData.voyage_metadata[0].image_link : ''"
  ></Banner>
  <div
    class="block p-0 body-custom w-full px-5"
    style="max-width: 1440px"
  >
    <div class="flex-c gap-8 mb-8 w-full">
      <div class="blue flex-c justify-center items-center">
        <div class="blue flex text-lg md:text-xl uppercase">{{ $t("voyageSearch.voyage") }}</div>
        <div
          class="blue w-full text-4xl md:text-5xl text-center mt-8 home-page-title"
          v-html="voyageData[`title_${language}`]"
        ></div>
      </div>
      <div class="flex-r justify-center">
        <div
          class="blue text-center text-xs md:text-xl w-full"
          v-html="voyageData[`content_${language}`]"
        ></div>
      </div>
      <div class="flex-r justify-center gap-8 mb-8">
        <Button
          button_v="1"
          :text="$t('inquireNowButton')"
          @click="redirectToBooking"
        ></Button>
      </div>
    </div>
    <div class="h-full w-full bg-contain mb-8">
      <!-- <img
        v-for="(item, index) in voyageData.voyage_metadata"
        :key="index"
        class="w-full mb-8"
        :src="voyageData.voyage_metadata.length > 0 ? item.image_link : ''"
        height="2790"
      /> -->
      <swiper
        :slides-per-view="1"
        :space-between="10"
        class="w-full mb-8 mySwiperDetail"
        @swiper="onSwiper"
        :loop="true"
        :navigation="true"
        :modules="modules"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: true,
        }"
      >
        <swiper-slide
          v-for="(item, index) in voyageData.voyage_metadata"
          :key="index"
        >
          <img
            :src="item.image_link"
            :alt="'Image ' + (index + 1)"
            height="2790"
          />
        </swiper-slide>
        <div
          class="swiper-pagination"
          v-bind="pagination"
        ></div>
      </swiper>
    </div>
    <div class="flex-c items-center mb-20">
      <div class="blue mb-8 text-lg md:text-xl uppercase">{{ $t("voyageSearch.intinerary") }}</div>
      <div class="blue text-4xl md:text-5xl mb-8 uppercase">{{ $t("voyageSearch.cruiseWU") }}</div>
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-4 w-full">
        <div class="">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119318.56762602307!2d107.06722726950377!3d20.843606030139824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314a5796518cee87%3A0x55c6b0bcc85478db!2zSOG6oSBMb25nIEJheQ!5e0!3m2!1sen!2s!4v1722284286788!5m2!1sen!2s"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            class="hidden md:block"
          ></iframe>
          <!-- <div
          class="yellow font-semibold"
          v-html="voyageData.voyage_location.length > 0 ? voyageData.voyage_location[0].title : ''"
        ></div>
        <div
          class="blue text-2xl w-full mb-8"
          v-html="
            voyageData.voyage_location.length > 0 ? voyageData.voyage_location[0].sub_title : ''
          "
        ></div>
        <div
          class="blue text-xs md:text-base"
          v-html="
            voyageData.voyage_location.length > 0 ? voyageData.voyage_location[0].description : ''
          "
        ></div> -->
        </div>
        <div class="">
          <div class="flex-c gap-4">
            <div class="flex-r items-center">
              <div
                class="flex-c border-r border-solid pr-2"
                style="border-right: 1px solid var(--blue-color)"
              >
                <div class="blue text-base uppercase">
                  {{ $t("voyageSearch.intineraryDetail") }}
                </div>
              </div>
              <div
                class="blue text-xs pl-2 uppercase"
                v-html="voyageData[`time_${language}`]"
              ></div>
            </div>
            <div class="flex-c voyage-detail-container">
              <div
                v-for="(value, index) in voyage_timeline.data"
                :key="index"
                class="flex-c day-item"
                @click="toggleDay(index)"
              >
                <div
                  class="flex-r jc-sb pb-2 mb-2 cursor-pointer"
                  :class="{ active: activeDays.data.includes(index) }"
                >
                  <div
                    :class="{ 'yellow text-xs md:text-base': true }"
                    v-html="value[`daytime_${language}`]"
                  ></div>
                  <font-awesome-icon
                    :icon="['fas', 'chevron-down']"
                    :class="[{ rotate: activeDays.data.includes(index) }, 'yellow']"
                  />
                </div>
                <div
                  v-if="activeDays.data.includes(index)"
                  class="details open flex-c gap-2 mb-2"
                >
                  <div
                    class="voyage-detail blue text-xs md:text-base"
                    v-html="value[`detail_${language}`]"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-c items-center mb-8">
      <div class="blue mb-8 text-lg md:text-xl uppercase">{{ $t("voyageSearch.otherVoyage") }}</div>
      <!-- <div class="blue text-4xl md:text-5xl mb-8">SUITE & FARES</div> -->
      <!-- <div class="flex-r items-start w-full mb-8">
      <div class="w-1/2 md:w-1/4">
        <Combobox
          isV3="true"
          :white="false"
          label="SIGNATURE SUITES"
        ></Combobox>
      </div>
    </div>
    <div class="flex-r border-bottom-blue mb-8 border-b justify-between w-full">
      <div class="flex-r gap-3">
        <div
          :class="[
            'grid-otp',
            'flex-r',
            'items-center',
            'blue',
            'gap-2',
            { selected: selectedOption == 'grid' },
          ]"
          @click="selectOption('grid')"
        >
          <font-awesome-icon :icon="['fas', 'grip-vertical']" />
          {{ $t("voyageSearch.gridOtp") }}
        </div>
      </div>
      <div class="border-bottom-blue border-b blue text-xs">PHOTO VIEW</div>
    </div> -->
      <div class="mb-20 w-full">
        <VoyageComponent />
      </div>

      <!-- <div class="flex-c items-center mb-8 w-full">
      <div class="blue mb-8 text-lg md:text-xl">CONNECTIVITY</div>
      <div class="blue mb-8 text-center text-4xl md:text-5xl">CONNECTING SUITES</div>
    </div> -->
      <!-- Service Section -->
      <!-- <div class="xl:h-screen xl:w-full mt-20 mb-10">
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-4 h-full">
        <div class="service-slide-content">
          <div class="flex flex-col justify-around h-full">
            <div class="flex flex-row overflow-hidden slide-title">
              <div class="flex flex-col justify-around h-full overflow-hidden">
                <div class="blue uppercase text-center text-lg md:text-xl overflow-hidden">
                  <p>{{ $t("service.titleNor") + $t("service.titleItalic") }}</p>
                </div>
              </div>
            </div>
            <div class="blue overflow-hidden uppercase text-4xl py-7 md:text-5xl text-center">
              Oceania Pool & Bar
            </div>
            <div class="flex flex-row items-center justify-center blue overflow-hidden">
              <div
                class="slide-content flex flex-col text-center text-xs h-full md:text-base font-normal my-4 pb-5"
              >
                <p class="pb-3 xl:pb-5">Location: 2nd floor</p>
                <p class="pb-3 xl:pb-5">Opening Hours: 6:30 - 22:00</p>

                <p>
                  Enjoy relaxation and immerse yourself in the cool crystal waters of the largest
                  four-season saltwater pool, listening to the call of nature in the heart of the
                  World Wonder.
                </p>
              </div>
            </div>
            <div
              class="flex flex-row items-center justify-center text-center overflow-hidden gap-5 pt-5 pb-5"
            >
              <button class="prevBtn h-8 w-8 md:h-10 md:w-10"></button>
              <div
                class="slide-counter blue"
                id="slideCounter"
              >
                1 of 3
              </div>
              <button class="nextBtn h-8 w-8 md:h-10 md:w-10"></button>
            </div>
            <div class=" bg-cover w-full h-96 block xl:hidden"></div>
            <div class="flex flex-row items-center justify-center overflow-hidden pt-12 pb-1">
              <Button text="EXPLORE MORE"></Button>
            </div>
          </div>
        </div>
        <div class=" bg-cover h-full w-full hidden xl:block"></div>
      </div>
    </div> -->
      <!-- <div class="flex-c items-center w-full">
      <div class="blue mb-8 text-lg md:text-xl">EXPERIENCES</div>
      <div class="blue mb-8 text-center text-4xl md:text-5xl">EXPERIENCES ON JOURNEY</div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div
          class="mb-8"
          v-for="(item, index) in voyageData.voyage_experience"
          :key="index"
        >
          <div class="blue">
            <div class="cuisine-img bg-contain"></div>
          </div>
          <div class="flex-c">
            <div
              class="blue text-xs"
              v-html="item.review"
            ></div>
          </div>
        </div>
      </div>
    </div> -->
    </div>
  </div>
</template>

<style></style>
