<template>
  <div class="flex-c w-full items-center">
    <div class="w-full mb-8">
      <swiper
        :slides-per-view="perPage"
        :space-between="10"
        class="w-full mb-8 h-full md:grid md:items-stretch mySwiper"
        @swiper="onSwiper"
        :loop="true"
        :navigation="true"
        :modules="modules"
      >
        <swiper-slide
          v-for="(item, index) in itemsPaginated"
          :key="index"
          class="flex justify-center items-center"
        >
          <CardActivity
            :imgUrl="item.activity_metadata.length > 0 ? item.activity_metadata[0].image_link : ''"
            :card_title="item[`title_${language}`]"
            :card_descr="item[`content_${language}`]"
            :activity_id="item.id"
            class="text-start w-full h-full"
          />
        </swiper-slide>
        <div
          class="swiper-pagination"
          v-bind="pagination"
        ></div>
      </swiper>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import ActivityService from "@/services/activity.service";
import CardActivity from "@/components/activity/CardActivity.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

let itemsPaginated = [];

try {
  const activity = await ActivityService.getData();

  itemsPaginated = activity.data.data.activities.sort((a, b) => {
    return a.stt - b.stt;
  });
} catch (err) {
  console.log(err);
}

const language = ref(localStorage.language);

const mySwiper = ref(null);

const modules = ref([Navigation]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const perPage = ref(0);

if (itemsPaginated.length == 1) {
  perPage.value = 1;
} else if (itemsPaginated.length == 2) {
  if (window.innerWidth >= 768) {
    perPage.value = 2;
  } else {
    perPage.value = 1;
  }
} else {
  if (window.innerWidth >= 768 && window.innerWidth <= 1280) {
    perPage.value = 2;
  } else if (window.innerWidth >= 1280) {
    perPage.value = 3;
  } else {
    perPage.value = 1;
  }
}
</script>
<style></style>
