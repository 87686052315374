<template>
  <Suspense>
    <BlogHeading />
  </Suspense>
  <div
    class="blog-view block p-0 w-full px-5 body-custom mb-10 mt-2"
    style="max-width: 1440px"
  >
    <Suspense>
      <PostAllCardComponent />
    </Suspense>
  </div>
  <Menu></Menu>
  <Footer></Footer>
</template>
<script>
import Footer from "@/components/Footer.vue";
// import PageLogo from "@/components/PageLogo.vue";
import Menu from "@/components/Menu.vue";
import PostAllCardComponent from "@/components/post/PostAllCardComponent.vue";
import BlogHeading from "@/components/heading/BlogHeading.vue";

export default {
  name: "BlogView",
  components: {
    Footer,
    // PageLogo,
    Menu,
    PostAllCardComponent,
    BlogHeading,
  },
};
</script>
