<!-- eslint-disable -->
<template>
  <Banner
    :imgUrl="
      serviceData.service_metadata.length > 0 ? serviceData.service_metadata[0].image_link : ''
    "
  ></Banner>
  <div
    class="block p-0 body-custom w-full px-5"
    style="max-width: 1440px"
  >
    <div class="">
      <div class="flex-c items-center gap-8 mb-8">
        <div class="blue text-lg md:text-xl text-center uppercase">{{ $t("menu.service") }}</div>
        <div
          class="blue text-4xl md:text-5xl text-center home-page-title"
          v-html="serviceData[`title_${language}`]"
        ></div>
        <div class="blue w-full text-xs lg:text-base text-center">
          <li
            class="list-none"
            v-html="serviceData[`location_${language}`]"
          ></li>
          <li
            class="list-none"
            v-html="serviceData[`opening_hour_${language}`]"
          ></li>
        </div>
        <div
          class="blue text-xs lg:text-base text-center w-full"
          v-html="serviceData[`content_${language}`]"
        ></div>
        <!-- <div class="flex-r w-full justify-between">
          <button
            @click="goPrev"
            class="prevBtn h-8 w-8 md:h-10 md:w-10"
          ></button>
          <button
            @click="goNext"
            class="nextBtn h-8 w-8 md:h-10 md:w-10"
          ></button>
        </div> -->
        <swiper
          :slides-per-view="1"
          :space-between="10"
          class="w-full mb-8 mySwiperDetail"
          @swiper="onSwiper"
          loop="true"
          :navigation="true"
          :modules="modules"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: true,
          }"
        >
          <swiper-slide
            v-for="(item, index) in serviceData.service_metadata"
            :key="index"
          >
            <img
              :src="item.image_link"
              :alt="'Image ' + (index + 1)"
            />
          </swiper-slide>
          <!-- Swiper Pagination -->
          <div
            class="swiper-pagination"
            slot="pagination"
          ></div>
        </swiper>
        <div class="blue text-lg md:text-xl text-center uppercase">{{ $t("otherService") }}</div>
        <VesselServiceComponent :vessel_id="vessel_id" />
      </div>
      <!-- <div
      class="blue text-xs lg:text-base text-center w-full"
      v-html="serviceData.content"
    ></div>
    <img
      v-for="(item, index) in serviceData.service_metadata"
      :key="index"
      class="w-full mb-8"
      :src="serviceData.service_metadata.length > 0 ? item.image_link : ''"
      height="2790"
    />
    <div class="blue text-lg md:text-xl text-center">OTHER SERVICES</div>
    <VesselServiceComponent /> -->
    </div>
  </div>
</template>

<script setup>
// import PageLogo from "@/components/PageLogo.vue";
import VesselServiceClass from "@/services/vessel_service.service";
import VesselServiceComponent from "@/components/vessel_service/VesselServiceComponent.vue";
import Banner from "@/components/Banner.vue";
import { defineProps, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";

const language = ref(localStorage.language);

const props = defineProps({
  service_id: {
    type: Number,
  },
});

let serviceData = { service_metadata: [] };

const vessel_id = ref(localStorage.vessel);

try {
  const service = await VesselServiceClass.getDataById(localStorage.vessel, props.service_id);
  serviceData = service.data.data.service;
} catch (err) {
  console.log(err);
}

const mySwiper = ref(null);

const modules = ref([Navigation, Autoplay]);

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

// const goPrev = () => {
//   if (mySwiper.value) {
//     mySwiper.value.slidePrev();
//   }
// };

// const goNext = () => {
//   if (mySwiper.value) {
//     mySwiper.value.slideNext();
//   }
// };
</script>

<style>
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
</style>
