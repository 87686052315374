<template>
  <div
    class="dropdown"
    style="width: 100%"
  >
    <button
      @click="toggleDropdown"
      class="dropdown-toggle gap-2 w-full"
    >
      <div class="flex-r items-center w-full">
        <div
          class="select-voyage"
          v-html="
            selectedOption == '' || selectedOption == null
              ? $t('selectVoyage')
              : selectedOption[`title_${language}`]
          "
        ></div>
      </div>
      <font-awesome-icon :icon="['fas', 'chevron-down']" />
    </button>
    <div
      v-if="isOpen"
      class="dropdown-menu w-full z-10"
    >
      <li @click="selectOption('')">{{ $t("selectVoyage") }}</li>
      <li
        v-for="(option, index) in voyage_data"
        :key="index"
        @click="selectOption(option)"
      >
        <div v-html="option[`title_${language}`]"></div>
      </li>
    </div>
  </div>
</template>

<script>
import VoyageService from "@/services/voyage.service";

export default {
  name: "ComboboxLocation",
  props: {
    modelValue: Object,
  },
  data() {
    return {
      isOpen: false,
      selectedOption: null,
      voyage_data: [],
      language: localStorage.language,
      name: "",
      order_number: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.name = option[`title_${this.language}`];
      this.updateModel(); // Call updateModel to emit the values
      this.isOpen = false; // Đóng dropdown khi lựa chọn được thực hiện
    },
    getData() {
      VoyageService.getData()
        .then((res) => {
          this.voyage_data = res.data.data.voyages;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateModel() {
      this.$emit("update:modelValue", {
        name: this.name,
      });
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.name = newValue[`title_${this.language}`] || "";
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu {
  min-width: 350px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}
</style>
