<template>
    <div v-if="isLoading" class="loading-overlay">
        <img src="./../assets/loading_overlay.gif" alt="Loading...">
    </div>
</template>

<script>
export default {
    name: "LoadingPage",
    props: {
        isLoading: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}
</style>